import { all, fork } from 'redux-saga/effects';
import authSaga from './AuthSaga';
import productSaga from './ProductSaga';
import productVariantSaga from './ProductVariantSaga';
import ProfileSaga from './ProfileSaga';
import userSaga from './UserSaga';
import addressSaga from './AddressSaga';
import orderSaga from './OrderSaga';
import scheduleSaga from './ScheduleSaga';
import companySaga from './CompanySaga';
import deviceSaga from './DeviceSaga';
import generalSaga from './GeneralSaga';

function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(productSaga),
    fork(productVariantSaga),
    fork(ProfileSaga),
    fork(userSaga),
    fork(addressSaga),
    fork(orderSaga),
    fork(generalSaga),
    fork(scheduleSaga),
    fork(companySaga),
    fork(deviceSaga),
  ]);
}

export default rootSaga;
