export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const PERSONAL_DATA_REQUEST = 'PERSONAL_DATA_REQUEST';
export const PERSONAL_DATA_SUCCESS = 'PERSONAL_DATA_SUCCESS';
export const PERSONAL_DATA_ERROR = 'PERSONAL_DATA_ERROR';

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_ERROR = 'CONTACT_US_ERROR';
