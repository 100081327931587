import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/SelectModal.scss';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setAddressModal,
  setLocalAddress,
  setSelectedAddress,
  setSelectedAddressModal,
} from 'redux/actions/AddressActions';
import {
  getAddressListState,
  getAuthState,
  getCartState,
  getSelectAddressModalState,
  getUserProfileState,
} from 'redux/src/StatesGetter';
import plusIcon from 'assets/images/plus-icon.svg';
import addressPin from 'assets/images/address-pin-icon.svg';
import { defaultBillingAddress } from 'redux/selector/AddressSelector';
import warningIcon from 'assets/images/warning-icon.svg';
import { setProfileDataRequest } from 'redux/actions/ProfileActions';

const SelectAddressModal = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const addresses = useSelector(getAddressListState, shallowEqual);
  const modalState = useSelector(getSelectAddressModalState, shallowEqual);
  const { shippingAddress, billingAddress } = useSelector(getCartState, shallowEqual);
  const profile = useSelector(getUserProfileState, shallowEqual);
  const { id: profileId } = profile;

  const closeSelectAddressModal = () => {
    dispatch(setSelectedAddressModal({ open: false, setDefault: false, billing: false }));
  };

  const selectAddress = (clickedAddress) => {
    dispatch(
      setSelectedAddress({
        shippingAddress: { ...shippingAddress, ...(!modalState.billing && clickedAddress) },
        billingAddress: {
          ...billingAddress,
          ...((modalState.billing || Object.keys(billingAddress ?? {}).length === 0) &&
            clickedAddress),
        },
        selected: !modalState.billing,
      }),
    );

    if (isLoggedIn && modalState.setDefault) {
      const patientData = {
        defaultAddress: clickedAddress?.id
          ? `/api/ro/v2/shop/account/addresses/${clickedAddress.id}`
          : null,
      };

      dispatch(
        setProfileDataRequest({
          id: profileId,
          patientData,
        }),
      );
    }
    closeSelectAddressModal();
  };

  const openAddAddressModal = () => {
    dispatch(
      setAddressModal({
        open: true,
        setSelected: true,
        setDefault: true,
        billing: modalState.billing,
      }),
    );
  };

  const addNewAddress = () => {
    dispatch(setLocalAddress({ ...defaultBillingAddress }));
    closeSelectAddressModal();
    openAddAddressModal();
  };

  return (
    <>
      <Modal
        show={modalState.open}
        onHide={closeSelectAddressModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="selectModal"
      >
        <Modal.Header closeButton>
          <span className="modal-header-text">
            {modalState.billing ? t('cart.selectBillingAddress') : t('cart.selectDeliveryAddress')}
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper address-wrapper">
            {addresses?.length > 0 ? (
              addresses?.map((address) => {
                return (
                  <div className="address" key={address.id} onClick={() => selectAddress(address)}>
                    <img src={addressPin} alt="address-pin" />
                    <div className="text">
                      <span>{`${address.street} ${address.streetNumber}, ${address.city}`}</span>
                      <span>{`${address.additionalInfo ?? ''}`}</span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-selections-message">
                <img src={warningIcon} alt="warning-icon" />
                {t('cart.noAddressesInAccount')}
              </div>
            )}
          </div>
          <div className="add-new-button" onClick={addNewAddress}>
            <img src={plusIcon} alt="plus-icon" />
            <span>{t('addNewAddress')}</span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SelectAddressModal;
