import 'styles/components/AccountAddressBlock.scss';
import { useTranslation } from 'react-i18next';
import editAddressIcon from 'assets/images/edit-address-icon.svg';
import deleteAddressIcon from 'assets/images/delete-icon.svg';
import plusIcon from 'assets/images/plus-icon.svg';
import { getAddressListState, getSelectedAddressState } from 'redux/src/StatesGetter';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  deleteAddressRequest,
  fetchAddressRequest,
  setAddressModal,
  setLocalAddress,
} from 'redux/actions/AddressActions';
import { defaultShippingAddress } from 'redux/selector/AddressSelector';
import Alerts from 'components/Alerts';

const AccountLegalInfoBlock = () => {
  const { t, i18n } = useTranslation();
  const addresses = useSelector(getAddressListState, shallowEqual);
  const selectedAddress = useSelector(getSelectedAddressState, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAddressRequest({}));
  }, []);

  const editAddress = (clickedAddress) => {
    dispatch(
      setLocalAddress({ ...clickedAddress, additionalInfo: clickedAddress.additionalInfo ?? '' }),
    );
    dispatch(setAddressModal({ open: true, setSelected: false, billing: false }));
  };

  const deleteAddress = (clickedAddress) => {
    Alerts.okCancelAlert(t('warning'), t('alerts.addressDelete'), () => {
      dispatch(deleteAddressRequest(clickedAddress));
    });
  };

  return (
    <div className="account-address-block">
      <div className="mobile-block-title">{t('settings.myAddresses')}</div>
      <div
        className="add-new-address-button"
        onClick={() =>
          editAddress({
            ...defaultShippingAddress,
          })
        }
      >
        <img src={plusIcon} alt="plus-icon" />
        <span>{t('addNewAddress')}</span>
      </div>
      <div className="addresses-wrapper">
        {addresses?.length > 0 &&
          addresses?.map((address) => {
            return (
              <div className="address" key={address.id}>
                <div className="address-text">
                  <span>{`${address.street} ${address.streetNumber}, ${address.city}`}</span>
                  <span>{`${address.additionalInfo ?? ''}`}</span>
                </div>
                {selectedAddress.id !== address.id && (
                  <div className="delete-address" onClick={() => deleteAddress(address)}>
                    <img src={deleteAddressIcon} alt="delete-address" />
                  </div>
                )}
                <div className="edit-address" onClick={() => editAddress(address)}>
                  <img src={editAddressIcon} alt="edit-address" />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default AccountLegalInfoBlock;
