import { AnyAction } from 'redux';
import {
  ALL_PRODUCTS_FETCH_SUCCESS,
  FETCH_ACCESSORY_PRODUCTS_SUCCESS,
  PRODUCT_FETCH_SUCCESS,
} from 'redux/types/ProductTypes';

const AllProductsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case ALL_PRODUCTS_FETCH_SUCCESS:
      return {
        data: action.payload.data,
        noOfPages: action.payload.noOfPages ?? 1,
        currentPage: action.payload.page ?? 1,
      };
    default:
      return state;
  }
};

const ProductReducer = (state = { data: [] }, action: AnyAction) => {
  switch (action.type) {
    case PRODUCT_FETCH_SUCCESS:
      return {
        data: action.payload.data,
      };
    default:
      return state;
  }
};

const AccessoryProductsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_ACCESSORY_PRODUCTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export { AllProductsReducer, ProductReducer, AccessoryProductsReducer };
