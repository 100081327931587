import * as PropTypes from 'prop-types';

const LoadingSpinner = ({ isLoading }) => {
  return isLoading ? (
    <div className="showbox">
      <div className="loader">
        <svg className="circular" viewBox="25 25 50 50">
          <circle
            className="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </div>
  ) : (
    <div className="tinyBox" />
  );
};

LoadingSpinner.defaultProps = {
  isLoading: false,
};

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
};
export default LoadingSpinner;
