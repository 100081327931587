export const ALL_PRODUCTS_FETCH_REQUEST = 'ALL_PRODUCTS_FETCH_REQUEST';
export const ALL_PRODUCTS_FETCH_SUCCESS = 'ALL_PRODUCTS_FETCH_SUCCESS';
export const ALL_PRODUCTS_FETCH_ERROR = 'ALL_PRODUCTS_FETCH_ERROR';

export const PRODUCT_FETCH_REQUEST = 'PRODUCT_FETCH_REQUEST';
export const PRODUCT_FETCH_SUCCESS = 'PRODUCT_FETCH_SUCCESS';
export const PRODUCT_FETCH_ERROR = 'PRODUCT_FETCH_ERROR';

export const FETCH_ACCESSORY_PRODUCTS_REQUEST = 'FETCH_ACCESSORY_PRODUCTS_REQUEST';
export const FETCH_ACCESSORY_PRODUCTS_SUCCESS = 'FETCH_ACCESSORY_PRODUCTS_SUCCESS';
export const FETCH_ACCESSORY_PRODUCTS_ERROR = 'FETCH_ACCESSORY_PRODUCTS_ERROR';
