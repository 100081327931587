import 'styles/components/Footer.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getEnv } from 'services/EnvService';

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="footer">
      <div className="footer-links">
        <Link to="/about-delivery">{t('support.delivery')}</Link>
        <Link to="/terms-and-conditions">{t('support.terms')}</Link>
        <Link to="/privacy-policy">{t('support.policy')}</Link>
        <Link to="/contact">{t('contact')}</Link>
        <a href="https://aquavia.ro/">{t('support.aboutUs')}</a>
      </div>
      <div className="disclaimer">{`Copyright © 2022 ${getEnv('APP_NAME')} - ${t(
        'support.allRightsReserved',
      )}`}</div>
    </div>
  );
};

export default Footer;
