import 'styles/components/QuantityChange.scss';

import minusQuantityImage from 'assets/images/minus-quantity.svg';
import addQuantityImage from 'assets/images/add-quantity.svg';
import { useEffect, useState } from 'react';

const QuantityChange = ({
  quantitySelected,
  itemsInCart = 0,
  setQuantity,
  accessory = false,
  maxQuantity = 6,
}) => {
  return (
    <div className="product-quantity-change">
      <span
        className={`quantity-change-button ${
          (quantitySelected === 2 && !accessory) || (accessory && quantitySelected === 1)
            ? 'disabled'
            : ''
        }`}
        onClick={() => setQuantity(-1)}
      >
        <img src={minusQuantityImage} alt="minus-quantity" />
      </span>
      <span className="quantity-number">{`${quantitySelected}`}</span>
      <span
        className={`quantity-change-button ${
          (quantitySelected === maxQuantity || itemsInCart + 1 > maxQuantity) && !accessory
            ? 'disabled'
            : ''
        }`}
        onClick={() => setQuantity(1)}
      >
        <img src={addQuantityImage} alt="add-quantity" />
      </span>
    </div>
  );
};

export default QuantityChange;
