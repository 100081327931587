import _ from 'lodash';
import loadedRoutes from './LoadedRoutes.json';
import erroredRoutes from './ErroredRoutes.json';

const createErrorMessageSelector = (state) => {
  const actions = erroredRoutes;
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we show the first error
  return (
    _(actions)
      .filter((act) => state[act]?.message)
      .map((action) => _.get(state, action))
      .compact()
      .last() || ''
  );
};

const createLoadingSelector = () => (state) => {
  const actions = loadedRoutes;
  // returns true only when all actions are loading
  return actions.some((action) => _.get(state, `loadingState.${action}`));
};

export { createErrorMessageSelector, createLoadingSelector };
