import 'styles/components/CartPaymentBlock.scss';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAuthState, getPaymentMethodsState } from 'redux/src/StatesGetter';
import { setOrderPaymentMethodRequest } from 'redux/actions/OrderActions';

const CartPaymentBlock = ({ cart }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const paymentMethods = useSelector(getPaymentMethodsState, shallowEqual);
  const { payments } = cart;

  const selectPaymentMethod = (e) =>
    dispatch(
      setOrderPaymentMethodRequest({
        paymentMethod: `${e.target.value}`,
      }),
    );

  return (
    <div className="cart-payment-block mt-3">
      <div className="payment-block-title mb-3">{t('cart.payment')}</div>
      <div className="payment-block-eta">
        <span className="payment-title">{t('cart.paymentMethod')}</span>
        <div className="payment-text">
          {paymentMethods.map((method) => {
            return (
              <div key={method.id} className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  id={`paymentMethod-${method.id}`}
                  value={method.code}
                  onChange={selectPaymentMethod}
                  checked={payments[0].method.name === method.name ?? false}
                />
                <label className="form-check-label" htmlFor={`paymentMethod-${method.id}`}>
                  {method.translations[i18n.language ?? 'ro'].name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CartPaymentBlock;
