import 'styles/components/CartProducts.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CartSuggestions from 'components/CartSuggestions';
import CartItem from 'components/CartItem';
import EditCartItemModal from 'components/modals/EditCartItemModal';
import { fetchAccessoryProductsRequest } from 'redux/actions/ProductActions';
import { getAccessoryProductsState, getOrderItemState } from 'redux/src/StatesGetter';

const CartProducts = ({ cart }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(getOrderItemState, shallowEqual);
  const [editCartItemModal, setEditCartItemModal] = useState(false);
  const showCartItemModal = () => setEditCartItemModal(true);
  const closeCartItemModal = () => setEditCartItemModal(false);
  const accessoryProducts = useSelector(getAccessoryProductsState, shallowEqual);

  useEffect(() => {
    dispatch(fetchAccessoryProductsRequest({}));
  }, []);

  return (
    <div className="cart-products-wrapper">
      <div>
        <span className="products-title">{t('cart.product')}</span>
        {cart.items.map((item) => {
          return <CartItem key={item.id} item={item} showCartItemModal={showCartItemModal} />;
        })}
      </div>

      <CartSuggestions products={accessoryProducts} />

      <EditCartItemModal
        cartItemModalVisibility={editCartItemModal}
        closeCartItemModal={closeCartItemModal}
        item={selectedProduct}
      />
    </div>
  );
};

export default CartProducts;
