const localforage = window.localStorage;

const setData = async (key: any, value: any) => {
  try {
    await localforage.setItem(key, value);
    return true;
  } catch (error) {
    return false;
  }
};

const getData = async (key: any, defaultValue: any) => {
  try {
    const value = await localforage.getItem(key);
    if (value !== null) {
      return value;
    }
    return defaultValue;
  } catch (error) {
    return defaultValue;
  }
};

const getItem = async (key) => {
  return localforage.getItem(key);
};

const setItem = async (key, value) => {
  await localforage.setItem(key, value);
};

const removeItem = async (key) => {
  return localforage.removeItem(key);
};

const clearData = async (key: any) => {
  try {
    await localforage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
};

const clearAll = () => {
  return (async () => {
    try {
      await localforage.clear();
      return true;
    } catch (error) {
      return false;
    }
  })().then(() => {
    return true;
  });
};

const pushNotificationRef = (id: any = null) => {
  let oldPushes = localStorage.getItem('handledPushes');
  if (oldPushes) {
    oldPushes = oldPushes.split('_').slice(-10).join('_');
  }
  if (id) {
    localStorage.setItem('handledPushes', `${oldPushes}_${id}`);
  }
  return oldPushes ?? '_';
};

export default {
  setData,
  clearAll,
  clearData,
  getData,
  getItem,
  setItem,
  removeItem,
  pushNotificationRef,
};
