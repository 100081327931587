import arrow from 'assets/images/arrow-right.svg';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={arrow} alt="Next" />
    </div>
  );
};

export default NextArrow;
