import 'styles/pages/SuccessPage.scss';
import checkmark from 'assets/images/green-checkmark.svg';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Order from 'types/Order';
import { shallowEqual, useSelector } from 'react-redux';
import { getOrderDetailsState } from 'redux/src/StatesGetter';

const SuccessPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const order: Order = useSelector(getOrderDetailsState, shallowEqual);

  const navigateToOrder = () => {
    navigate(`/orders/${order.tokenValue}`);
  };

  return (
    <div className="success-page-wrapper">
      <div className="checkmark-wrapper">
        <img src={checkmark} alt="checkmark" />
      </div>
      <div className="order-placed">{t('order.orderPlaced')}</div>
      <div className="green-button track-order-btn" onClick={navigateToOrder}>
        {t('order.trackOrder')}
      </div>
      <Link className="keep-shopping-btn" to="/">
        {t('order.keepShopping')}
      </Link>
    </div>
  );
};

export default SuccessPage;
