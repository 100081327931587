import { convertPrice, convertPriceToString } from 'modules/CurrencyUtils';
import { sortObjectByKey } from 'modules/Utils';
import i18next from 'i18next';

const productPriceObj = (priceArray, quantity = 1) => {
  const newArray = priceArray?.filter((current) => {
    return current.quantity <= quantity;
  });

  const selectedObject =
    newArray.length > 0
      ? newArray.pop()
      : { ...priceArray[0], price: priceArray[0]?.originalPrice };

  return {
    pvqpId: selectedObject.pvqpId,
    productVariantId: selectedObject.productVariantId,
    image: selectedObject.image,
    originalPrice: convertPrice(selectedObject.originalPrice * quantity),
    price: convertPrice(selectedObject.price * quantity),
    originalPriceAsString: convertPriceToString(selectedObject.originalPrice * quantity),
    priceAsString: convertPriceToString(selectedObject.price * quantity),
    quantity,
  };
};

const cartItemPriceObj = (item) => {
  return {
    productVariantId: item.variant?.id,
    image: '78/50/e80ea040bdb8d90e7efbcb374813.png', // TODO: API must expose image
    originalPrice: item.variant?.originalPrice
      ? convertPrice(item.variant.originalPrice * item.quantity)
      : undefined,
    price: item.variant?.price ? convertPrice(item.variant.price * item.quantity) : undefined,
    total: item.total ? convertPrice(item.total) : undefined,
    originalPriceAsString: item.variant?.originalPrice
      ? convertPriceToString(item.variant.originalPrice * item.quantity)
      : undefined,
    priceAsString: item.variant?.price
      ? convertPriceToString(item.variant.price * item.quantity)
      : undefined,
    totalAsString: item.total ? convertPriceToString(item.total) : undefined,
    quantity: item.quantity,
  };
};

const resolveProductName = (taxon, code) => {
  return taxon.code === 'WATER' ? `${i18next.t('bidon')} ${code}` : code;
};

const resolveAquaDetails = (product) => {
  return product.mainTaxon.code === 'WATER' && product.aquaDetails.length > 0
    ? sortObjectByKey(product.aquaDetails, 'quantity')
    : [
        {
          image: product.images[0].path,
          originalPrice: product.variants[0].originalPrice,
          price: product.variants[0].price,
          productVariantId: product.variants[0].id,
          pvqpId: product.variants[0].id,
          quantity: 1,
        },
      ];
};

export { productPriceObj, cartItemPriceObj, resolveProductName, resolveAquaDetails };
