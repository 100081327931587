/**
 * @see {^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,8}$ }
 * @param email
 * @returns {boolean}
 */

const validateEmail = (email: string) => {
  if (!email?.length) {
    return false;
  }
  return /^\w+([.-/+/]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim());
};

/**
 * @param phone
 * @returns {boolean}
 * @about {either 10 digits number, or 12 or 14 or XXX-XXX-XXXX or +XX-XXXX-XXXX}
 */
const validatePhone = (phone: string) => {
  const phonePattern =
    /^\d{10}|^\d{12}|^\d{14}|^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})|^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return !!phone.match(phonePattern);
};

/**
 * @ref https://www.w3resource.com/javascript/form/password-validation.php
 * @param password
 */
const validatePassword = (password: string | any[]) => {
  return password && password.length >= 6;
};

const validateName = (name: string | any[], minLength = 2, maxLength = 40) => {
  // const onlyLettersPattern = /^\b(?!.*?\s{2})[A-Za-z ]{3,50}\b$/; // no space at the ends
  // const onlyLettersPattern = /^(?!.*?\s{2})[A-Za-z ]{3,50}$/;
  return name.length >= minLength && name.length <= maxLength;
};

const validateDescription = (name: string | any[], minLength = 4, maxLength = 200) => {
  return name.length >= minLength && name.length <= maxLength;
};
const validateNumber = (number: { toString: () => string }) => {
  const numericPattern = /^-?\d+\.?\d*$/;
  return number && !!number.toString().match(numericPattern);
};

const validateBarcode = (barcode: string) => {
  const BarcodePattern = /^123456\d{8}$/;
  return !!barcode.match(BarcodePattern);
};

const validLetters = (_txt: string) => {
  const r = /^[a-zA-ZÀ-ÿĂăÂâÎîȘșȚț]+$/;
  return r.test(_txt.trim());
};

const isValidAddress = (theAddress) => {
  return theAddress && Array.isArray(Object.keys(theAddress)) && Object.keys(theAddress).length > 1;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  validateEmail,
  validatePhone,
  validatePassword,
  validateName,
  validateNumber,
  validateBarcode,
  validateDescription,
  validLetters,
  isValidAddress,
};
