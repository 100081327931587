import StaticPage from 'pages/support/StaticPage';
import { getEnv } from 'services/EnvService';
import { default as ApiConstants } from 'constants/ApiConstants.json';

const ReturnPolicyPage = () => {
  return (
    <StaticPage url={`${getEnv('BASE_URL')}/api/ro/v2${ApiConstants.RETURN_POLICY_ENDPOINT}`} />
  );
};

export default ReturnPolicyPage;
