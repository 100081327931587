import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/ProgramDeliveryModal.scss';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getAvailableShippingMethodsState,
  getCartState,
  getShippingMethodsState,
} from 'redux/src/StatesGetter';
import {
  getDayNameByDate,
  orderDateETAFormat,
  orderDayFormat,
  orderHistoryDateFormat,
} from 'modules/DateUtils';
import { setOrderShippingMethod } from 'redux/actions/OrderActions';
import Alerts from 'components/Alerts';
import Order from 'types/Order';

const ProgramDeliveryModal = ({ showModal, modalClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const shippingMethodState = useSelector(getShippingMethodsState, shallowEqual);
  const availableShippingMethods = useSelector(getAvailableShippingMethodsState, shallowEqual);
  const cart: Order = useSelector(getCartState, shallowEqual);

  const [selectedPlannedDate, setSelectedPlannedDate] = useState<any>({
    day: '',
    slotStart: '',
    slotEnd: '',
  });

  useEffect(() => {
    if (availableShippingMethods?.type === 'planned') {
      if (
        !availableShippingMethods?.options?.some((option) => {
          return option.day === shippingMethodState?.day;
        })
      ) {
        dispatch(
          setOrderShippingMethod({
            day: '',
            slotStart: '',
            slotEnd: '',
            type: '',
            vehicle_id: '',
          }),
        );
      }

      let plannedDate = {
        day: shippingMethodState?.day ?? '',
        slotStart: shippingMethodState?.slotStart ?? '',
        slotEnd: shippingMethodState?.slotEnd ?? '',
      };
      if (Object.keys(shippingMethodState).length === 0 || shippingMethodState?.day === '') {
        availableShippingMethods?.options?.some((option) => {
          return option.slots.some((slot) => {
            if (slot.available) {
              plannedDate = {
                day: option.day ?? '',
                slotStart: slot.startETA ?? '',
                slotEnd: slot.endETA ?? '',
              };
              return true;
            }
            return false;
          });
        });
      }

      setSelectedPlannedDate(plannedDate);
    }
  }, [availableShippingMethods, shippingMethodState]);

  const submitProgram = () => {
    if (
      selectedPlannedDate.day === '' ||
      selectedPlannedDate.slotStart === '' ||
      selectedPlannedDate.slotEnd === ''
    ) {
      Alerts.warningAlert(t('alerts.selectProgramDateMandatory'));
      return;
    }

    dispatch(
      setOrderShippingMethod({
        day: selectedPlannedDate.day,
        slotStart: selectedPlannedDate.slotStart,
        slotEnd: selectedPlannedDate.slotEnd,
        type: availableShippingMethods.type,
        vehicle_id: availableShippingMethods.vehicle_id,
      }),
    );
    modalClose();
  };

  const setProgrammedDate = (slotClicked) => {
    if (slotClicked.available) {
      setSelectedPlannedDate({
        ...selectedPlannedDate,
        slotStart: slotClicked.startETA,
        slotEnd: slotClicked.endETA,
      });
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={modalClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="program-delivery-modal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">{t('cart.scheduleDelivery')}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="program-order-pick-date">
          <span className="date-picker-title">{t('cart.chooseDay')}</span>
          <div className="pick-day-wrapper">
            {availableShippingMethods?.options?.map((option) => {
              return (
                <div
                  className={`date-option day-option ${
                    selectedPlannedDate.day === option.day ? 'selected' : ''
                  }`}
                  key={option.day}
                  onClick={() =>
                    setSelectedPlannedDate({ day: option.day, slotStart: '', slotEnd: '' })
                  }
                >
                  <span>{getDayNameByDate(option.day)}</span>
                  <span className="date">{orderHistoryDateFormat(option.day)}</span>
                </div>
              );
            })}
          </div>
        </div>
        {selectedPlannedDate?.day !== '' && (
          <div className="program-order-pick-time">
            <span className="date-picker-title">{t('cart.chooseTime')}</span>
            <div className="pick-time-wrapper">
              {availableShippingMethods &&
                availableShippingMethods?.options
                  ?.filter((option) => {
                    return option.day === selectedPlannedDate.day;
                  })[0]
                  ?.slots?.map((slot) => {
                    return (
                      <div
                        className={`date-option time-option ${!slot.available ? 'disabled' : ''} ${
                          selectedPlannedDate?.slotStart === slot.startETA && slot.available
                            ? 'selected'
                            : ''
                        }`}
                        key={`slot_${slot.startETA}`}
                        onClick={() => setProgrammedDate(slot)}
                      >
                        <span>{slot.timeSlot}</span>
                        {!slot.available && (
                          <span className="unavailable">{t('cart.unavailable')}</span>
                        )}
                      </div>
                    );
                  })}
            </div>
          </div>
        )}

        <div className="program-order-date">
          <span>{t('order.day')}</span>
          <span className="bold-text">
            {selectedPlannedDate?.day !== ''
              ? orderHistoryDateFormat(selectedPlannedDate?.day)
              : '-'}
          </span>
        </div>
        <div className="program-order-time">
          <span>{t('order.timeSlot')}</span>
          <span className="bold-text">
            {selectedPlannedDate?.slotStart !== ''
              ? orderDateETAFormat(selectedPlannedDate?.slotStart, selectedPlannedDate?.slotEnd)
              : '-'}
          </span>
        </div>
        <div className="green-button" onClick={submitProgram}>
          {t('cart.saveSchedule')}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProgramDeliveryModal;
