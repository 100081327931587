import ApiUtils from 'modules/ApiUtils';
import { default as ApiConstants } from 'constants/ApiConstants.json';

const getAllProductsAPI = ({ page, itemsPerPage }) =>
  ApiUtils.getRequest(
    `${ApiConstants.GET_PRODUCTS_ENDPOINT}?page=${page}&itemsPerPage=${itemsPerPage}`,
  );

const getProductAPI = ({ code }) =>
  ApiUtils.getRequest(`${ApiConstants.GET_PRODUCTS_ENDPOINT}/${code}`);

const getAccessoryProductsAPI = (payload) =>
  ApiUtils.getRequest(
    `${ApiConstants.GET_PRODUCTS_ENDPOINT}?productTaxons.taxon.code=ACCESSORIES&page=${payload.page}&itemsPerPage=${payload.itemsPerPage}`,
  );

export { getAllProductsAPI, getProductAPI, getAccessoryProductsAPI };
