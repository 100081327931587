import { storeDispatcher, storeStateGetter } from 'redux/ReduxStore';
import Alerts from 'components/Alerts';
import { stringHashCode } from 'modules/StringUtils';
import { fetchOrderDetailsRequest, triggerReviewRequest } from 'redux/actions/OrderActions';

const handledNotifications: number[] = [];

const onPushReceived = (pushPayload) => {
  const { isLoggedIn } = storeStateGetter('authState');

  const { data: customData, fcmMessageId } = pushPayload;
  const notificationId = stringHashCode(fcmMessageId);
  const { type: notificationType, order, body, title } = customData;

  const orderObject = JSON.parse(order);

  const { token } = orderObject;

  // Notification already handled
  if (handledNotifications.indexOf(notificationId) > -1) {
    return;
  }

  handledNotifications.push(notificationId);

  switch (notificationType) {
    case 'order_in_delivery':
      storeDispatcher(fetchOrderDetailsRequest(token));
      Alerts.actionAlert(title, body, 'Ok', () => {});
      break;
    case 'order_late_delivery':
      storeDispatcher(fetchOrderDetailsRequest(token));
      Alerts.actionAlert(title, body, 'Ok', () => {});
      break;
    case 'order_review':
      storeDispatcher(triggerReviewRequest({ token }));
      break;
    case 'order_5_minutes_away':
    default:
      Alerts.actionAlert(title, body, 'Ok', () => {});
      break;
  }
};

export default {
  onPushReceived,
};
