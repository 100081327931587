import { AnyAction } from 'redux';
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_ERROR,
  FORCE_LOGOUT_REQUEST,
  FORCE_LOGOUT_SUCCESS,
  FORCE_LOGOUT_ERROR,
  RESEND_TOKEN_REQUEST,
  RESEND_TOKEN_SUCCESS,
  RESEND_TOKEN_ERROR,
  SOCIAL_UNREGISTERED_USER,
} from 'redux/types/AuthTypes';

export const requestLogin = (payload: any): AnyAction => ({
  type: LOGIN_REQUEST,
  payload,
});

export const requestLoginError = (payload: any): AnyAction => ({
  type: LOGIN_ERROR,
  payload,
});

export const requestLoginSuccess = (payload: any): AnyAction => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const requestLogout = (payload: any): AnyAction => ({
  type: LOGOUT_REQUEST,
  payload,
});

export const requestLogoutError = (payload: any): AnyAction => ({
  type: LOGOUT_ERROR,
  payload,
});

export const requestLogoutSuccess = (payload: any): AnyAction => ({
  type: LOGOUT_SUCCESS,
  payload,
});

export const socialPreRegister = (payload: any): AnyAction => ({
  type: SOCIAL_UNREGISTERED_USER,
  payload,
});

export const forgotPasswordRequest = (payload: any): AnyAction => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload,
});

export const forgotPasswordSuccess = (payload: any): AnyAction => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordError = (payload: any): AnyAction => ({
  type: FORGOT_PASSWORD_ERROR,
  payload,
});

export const resetPasswordRequest = (payload: any): AnyAction => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccess = (payload: any): AnyAction => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordError = (payload: any): AnyAction => ({
  type: RESET_PASSWORD_ERROR,
  payload,
});

export const changePasswordRequest = (payload: any): AnyAction => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
});

export const changePasswordSuccess = (payload: any): AnyAction => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordError = (payload: any): AnyAction => ({
  type: CHANGE_PASSWORD_ERROR,
  payload,
});

export const deleteAccountRequest = (payload: any): AnyAction => ({
  type: DELETE_ACCOUNT_REQUEST,
  payload,
});

export const deleteAccountSuccess = (payload: any): AnyAction => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload,
});

export const deleteAccountError = (payload: any): AnyAction => ({
  type: DELETE_ACCOUNT_ERROR,
  payload,
});

export const verifyAccountRequest = (payload: any): AnyAction => ({
  type: VERIFY_ACCOUNT_REQUEST,
  payload,
});

export const verifyAccountSuccess = (payload: any): AnyAction => ({
  type: VERIFY_ACCOUNT_SUCCESS,
  payload,
});

export const verifyAccountError = (payload: any): AnyAction => ({
  type: VERIFY_ACCOUNT_ERROR,
  payload,
});

export const forceLogoutRequest = (payload): AnyAction => ({
  type: FORCE_LOGOUT_REQUEST,
  payload,
});

export const forceLogoutError = (payload): AnyAction => ({
  type: FORCE_LOGOUT_ERROR,
  payload,
});

export const forceLogoutSuccess = (payload): AnyAction => ({
  type: FORCE_LOGOUT_SUCCESS,
  payload,
});

export const resendTokenRequest = (payload): AnyAction => ({
  type: RESEND_TOKEN_REQUEST,
  payload,
});

export const resendTokenError = (payload): AnyAction => ({
  type: RESEND_TOKEN_ERROR,
  payload,
});

export const resendTokenSuccess = (payload): AnyAction => ({
  type: RESEND_TOKEN_SUCCESS,
  payload,
});
