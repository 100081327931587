import { default as ApiConstants } from 'constants/ApiConstants.json';
import ApiUtils from 'modules/ApiUtils';

const registerAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.REGISTER_ENDPOINT, payload);

const personalDataAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.getRequest(ApiConstants.PERSONAL_DATA_ENDPOINT, payload);

const contactAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.CONTACT_US_ENDPOINT, payload);

export { registerAPI, personalDataAPI, contactAPI };
