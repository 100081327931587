import { AnyAction } from 'redux';
import {
  ADD_ADDRESS_ERROR,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADDRESS_MODAL,
  CONTACT_INFO,
  CONTACT_INFO_MODAL,
  DELETE_ADDRESS_ERROR,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  FETCH_ADDRESS_LIST_ERROR,
  FETCH_ADDRESS_LIST_REQUEST,
  FETCH_ADDRESS_LIST_SUCCESS,
  LOCAL_ADDRESS,
  SELECT_ADDRESS_MODAL,
  SELECTED_ADDRESS_REQUEST,
  SELECTED_ADDRESS_ERROR,
  SELECTED_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_ERROR,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
} from 'redux/types/AddressTypes';

export const fetchAddressRequest = (payload): AnyAction => ({
  type: FETCH_ADDRESS_LIST_REQUEST,
  payload,
});

export const fetchAddressSuccess = (payload): AnyAction => ({
  type: FETCH_ADDRESS_LIST_SUCCESS,
  payload,
});

export const fetchAddressError = (payload): AnyAction => ({
  type: FETCH_ADDRESS_LIST_ERROR,
  payload,
});

export const addAddressRequest = (payload): AnyAction => ({
  type: ADD_ADDRESS_REQUEST,
  payload,
});

export const addAddressSuccess = (payload): AnyAction => ({
  type: ADD_ADDRESS_SUCCESS,
  payload,
});

export const addAddressError = (payload): AnyAction => ({
  type: ADD_ADDRESS_ERROR,
  payload,
});

export const deleteAddressRequest = (payload): AnyAction => ({
  type: DELETE_ADDRESS_REQUEST,
  payload,
});

export const deleteAddressSuccess = (payload): AnyAction => ({
  type: DELETE_ADDRESS_SUCCESS,
  payload,
});

export const deleteAddressError = (payload): AnyAction => ({
  type: DELETE_ADDRESS_ERROR,
  payload,
});

export const updateAddressRequest = (payload): AnyAction => ({
  type: UPDATE_ADDRESS_REQUEST,
  payload,
});

export const updateAddressSuccess = (payload): AnyAction => ({
  type: UPDATE_ADDRESS_SUCCESS,
  payload,
});

export const updateAddressError = (payload): AnyAction => ({
  type: UPDATE_ADDRESS_ERROR,
  payload,
});

export const setLocalAddress = (payload): AnyAction => ({
  type: LOCAL_ADDRESS,
  payload,
});

export const setSelectedAddressSuccess = (payload): AnyAction => ({
  type: SELECTED_ADDRESS_SUCCESS,
  payload,
});

export const setSelectedAddressError = (payload): AnyAction => ({
  type: SELECTED_ADDRESS_ERROR,
  payload,
});

export const setSelectedAddress = (payload): AnyAction => ({
  type: SELECTED_ADDRESS_REQUEST,
  payload,
});

export const setAddressModal = (payload): AnyAction => ({
  type: ADDRESS_MODAL,
  payload,
});

export const setSelectedAddressModal = (payload): AnyAction => ({
  type: SELECT_ADDRESS_MODAL,
  payload,
});

export const setContactInfoModal = (payload): AnyAction => ({
  type: CONTACT_INFO_MODAL,
  payload,
});

export const setContactInfo = (payload): AnyAction => ({
  type: CONTACT_INFO,
  payload,
});
