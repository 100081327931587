import arrow from 'assets/images/arrow-right.svg';

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={arrow} alt="Prev" />
    </div>
  );
};

export default PrevArrow;
