import { useEffect, useState } from 'react';
import Address from 'types/Address';

const useAddress = (address: Address) => {
  const [addressFirstName, setAddressFirstName] = useState(address?.firstName ?? '');
  const [addressLastName, setAddressLastName] = useState(address?.lastName ?? '');
  const [addressNumber, setAddressNumber] = useState(address?.phoneNumber ?? '');
  const [addressCountryCode, setAddressCountryCode] = useState(address?.countryCode ?? 'RO');
  const [addressUnRegistrationNum, setAddressUnRegistrationNum] = useState(
    address?.uniqueRegistrationCode ?? '',
  );
  const [cartCommercialRegisterNumber, setCartCommercialRegisterNumber] = useState(
    address?.commercialRegisterNumber ?? '',
  );
  const [cartBankName, setCartBankName] = useState(address?.bankName ?? '');
  const [cartBankAccount, setCartBankAccount] = useState(address?.bankAccount ?? '');
  const [addressCompany, setAddressCompany] = useState(address?.company ?? '');

  const handleAddressFirstNameChange = (e) => setAddressFirstName(e.target.value.trim());
  const handleAddressLastNameChange = (e) => setAddressLastName(e.target.value.trim());
  const handleAddressNumber = (e) => setAddressNumber(e.target.value.trim());
  const handleAddressUnRegistrationNumChange = (e) =>
    setAddressUnRegistrationNum(e.target.value.trim());
  const handleCartCommercialRegisterNumberChange = (e) =>
    setCartCommercialRegisterNumber(e.target.value.trim());
  const handleCartBankNameNumberChange = (e) => setCartBankName(e.target.value.trim());
  const handleCartBankAccountChange = (e) => setCartBankAccount(e.target.value.trim());
  const handleAddressCompanyChange = (e) => setAddressCompany(e.target.value.trim());

  useEffect(() => {
    setAddressFirstName(address?.firstName ?? '');
    setAddressLastName(address?.lastName ?? '');
    setAddressNumber(address?.phoneNumber ?? '');
    setAddressCountryCode(address?.countryCode ?? '');
    setAddressUnRegistrationNum(address?.uniqueRegistrationCode ?? '');
    setCartCommercialRegisterNumber(address?.commercialRegisterNumber ?? '');
    setCartBankName(address?.bankName ?? '');
    setCartBankAccount(address?.bankAccount ?? '');
    setAddressCompany(address?.company ?? '');
  }, [address]);

  return {
    addressFirstName,
    addressLastName,
    addressNumber,
    addressCountryCode,
    addressUnRegistrationNum,
    cartCommercialRegisterNumber,
    cartBankName,
    cartBankAccount,
    addressCompany,
    setAddressFirstName,
    setAddressLastName,
    setAddressNumber,
    setAddressCountryCode,
    setAddressUnRegistrationNum,
    setCartCommercialRegisterNumber,
    setCartBankName,
    setCartBankAccount,
    setAddressCompany,
    handleAddressFirstNameChange,
    handleAddressLastNameChange,
    handleAddressNumber,
    handleAddressUnRegistrationNumChange,
    handleCartCommercialRegisterNumberChange,
    handleCartBankNameNumberChange,
    handleCartBankAccountChange,
    handleAddressCompanyChange,
  };
};

export default useAddress;
