import { AnyAction } from 'redux';
import {
  CONTACT_US_ERROR,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  PERSONAL_DATA_ERROR,
  PERSONAL_DATA_REQUEST,
  PERSONAL_DATA_SUCCESS,
  REGISTER_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from 'redux/types/UserTypes';

export const registerRequest = (payload: any): AnyAction => ({
  type: REGISTER_REQUEST,
  payload,
});

export const registerSuccess = (payload: any): AnyAction => ({
  type: REGISTER_SUCCESS,
  payload,
});

export const registerError = (payload: any): AnyAction => ({
  type: REGISTER_ERROR,
  payload,
});

export const personalDataRequest = (payload: any): AnyAction => ({
  type: PERSONAL_DATA_REQUEST,
  payload,
});

export const personalDataSuccess = (payload: any): AnyAction => ({
  type: PERSONAL_DATA_SUCCESS,
  payload,
});

export const personalDataError = (payload: any): AnyAction => ({
  type: PERSONAL_DATA_ERROR,
  payload,
});

export const contactUsRequest = (payload: any): AnyAction => ({
  type: CONTACT_US_REQUEST,
  payload,
});

export const contactUsSuccess = (payload: any): AnyAction => ({
  type: CONTACT_US_SUCCESS,
  payload,
});

export const contactUsError = (payload: any): AnyAction => ({
  type: CONTACT_US_ERROR,
  payload,
});
