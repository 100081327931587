import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { getAccessoryProductsAPI, getAllProductsAPI, getProductAPI } from 'redux/api/ProductApi';
import {
  allProductsFetchError,
  allProductsFetchSuccess,
  fetchAccessoryProductsError,
  fetchAccessoryProductsSuccess,
  productFetchError,
  productFetchSuccess,
} from 'redux/actions/ProductActions';
import {
  accessoryProductsSelector,
  allProductsSelector,
  productSelector,
} from 'redux/selector/ProductSelector';
import {
  ALL_PRODUCTS_FETCH_REQUEST,
  FETCH_ACCESSORY_PRODUCTS_REQUEST,
  PRODUCT_FETCH_REQUEST,
} from 'redux/types/ProductTypes';

function* getAllProducts(actionType: ActionPattern<Action<any>>) {
  const getProductsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getProductsChannel);
    try {
      const safeAction = {
        itemsPerPage: 30,
        page: 1,
        ...payload,
      };
      const response = yield call(getAllProductsAPI, safeAction);
      yield put(
        allProductsFetchSuccess({
          data: allProductsSelector(response.data),
        }),
      );
    } catch ({ message }) {
      yield put(allProductsFetchError({ message: ` ` }));
    }
  }
}

function* getProduct(actionType: ActionPattern<Action<any>>) {
  const getProductChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getProductChannel);
    try {
      const safeAction = {
        ...payload,
      };
      const response = yield call(getProductAPI, safeAction);
      yield put(
        productFetchSuccess({
          data: productSelector(response.data),
        }),
      );
    } catch ({ message }) {
      yield put(productFetchError({ message }));
    }
  }
}

function* getAccessoryProducts(actionType: ActionPattern<Action<any>>) {
  const getAccessoryProductsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getAccessoryProductsChannel);
    try {
      const safeAction = {
        itemsPerPage: 20,
        page: 1,
        ...payload,
      };
      const response = yield call(getAccessoryProductsAPI, safeAction);
      yield put(fetchAccessoryProductsSuccess(accessoryProductsSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchAccessoryProductsError({ message }));
    }
  }
}

function* productSaga() {
  yield fork(getAllProducts, ALL_PRODUCTS_FETCH_REQUEST);
  yield fork(getProduct, PRODUCT_FETCH_REQUEST);
  yield fork(getAccessoryProducts, FETCH_ACCESSORY_PRODUCTS_REQUEST);
}

export default productSaga;
