import 'styles/pages/OrderHistoryPage.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OrdersHistoryOrderBlock from 'components/OrdersHistoryOrderBlock';
import {
  fetchOrderDetailsRequest,
  activeOrdersFetchRequest,
  inactiveOrdersFetchRequest,
} from 'redux/actions/OrderActions';
import { getActiveOrdersState, getInactiveOrdersState } from 'redux/src/StatesGetter';

const OrderHistoryPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: activeOrders, hasMoreActive } = useSelector(getActiveOrdersState, shallowEqual);
  const { data: inactiveOrders, hasMoreInactive } = useSelector(
    getInactiveOrdersState,
    shallowEqual,
  );

  const itemsPerPage = 10;

  const [activePage, setActivePage] = useState(1);
  const [inactivePage, setInactivePage] = useState(1);

  useEffect(() => {
    dispatch(
      activeOrdersFetchRequest({
        state: 'active',
        page: activePage,
        itemsPerPage,
      }),
    );

    dispatch(
      inactiveOrdersFetchRequest({
        state: 'inactive',
        page: inactivePage,
        itemsPerPage,
      }),
    );
  }, []);

  const orderClick = (token) => {
    navigate(`/orders/${token}`);
  };

  const loadNextActivePage = () => {
    dispatch(
      activeOrdersFetchRequest({
        state: 'active',
        page: activePage + 1,
        itemsPerPage,
      }),
    );
    setActivePage(activePage + 1);
  };

  const loadNextInactivePage = () => {
    dispatch(
      inactiveOrdersFetchRequest({
        state: 'inactive',
        page: inactivePage + 1,
        itemsPerPage,
      }),
    );
    setInactivePage(inactivePage + 1);
  };

  return (
    <div className="orders-page-wrapper container">
      <div className="orders-page-title">{t('order.orders')}</div>
      <div className="orders-page-content">
        <div className="orders-flex-wrapper">
          <div className="active-orders-block">
            <div className="orders-section-title">
              <span>{t('order.activeOrder')}</span>
            </div>
            <div className="orders-wrapper">
              {activeOrders && activeOrders?.length > 0 ? (
                activeOrders?.map((order) => (
                  <OrdersHistoryOrderBlock key={order.id} order={order} clickEvent={orderClick} />
                ))
              ) : (
                <div>{t('ordersPage.noActiveOrders')}</div>
              )}
              {hasMoreActive && (
                <div className="load-more-orders-btn" onClick={loadNextActivePage}>
                  {t('ordersPage.loadMoreOrders')}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="orders-flex-wrapper">
          <div className="completed-orders-block">
            <div className="orders-section-title">
              <span>{t('order.completedOrders')}</span>
            </div>
            <div className="orders-wrapper">
              {inactiveOrders && inactiveOrders?.length > 0 ? (
                inactiveOrders?.map((order) => (
                  <OrdersHistoryOrderBlock key={order.id} order={order} clickEvent={orderClick} />
                ))
              ) : (
                <div>{t('ordersPage.noPrevOrders')}</div>
              )}
              {hasMoreInactive && (
                <div className="load-more-orders-btn" onClick={loadNextInactivePage}>
                  {t('ordersPage.loadMoreOrders')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryPage;
