import 'styles/pages/ProfilePage.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchProfileRequest } from 'redux/actions/ProfileActions';
import { Col, Dropdown, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import { requestLogout } from 'redux/actions/AuthActions';
import { useTranslation } from 'react-i18next';
import { getAuthState, getUserProfileState } from 'redux/src/StatesGetter';
import AccountPersonalDataBlock from 'components/AccountPersonalDataBlock';
import profileIcon from 'assets/images/profile-icon-green.svg';
import addressIcon from 'assets/images/address-green-icon.svg';
import settingsIcon from 'assets/images/settings-icon.svg';
import companyIcon from 'assets/images/company-icon.svg';
import contactIcon from 'assets/images/contact-icon.svg';
import returnPolicyIcon from 'assets/images/return-policy-icon.svg';
import informationLegalIcon from 'assets/images/information-legal-icon.svg';
import changePassIcon from 'assets/images/change-pass-icon.svg';
import anpcIcon from 'assets/images/anpc-icon.svg';
import disconnectIcon from 'assets/images/disconnect-icon.svg';
import hamburgerMenu from 'assets/images/hamburger-menu.svg';
import navArrow from 'assets/images/nav-arrow-down.svg';

import AccountChangePasswordBlock from 'components/AccountChangePasswordBlock';
import AccountAddressBlock from 'components/AccountAddressBlock';
import AccountCompaniesBlock from 'components/AccountCompaniesBlock';
import AccountSettingsBlock from 'components/AccountSettingsBlock';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getUserProfileState, shallowEqual);
  const navigate = useNavigate();
  const { fullName, phoneNumber } = profile;

  useEffect(() => {
    dispatch(fetchProfileRequest({}));
  }, []);

  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(requestLogout({}));
  };

  const onAnpc = (e) => {
    e.preventDefault();
    navigate('/anpc');
  };

  const navigateToPage = (e) => {
    e.preventDefault();
    navigate('/anpc');
  };

  const [backDrop, setBackDrop] = useState('fade dropdown-backdrop');

  const onToggle = (e) => {
    if (e === true) {
      setBackDrop('fade dropdown-backdrop show');
    } else {
      setBackDrop('fade dropdown-backdrop');
    }
  };

  return (
    <div className="container account">
      <div className="title-section">
        <h1>{t('account.account')}</h1>
      </div>
      <Tab.Container id="tabs-container" defaultActiveKey="none-selected">
        <Row className="account-content-wrapper">
          <Col className="tabs-wrapper col-12 col-lg-4">
            <div className="tabs-header">
              <div className="account-full-name">{fullName}</div>
              <div className="account-phone-number">{phoneNumber}</div>
            </div>
            <div className="menu-account-mobile">
              <Dropdown onToggle={onToggle}>
                <Dropdown.Toggle id="dropdown-basic">
                  <img className="burger-menu-icon" src={hamburgerMenu} alt="burger-menu-icon" />
                  {t('account.accountMenu')}
                  <img className="arrow-down" src={navArrow} alt="nav-arrow" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Nav variant="tabs">
                    <Nav.Item className="tab">
                      <Nav.Link eventKey="profile">
                        <img src={profileIcon} alt="profile-icon" />
                        <div>{t('settings.personalData')}</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="tab">
                      <Nav.Link eventKey="saved-address">
                        <img src={addressIcon} alt="address-icon" />
                        <div>{t('settings.myAddresses')}</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="tab">
                      <Nav.Link eventKey="saved-companies">
                        <img src={companyIcon} alt="company-icon" />
                        <div>{t('settings.customerCompanies')}</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="tab">
                      <Nav.Link eventKey="changePass">
                        <img src={changePassIcon} alt="change-pass-icon" />
                        <div>{t('settings.changePassword')}</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="tab">
                      <Nav.Link eventKey="settings">
                        <img src={settingsIcon} alt="settings-icon" />
                        <div>{t('settings.settings')}</div>
                      </Nav.Link>
                    </Nav.Item>
                    <div className="delimeter-div" />
                    <Nav.Item className="tab">
                      <Nav.Link
                        eventKey="contact"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/contact');
                        }}
                      >
                        <img src={contactIcon} alt="contact-icon" />
                        <div>{t('contact')}</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="tab">
                      <Nav.Link
                        eventKey="terms"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/terms-and-conditions');
                        }}
                      >
                        <img src={informationLegalIcon} alt="terms-icon" />
                        <div>{t('support.terms')}</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="tab">
                      <Nav.Link
                        eventKey="policy"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/privacy-policy');
                        }}
                      >
                        <img src={informationLegalIcon} alt="policy-icon" />
                        <div>{t('support.policy')}</div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="tab">
                      <Nav.Link
                        eventKey="returnPolicy"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/return-policy');
                        }}
                      >
                        <img src={returnPolicyIcon} alt="returnPolicy-icon" />
                        <div>{t('support.returnPolicy')}</div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="tab">
                      <Nav.Link
                        eventKey="anpc"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/anpc');
                        }}
                      >
                        <img src={anpcIcon} alt="anpc-icon" />
                        <div>{t('settings.anpc')}</div>
                      </Nav.Link>
                    </Nav.Item>
                    <div className="delimeter-div" />
                    <Nav.Item className="tab">
                      <Nav.Link eventKey="disconnect" onClick={onLogOut}>
                        <img src={disconnectIcon} alt="disconnect-icon" />
                        <div>{t('settings.disconnect')}</div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Dropdown.Menu>
              </Dropdown>
              <div className={backDrop} />
            </div>
            <Nav variant="pills" className="flex-column">
              <Nav.Item className="tab">
                <Nav.Link eventKey="profile">
                  <img src={profileIcon} alt="profile-icon" />
                  <div>{t('settings.personalData')}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab">
                <Nav.Link eventKey="saved-address">
                  <img src={addressIcon} alt="address-icon" />
                  <div>{t('settings.myAddresses')}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab">
                <Nav.Link eventKey="saved-companies">
                  <img src={companyIcon} alt="company-icon" />
                  <div>{t('settings.customerCompanies')}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab">
                <Nav.Link eventKey="changePass">
                  <img src={changePassIcon} alt="change-pass-icon" />
                  <div>{t('settings.changePassword')}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab">
                <Nav.Link eventKey="settings">
                  <img src={settingsIcon} alt="settings-icon" />
                  <div>{t('settings.settings')}</div>
                </Nav.Link>
              </Nav.Item>
              <div className="delimeter-div" />
              <Nav.Item className="tab">
                <Nav.Link
                  eventKey="contact"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/contact');
                  }}
                >
                  <img src={contactIcon} alt="contact-icon" />
                  <div>{t('contact')}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab">
                <Nav.Link
                  eventKey="terms"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/terms-and-conditions');
                  }}
                >
                  <img src={informationLegalIcon} alt="terms-icon" />
                  <div>{t('support.terms')}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab">
                <Nav.Link
                  eventKey="policy"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/privacy-policy');
                  }}
                >
                  <img src={informationLegalIcon} alt="policy-icon" />
                  <div>{t('support.policy')}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab">
                <Nav.Link
                  eventKey="returnPolicy"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/return-policy');
                  }}
                >
                  <img src={returnPolicyIcon} alt="returnPolicy-icon" />
                  <div>{t('support.returnPolicy')}</div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="tab">
                <Nav.Link
                  eventKey="anpc"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/anpc');
                  }}
                >
                  <img src={anpcIcon} alt="anpc-icon" />
                  <div>{t('settings.anpc')}</div>
                </Nav.Link>
              </Nav.Item>
              <div className="delimeter-div" />
              <Nav.Item className="tab">
                <Nav.Link eventKey="disconnect" onClick={onLogOut}>
                  <img src={disconnectIcon} alt="disconnect-icon" />
                  <div>{t('settings.disconnect')}</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col className="tab-content col-12 col-lg-8">
            <Tab.Pane eventKey="none-selected">
              <div className="none-selected-tab">
                <span>{t('settings.chooseSectionMyAccount')}</span>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="profile">
              <AccountPersonalDataBlock profile={profile} />
            </Tab.Pane>
            <Tab.Pane eventKey="saved-address">
              <AccountAddressBlock />
            </Tab.Pane>
            <Tab.Pane eventKey="saved-companies">
              <AccountCompaniesBlock />
            </Tab.Pane>
            <Tab.Pane eventKey="changePass">
              <AccountChangePasswordBlock profile={profile} />
            </Tab.Pane>
            <Tab.Pane eventKey="settings">
              <AccountSettingsBlock />
            </Tab.Pane>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default ProfilePage;
