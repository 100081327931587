export const INACTIVE_ORDERS_FETCH_REQUEST = 'INACTIVE_ORDERS_FETCH_REQUEST';
export const INACTIVE_ORDERS_FETCH_SUCCESS = 'INACTIVE_ORDERS_FETCH_SUCCESS';
export const INACTIVE_ORDERS_FETCH_ERROR = 'INACTIVE_ORDERS_FETCH_ERROR';

export const ACTIVE_ORDERS_FETCH_REQUEST = 'ACTIVE_ORDERS_FETCH_REQUEST';
export const ACTIVE_ORDERS_FETCH_SUCCESS = 'ACTIVE_ORDERS_FETCH_SUCCESS';
export const ACTIVE_ORDERS_FETCH_ERROR = 'ACTIVE_ORDERS_FETCH_ERROR';

export const FETCH_LAST_ORDER_REQUEST = 'FETCH_LAST_ORDER_REQUEST';
export const FETCH_LAST_ORDER_SUCCESS = 'FETCH_LAST_ORDER_SUCCESS';
export const FETCH_LAST_ORDER_ERROR = 'FETCH_LAST_ORDER_ERROR';

export const FETCH_ORDER_DETAILS_REQUEST = 'FETCH_ORDER_DETAILS_REQUEST';
export const FETCH_ORDER_DETAILS_SUCCESS = 'FETCH_ORDER_DETAILS_SUCCESS';
export const FETCH_ORDER_DETAILS_ERROR = 'FETCH_ORDER_DETAILS_ERROR';

export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_ERROR = 'FETCH_CART_ERROR';

export const CREATE_CART_REQUEST = 'CREATE_CART_REQUEST';
export const CREATE_CART_SUCCESS = 'CREATE_CART_SUCCESS';
export const CREATE_CART_ERROR = 'CREATE_CART_ERROR';

export const CREATE_CART_FROM_ORDER_REQUEST = 'CREATE_CART_FROM_ORDER_REQUEST';
export const CREATE_CART_FROM_ORDER_SUCCESS = 'CREATE_CART_FROM_ORDER_SUCCESS';
export const CREATE_CART_FROM_ORDER_ERROR = 'CREATE_CART_FROM_ORDER_ERROR';

export const DELETE_CART_REQUEST = 'DELETE_CART_REQUEST';
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS';
export const DELETE_CART_ERROR = 'DELETE_CART_ERROR';

export const ADD_ORDER_ITEM_REQUEST = 'ADD_ORDER_ITEM_REQUEST';
export const ADD_ORDER_ITEM_SUCCESS = 'ADD_ORDER_ITEM_SUCCESS';
export const ADD_ORDER_ITEM_ERROR = 'ADD_ORDER_ITEM_ERROR';

export const CHANGE_ORDER_ITEM_QUANTITY_REQUEST = 'CHANGE_ORDER_ITEM_QUANTITY_REQUEST';
export const CHANGE_ORDER_ITEM_QUANTITY_SUCCESS = 'CHANGE_ORDER_ITEM_QUANTITY_SUCCESS';
export const CHANGE_ORDER_ITEM_QUANTITY_ERROR = 'CHANGE_ORDER_ITEM_QUANTITY_ERROR';

export const REMOVE_ORDER_ITEM_REQUEST = 'REMOVE_ORDER_ITEM_REQUEST';
export const REMOVE_ORDER_ITEM_SUCCESS = 'REMOVE_ORDER_ITEM_SUCCESS';
export const REMOVE_ORDER_ITEM_ERROR = 'REMOVE_ORDER_ITEM_ERROR';

export const SET_ORDER_ADDRESS_REQUEST = 'SET_ORDER_ADDRESS_REQUEST';
export const SET_ORDER_ADDRESS_SUCCESS = 'SET_ORDER_ADDRESS_SUCCESS';
export const SET_ORDER_ADDRESS_ERROR = 'SET_ORDER_ADDRESS_ERROR';

export const SET_ORDER_PAYMENT_METHOD_REQUEST = 'SET_ORDER_PAYMENT_METHOD_REQUEST';
export const SET_ORDER_PAYMENT_METHOD_SUCCESS = 'SET_ORDER_PAYMENT_METHOD_SUCCESS';
export const SET_ORDER_PAYMENT_METHOD_ERROR = 'SET_ORDER_PAYMENT_METHOD_ERROR';

export const COMPLETE_ORDER_ON_CHECKOUT_REQUEST = 'COMPLETE_ORDER_ON_CHECKOUT_REQUEST';
export const COMPLETE_ORDER_ON_CHECKOUT_SUCCESS = 'COMPLETE_ORDER_ON_CHECKOUT_SUCCESS';
export const COMPLETE_ORDER_ON_CHECKOUT_ERROR = 'COMPLETE_ORDER_ON_CHECKOUT_ERROR';

export const FETCH_ORDER_SHIPPING_METHODS_REQUEST = 'FETCH_ORDER_SHIPPING_METHODS_REQUEST';
export const FETCH_ORDER_SHIPPING_METHODS_SUCCESS = 'FETCH_ORDER_SHIPPING_METHODS_SUCCESS';
export const FETCH_ORDER_SHIPPING_METHODS_ERROR = 'FETCH_ORDER_SHIPPING_METHODS_ERROR';

export const SET_ORDER_SHIPPING_METHOD = 'SET_ORDER_SHIPPING_METHOD';

export const FETCH_ORDER_PAYMENT_METHODS_REQUEST = 'FETCH_ORDER_PAYMENT_METHODS_REQUEST';
export const FETCH_ORDER_PAYMENT_METHODS_SUCCESS = 'FETCH_ORDER_PAYMENT_METHODS_SUCCESS';
export const FETCH_ORDER_PAYMENT_METHODS_ERROR = 'FETCH_ORDER_PAYMENT_METHODS_ERROR';

export const COMPANY_INVOICE_TYPE = 'COMPANY_INVOICE_TYPE';

export const REVIEW_MODAL = 'REVIEW_MODAL';

export const ADD_REVIEW_REQUEST = 'ADD_REVIEW_REQUEST';
export const ADD_REVIEW_SUCCESS = 'ADD_REVIEW_SUCCESS';
export const ADD_REVIEW_ERROR = 'ADD_REVIEW_ERROR';

export const REVIEW_LATER = 'REVIEW_LATER';

export const TRIGGER_REVIEW_REQUEST = 'TRIGGER_REVIEW_REQUEST';
export const TRIGGER_REVIEW_SUCCESS = 'TRIGGER_REVIEW_SUCCESS';
export const TRIGGER_REVIEW_ERROR = 'TRIGGER_REVIEW_ERROR';
