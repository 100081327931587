import CalendarConstants from 'constants/CalendarConstants';
import { scheduleArray, scheduleDay } from 'types/Schedule';
import i18next from 'i18next';

const generateScheduleDate = (day: string, date: Date): string => {
  const locale = i18next.language;
  return `${day.charAt(0).toUpperCase() + day.slice(1).toLowerCase()} · ${date.getDate()} ${
    CalendarConstants[locale].monthNamesShort[date.getMonth()]
  }`;
};

const scheduleSelector = (data: scheduleArray) => {
  const finalSchedule: scheduleDay[] = [];
  Object.entries(data).forEach(([key, value]) => {
    finalSchedule.push({
      day: key,
      date: generateScheduleDate(key, new Date(value?.date?.date)),
      status: value?.status,
      timeEnd: value?.timeEnd?.slice(0, -3) ?? '',
      timeStart: value?.timeStart?.slice(0, -3) ?? '',
    });
  });
  return finalSchedule;
};

export { scheduleSelector };
