import { default as ApiConstants } from 'constants/ApiConstants.json';
import ApiUtils from 'modules/ApiUtils';

const createDeviceAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.CREATE_DEVICE_ENDPOINT, payload);

const updateDeviceAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.UPDATE_DEVICE_ENDPOINT, payload);

const associateDeviceAPI = ({ regId }) =>
  ApiUtils.postRequest(`${ApiConstants.ASSOCIATE_DEVICE_ENDPOINT}/${regId}`, {});

export { createDeviceAPI, updateDeviceAPI, associateDeviceAPI };
