import { AnyAction } from 'redux';
import { GET_CHANNEL_ERROR, GET_CHANNEL_REQUEST, GET_CHANNEL_SUCCESS } from '../types/GeneralTypes';

export const getChannelRequest = (payload: any): AnyAction => ({
  type: GET_CHANNEL_REQUEST,
  payload,
});

export const getChannelSuccess = (payload: any): AnyAction => ({
  type: GET_CHANNEL_SUCCESS,
  payload,
});

export const getChannelError = (payload: any): AnyAction => ({
  type: GET_CHANNEL_ERROR,
  payload,
});
