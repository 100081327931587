import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/SelectModal.scss';
import { Modal, ModalFooter } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getCartState,
  getCustomerCompaniesState,
  getSelectCompanyModalState,
  getSelectedCompanyState,
} from 'redux/src/StatesGetter';
import warningIcon from 'assets/images/warning-icon.svg';
import plusIcon from 'assets/images/plus-icon.svg';
import {
  setCompanyModal,
  setLocalCompany,
  setSelectedCompany,
  setSelectedCompanyModal,
} from 'redux/actions/CompanyActions';
import React, { useState } from 'react';
import { setCompanyInvoiceType, setOrderAddressRequest } from '../../redux/actions/OrderActions';

const SelectCompanyModal = () => {
  const { t, i18n } = useTranslation();
  const companies = useSelector(getCustomerCompaniesState, shallowEqual);
  const dispatch = useDispatch();

  const selectedCompany = useSelector(getSelectedCompanyState, shallowEqual);
  const modalState = useSelector(getSelectCompanyModalState, shallowEqual);

  const { billingAddress, shippingAddress } = useSelector(getCartState, shallowEqual);

  const closeSelectCompanyModal = () => {
    dispatch(setSelectedCompanyModal({ open: false }));
    dispatch(setCompanyInvoiceType(false));
  };

  const selectCompany = (clickedCompany) => {
    dispatch(setSelectedCompany(clickedCompany));
    dispatch(setSelectedCompanyModal({ open: false }));
  };

  const openAddCompanyModal = () => {
    dispatch(
      setCompanyModal({
        open: true,
        setSelected: true,
        changeToPhysical: true,
      }),
    );
  };

  const addNewCompany = () => {
    dispatch(setLocalCompany({}));
    dispatch(setSelectedCompanyModal({ open: false }));
    openAddCompanyModal();
  };

  return (
    <>
      <Modal
        show={modalState.open}
        onHide={closeSelectCompanyModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="selectModal"
      >
        <Modal.Header closeButton>
          <span className="modal-header-text">{t('cart.selectBillingCompany')}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="add-new-button" onClick={addNewCompany}>
            <img src={plusIcon} alt="plus-icon" />
            <span>{t('addNewCompany')}</span>
          </div>
          <div className="saved-companies-text">{t('settings.customerCompanies')}</div>
          <div className="companies-wrapper">
            {companies?.data?.length > 0 ? (
              companies?.data?.map((company) => {
                return (
                  <div className="company" key={company.id}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="company"
                      id={`company-${company.id}`}
                      value={company}
                      checked={company.id === selectedCompany.id}
                      onChange={() => selectCompany(company)}
                    />
                    <label className="text" htmlFor={`company-${company.id}`}>
                      <span className="name">{`${company.company}`}</span>
                      <span>{`CUI: ${company.uniqueRegistrationCode}`}</span>
                      <span>{`${company.street} ${company.city}`}</span>
                    </label>
                  </div>
                );
              })
            ) : (
              <div className="no-selections-message">
                <img src={warningIcon} alt="warning-icon" />
                {t('cart.noCompaniesInAccount')}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SelectCompanyModal;
