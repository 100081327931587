import 'styles/pages/MainPage.scss';
import 'styles/slickSlider.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import homeBackground from 'assets/images/homepage-backgroun.jpg';
import noDeliveryZone from 'assets/images/no-delivery-zone-icon.svg';
import arrowRight from 'assets/images/arrow-right-2.svg';
import callIcon from 'assets/images/call-icon.svg';
import {
  getAllProductsState,
  getAuthState,
  getDeviceState,
  getLastOrderState,
  getSelectedAddressState,
  getTodayScheduleState,
  getUserProfileState,
  getWeeklyScheduleState,
} from 'redux/src/StatesGetter';
import { allProductsFetchRequest } from 'redux/actions/ProductActions';
import HomeProductCard from 'components/HomeProductCard';
import AddToCartModal from 'components/modals/AddToCartModal';
import Slider from 'react-slick';
import NextArrow from 'components/slider/NextArrow';
import PrevArrow from 'components/slider/PrevArrow';
import repeatOrderIcon from 'assets/images/repeat-order.svg';
import repeatOrderButtonIcon from 'assets/images/repeat-order-button.svg';
import { createCartFromOrderRequest, lastOrderFetchRequest } from 'redux/actions/OrderActions';
import { getDayName } from 'modules/DateUtils';
import {
  getDayScheduleRequest,
  getWeeklyScheduleRequest,
  weeklyScheduleModal,
} from 'redux/actions/ScheduleActions';
import { useTranslation } from 'react-i18next';
import NextDaysScheduleModal from '../components/modals/NextDaysScheduleModal';
import { capitalizeFirstLetter } from '../modules/Utils';

const MainPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getUserProfileState, shallowEqual);
  const device = useSelector(getDeviceState, shallowEqual);
  const schedule = useSelector(getWeeklyScheduleState, shallowEqual);

  const slider = useRef<Slider>(null);

  const { regId } = device;
  const { data: products } = useSelector(getAllProductsState, shallowEqual);
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const selectedAddress = useSelector(getSelectedAddressState, shallowEqual);
  const { delivery } = selectedAddress;
  const order = useSelector(getLastOrderState, shallowEqual);

  const openCartModal = (item) => {
    setSelectedProduct(item);
    setAddToCartModal(true);
  };
  const closeCartModal = () => setAddToCartModal(false);
  const repeatLastOrder = () => {
    dispatch(
      createCartFromOrderRequest({
        orderNumber: order[0].number,
        platform: '2',
        latitude: parseFloat(selectedAddress.latitude),
        longitude: parseFloat(selectedAddress.longitude),
        deviceRegId: regId ?? null,
      }),
    );
  };
  const openScheduleModal = () => {
    dispatch(weeklyScheduleModal({ open: true }));
  };

  useEffect(() => {
    dispatch(allProductsFetchRequest({}));
    dispatch(getWeeklyScheduleRequest({}));

    if (isLoggedIn) {
      dispatch(lastOrderFetchRequest({ itemsPerPage: 1 }));
    }
  }, []);

  useEffect(() => {
    slider?.current?.slickGoTo(0);
  }, [products]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="full homepage-wrapper"
      style={{ background: `url(${homeBackground})`, backgroundSize: 'cover' }}
    >
      <Container>
        <div className="home-top-section">
          <span className="home-greeting-msg">
            <div>{`${t('salutMessage')}${!isLoggedIn ? '.' : ', '}`} </div>
            {isLoggedIn ? <strong>{capitalizeFirstLetter(profile?.firstName) ?? ''}</strong> : ''}
          </span>
          <div className="home-program-wrapper">
            {schedule.length > 0 && (
              <>
                <span className="home-program-head">{t('scheduleModal.program')}</span>
                <div className="home-program" onClick={openScheduleModal}>
                  <span className="home-program-day-range">{`${schedule[0].date}`}</span>
                  <span className="home-program-hour-range">
                    {schedule[0].status === 'workday'
                      ? `${schedule[0].timeStart} - ${schedule[0].timeEnd}`
                      : t('scheduleModal.closed')}
                  </span>
                  <img src={arrowRight} alt="arrow-right" />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="home-products-wrapper">
          <div className="home-products-header">{t('orderWater')}</div>
          {delivery && isLoggedIn && (!delivery || !delivery?.ok) ? (
            <div className="no-delivery-zone-wrapper">
              <img src={noDeliveryZone} alt="no-delivery-zone" />
              <span>Ne pare rău, însă momentan nu livrăm la adresa selectată.</span>
            </div>
          ) : delivery?.type === 'call' ? (
            <div className="only-call-delivery">
              <span className="only-call-delivery-text">{t('product.onlyPhoneOrders')}</span>
              <div className="only-call-delivery-phone">
                <img src={callIcon} alt="call-icon" />
                <span>{delivery.phoneNumber}</span>
              </div>
            </div>
          ) : (
            <div className="home-products-slider">
              <Slider ref={slider} {...settings}>
                {order.length > 0 && (
                  <div className="repeat-card">
                    <div className="repeat-card-image-wrapper">
                      <div className="repeat-card-image">
                        <img src={repeatOrderIcon} alt="Repeat Order" />
                      </div>
                    </div>
                    <div className="repeat-card-content">
                      <div className="last-order-text">{t('lastOrder')}</div>

                      {order[0].items.map((item) => {
                        console.log(item.product.isPacket);
                        return (
                          <div key={item.id} className="last-order-product">
                            <div className="last-order-product-title">
                              {item.product.isPacket
                                ? `${t('packet')} ${item.quantity} x ${item.productName}`
                                : `${item.productName} x ${item.quantity}`}
                            </div>
                            <div className="last-order-product-price">
                              {item.totalAsString}
                              <span className="original-price">
                                {item.totalBeforeDiscountAsString}
                              </span>
                            </div>
                          </div>
                        );
                      })}

                      <button
                        type="button"
                        className="repeat-order"
                        onClick={() => repeatLastOrder()}
                      >
                        <img
                          width="22"
                          height="22"
                          src={repeatOrderButtonIcon}
                          alt="Repeat your last order"
                        />
                        <span>{t('repeatOrder')}</span>
                      </button>
                    </div>
                  </div>
                )}

                {products?.length > 0 &&
                  products?.map((product) => {
                    return (
                      <HomeProductCard item={product} key={product.id} openModal={openCartModal} />
                    );
                  })}
              </Slider>
            </div>
          )}
        </div>
      </Container>
      <AddToCartModal
        showModal={addToCartModal}
        modalClose={closeCartModal}
        item={selectedProduct}
      />
      <NextDaysScheduleModal />
    </div>
  );
};

export default MainPage;
