import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ isLoggedIn, redirectTo }) => {
  return isLoggedIn ? <Outlet /> : <Navigate to={redirectTo} />;
};

PrivateRoute.defaultProps = {
  redirectTo: '/',
  isLoggedIn: false,
};

PrivateRoute.propTypes = {
  redirectTo: PropTypes.string,
  isLoggedIn: PropTypes.bool,
};

export default PrivateRoute;
