import 'styles/pages/ContactUsPage.scss';
import 'styles/components/InputsHelper.scss';

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Alerts from 'components/Alerts';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { getAuthState, getUserProfileState } from 'redux/src/StatesGetter';
import { fetchProfileRequest } from 'redux/actions/ProfileActions';
import { contactUsRequest } from 'redux/actions/UserActions';
import { getEnv } from 'services/EnvService';
import { calcPhone, calcPrefix } from 'types/Prefix';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import labels from 'react-phone-number-input/locale/en.json';
import dropIcon from 'assets/images/dropdown-arrow-icon.svg';
import { isValidPhoneNumber } from 'react-phone-number-input';

const ContactUsPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const user = useSelector(getUserProfileState, shallowEqual);

  const [reCaptcha, setReCaptcha] = useState(false);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [phoneNumber, setPhoneNumber] = useState(calcPhone(user?.phoneNumber));
  const [phonePrefix, setPhonePrefix] = useState(calcPrefix(user.phoneNumber) ?? '+40');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [privacyPolicyConsent, setPrivacyPolicyConsent] = useState(false);

  const sendAction = (e) => {
    e.preventDefault();
    if (!email || !lastName || !firstName || !phoneNumber || !subject || !message) {
      Alerts.warningAlert(t('allFieldsMandatory'));
      return;
    }

    if (!privacyPolicyConsent) {
      Alerts.warningAlert(`${t('iAgreeWithAll')}`);
      return;
    }

    if (!isValidPhoneNumber(`${phonePrefix}${phoneNumber}`)) {
      Alerts.warningAlert(t('alerts.invalidPhoneNumber'));
      return;
    }

    if (!reCaptcha) {
      Alerts.warningAlert(`${t('recaptchaNotValid')}`);
      return;
    }

    ReactRecaptcha3.getToken().then(
      (token) => {
        dispatch(
          contactUsRequest({
            subject,
            message,
            firstName,
            lastName,
            email,
            phoneNumber: `${phonePrefix}${phoneNumber}`,
            reCaptchaToken: token,
            isWeb: true,
          }),
        );
      },
      (error) => {
        Alerts.warningAlert(`${t('recaptchaTokenNotValid')}`);
      },
    );

    if (!isLoggedIn) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
    }
    setSubject('');
    setMessage('');
    setPrivacyPolicyConsent(false);
  };

  useEffect(() => {
    dispatch(fetchProfileRequest({}));
    ReactRecaptcha3.init(getEnv('RECAPTCHA_KEY')).then((status) => {
      if (status === 'success') setReCaptcha(true);
    });
  }, []);

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setPhoneNumber(calcPhone(user.phoneNumber));
    setPhonePrefix(calcPrefix(user.phoneNumber));
  }, [user]);

  return (
    <main className="container" id="contact-form-main-wrapper">
      <section className="title contact-us-page">
        <form onSubmit={sendAction}>
          <div className="title-section">
            <h1>{t('contact')}</h1>
          </div>

          <div className="form-input">
            <input
              type="text"
              name="name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              className="form-control"
              placeholder=" "
            />
            <label htmlFor="name">{t('firstName')}</label>
          </div>

          <div className="form-input">
            <input
              type="text"
              name="lastName"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              className="form-control"
              placeholder=" "
            />
            <label htmlFor="lastName">{t('lastName')}</label>
          </div>

          <div className="form-input">
            <input
              type="text"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="form-control"
              placeholder=" "
            />
            <label htmlFor="email">{t('email')}</label>
          </div>

          <div className="telephone-wrapper">
            <div className="form-input prefix-wrapper">
              <select
                autoComplete="off"
                id="prefix"
                name="prefix"
                className="form-control"
                placeholder=" "
                onChange={(event) => setPhonePrefix(event.target.value)}
                defaultValue={calcPrefix(user?.phoneNumber) ?? '+40'}
              >
                {getCountries().map((country) => (
                  <option key={country} value={`+${getCountryCallingCode(country)}`}>
                    {labels[country]} +{getCountryCallingCode(country)}
                  </option>
                ))}
              </select>
              <label htmlFor="prefix">{t('choosePrefix')}</label>
              <div className="countryPrefix">
                <span>{phonePrefix}</span>
                <img src={dropIcon} alt="dropIcon" />
              </div>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="telephone"
                name="telephone"
                type="text"
                className="form-control"
                placeholder=" "
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
              <label htmlFor="telephone">{t('phoneNumber')}</label>
            </div>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="subject"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              className="form-control"
              placeholder=" "
            />
            <label htmlFor="subject">{t('subject')}</label>
          </div>
          <div className="form-input">
            <textarea
              className="form-control"
              placeholder=" "
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              rows={5}
            />
            <label htmlFor="subject">{t('yourMessage')}</label>
          </div>
          <div className="custom-control custom-checkbox green-checkbox">
            <input
              className="custom-control-input"
              id="privacy_policy_accept"
              type="checkbox"
              checked={privacyPolicyConsent}
              onChange={() => setPrivacyPolicyConsent(!privacyPolicyConsent)}
            />
            <label htmlFor="privacy_policy_accept" className="custom-control-label">
              {t('iUnderstandAnd')}&nbsp;
              <a target="_blank" href="/privacy-policy" rel="noreferrer">
                {t('support.policy')}
              </a>
            </label>
          </div>
          <button type="submit" className="green-button mt-4">
            {t('send')}
          </button>
        </form>
      </section>
    </main>
  );
};

export default ContactUsPage;
