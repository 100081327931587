import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/ReviewModal.scss';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Alerts from 'components/Alerts';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import starIcon from 'assets/images/star-icon.svg';
import emptyStarIcon from 'assets/images/empty-star-icon.svg';
import { addReviewRequest, setReviewModal } from 'redux/actions/OrderActions';
import { getAuthState, getReviewModalState, getUserProfileState } from 'redux/src/StatesGetter';
import StorageService from '../../services/StorageService';
import { default as StringConstants } from '../../constants/StringConstants.json';

const ReviewModal = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const textRef = useRef<any>();

  const reviewModal = useSelector(getReviewModalState, shallowEqual);
  const profile = useSelector(getUserProfileState, shallowEqual);
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);

  const { data } = reviewModal;

  const { driver, numberOfStars, orderId, comment, skip } = data ?? {};

  const [localRating, setLocalRating] = useState(numberOfStars);
  const [hover, setHover] = useState(numberOfStars);

  const closeReviewModal = async () => {
    dispatch(setReviewModal({ open: false, data: {} }));
    if (skip) {
      await StorageService.removeItem(StringConstants.REVIEW_LATER);
    }
  };

  const submitReview = async () => {
    if (localRating === 0) {
      Alerts.warningAlert(t('alerts.noRatingReview'));
    } else {
      dispatch(
        addReviewRequest({
          orderId,
          numberOfStars: localRating,
          comment: `${textRef.current.value}`,
        }),
      );

      await closeReviewModal();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setLocalRating(numberOfStars);
      setHover(numberOfStars);
    }
  }, [reviewModal.data]);

  const onChangeHandler = (e: SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    textRef.current.style.height = '30px';
    textRef.current.style.height = `${target.scrollHeight}px`;
  };

  const reviewLaterClick = async () => {
    if (!skip) {
      const d = new Date();
      await StorageService.setData(
        StringConstants.REVIEW_LATER,
        JSON.stringify({
          data: {
            driver,
            orderId,
            numberOfStars: localRating,
            comment: `${textRef.current.value}`,
            user: `/api/ro/v2/shop/customers/${profile.id}`,
            timestamp: d.getTime(),
            skip: true,
          },
        }),
      );
    } else {
      await StorageService.removeItem(StringConstants.REVIEW_LATER);
    }
    await closeReviewModal();
  };

  const selectRating = (index) => {
    setLocalRating(index);
  };

  return (
    <Modal
      show={reviewModal.open}
      onHide={closeReviewModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="reviewDriverModal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">{t('review.evaluateDelivery')}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-driver-section">
          <img src={driver?.picture} alt="driver" width="60" height="60" />
          <span>{driver?.name}</span>
        </div>
        <div className="modal-review-section">
          <span className="modal-review-title">{t('review.howEvaluate')}</span>
          <span className="modal-review-text">{t('review.yourOpinionText')}</span>
        </div>
        <div className="modal-review-rating">
          {[1, 2, 3, 4, 5].map((index) => {
            return (
              <button
                type="button"
                key={index}
                className={index <= (hover || localRating) ? 'on' : 'off'}
                onClick={() => selectRating(index)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(localRating)}
              >
                <span className="star">
                  <img
                    className="empty-star"
                    src={emptyStarIcon}
                    alt="empty-star"
                    width="40"
                    height="40"
                  />
                  <img className="filled-star" src={starIcon} alt="star" width="40" height="40" />
                </span>
              </button>
            );
          })}
          {/*  */}
        </div>
        <div className="modal-review-comment form-input">
          <textarea
            id="review-comment"
            name="review-comment"
            placeholder=" "
            ref={textRef}
            onChange={onChangeHandler}
            defaultValue={comment}
          />
          <label htmlFor="review-comment">{t('review.writeComment')}</label>
        </div>
        <div className="modal-review-submit-wrapper">
          <div className="modal-review-submit green-button" onClick={submitReview}>
            {t('review.submitEvaluation')}
          </div>
          <div className="modal-review-later" onClick={reviewLaterClick}>
            {skip ? t('review.skipReview') : t('review.later')}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReviewModal;
