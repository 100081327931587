import 'styles/components/AccountSettingsBlock.scss';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { deleteAccountRequest } from 'redux/actions/AuthActions';
import Alerts from 'components/Alerts';
import downloadIcon from 'assets/images/download-icon.svg';
import romanianFlag from 'assets/images/romanianFlag.svg';
import englishFlag from 'assets/images/englishFlag.svg';
import { Link } from 'react-router-dom';
import { getUserProfileState } from 'redux/src/StatesGetter';
import { setDownloadDataModal, setProfileDataRequest } from 'redux/actions/ProfileActions';
import { getEnv } from '../services/EnvService';
import StorageService from '../services/StorageService';

const AccountPersonalDataBlock = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [selectedLocale, setSelectedLocale] = useState(getEnv('LOCALE'));
  const profile = useSelector(getUserProfileState, shallowEqual);

  const { id, subscribedToNewsletter, useDataInAnonymousMode } = profile;

  const deleteAccount = () => {
    Alerts.okCancelAlert(t('warning'), t('account.deleteAccountSubtitle'), () => {
      dispatch(deleteAccountRequest({}));
    });
  };

  const openPersonalDataModal = () => {
    dispatch(setDownloadDataModal({ open: true }));
  };

  const handleChangeAnonymousDataUsage = () => {
    const newValue = !useDataInAnonymousMode;

    dispatch(
      setProfileDataRequest({
        id,
        patientData: {
          useDataInAnonymousMode: newValue,
        },
      }),
    );
  };
  const handleChangeNewsletterSubscription = () => {
    const newValue = !subscribedToNewsletter;

    dispatch(
      setProfileDataRequest({
        id,
        patientData: {
          subscribedToNewsletter: newValue,
        },
      }),
    );
  };

  useEffect(() => {
    StorageService.getData('APP_LANGUAGE', getEnv('LOCALE')).then((loc) => {
      setSelectedLocale(loc);
    });
  });

  const updateLocale = (e) => {
    const localeSelected = e.target.value;
    console.log(localeSelected);
    if (localeSelected !== selectedLocale) {
      Alerts.okCancelAlert(
        t('settings.change_language'),
        t('settings.change_language_question'),
        () => {
          StorageService.setData('APP_LANGUAGE', localeSelected).then(() => {
            setSelectedLocale(localeSelected);
            i18n.changeLanguage(localeSelected).then(() => {});
          });
        },
      );
    }
  };

  return (
    <div className="account-settings-block">
      <div className="mobile-block-title">{t('settings.settings')}</div>
      <div className="settings-section">
        <div className="settings-section-title">{t('settings.language')}</div>
        <div className="settings-section-language">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="romanianLang"
              id="romanianLang"
              value="ro"
              onChange={updateLocale}
              checked={selectedLocale === 'ro'}
            />
            <label className="form-check-label" htmlFor="invoice-individual">
              {t('settings.languageRomanian')}
            </label>
            <img src={romanianFlag} alt="Romanian" />
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="englishLang"
              id="englishLang"
              value="en"
              onChange={updateLocale}
              checked={selectedLocale === 'en'}
            />
            <label className="form-check-label" htmlFor="invoice-individual">
              {t('settings.languageEnglish')}
            </label>
            <img src={englishFlag} alt="English" />
          </div>
        </div>
      </div>
      <div className="settings-section">
        <div className="settings-section-title">{t('settings.usageDataSharing')}</div>
        <div className="settings-section-radio">
          <div className="switch-label">
            <span>{t('settings.usageDataAnonymous')}</span>
          </div>
          <div
            className={`switch-wrapper${useDataInAnonymousMode ? ' active' : ''}`}
            onClick={handleChangeAnonymousDataUsage}
          >
            <div className="input-wrapper" />
            <div className="bullet" />
          </div>
        </div>
      </div>
      <div className="settings-section">
        <div className="settings-section-title">{t('settings.policiesTitle')}</div>
        <div className="settings-section-radio disabled">
          <div className="switch-label">
            <span>{t('iAgreeWith')}</span>
            <Link to="/terms-and-conditions">{t('aquaTermsAndConditions')}</Link>
          </div>
          <div className="switch-wrapper active">
            <div className="input-wrapper" />
            <div className="bullet" />
          </div>
        </div>
        <div className="settings-section-radio disabled">
          <div className="switch-label">
            <span>{t('iAgreeWith')}</span>
            <Link to="/privacy-policy">{t('privacyTitle')}</Link>
          </div>
          <div className="switch-wrapper active">
            <div className="input-wrapper" />
            <div className="bullet" />
          </div>
        </div>
        <div className="settings-section-radio">
          <div className="switch-label">
            <span>{t('settings.marketingCommunication')}</span>
          </div>
          <div
            className={`switch-wrapper${subscribedToNewsletter ? ' active' : ''}`}
            onClick={handleChangeNewsletterSubscription}
          >
            <div className="input-wrapper" />
            <div className="bullet" />
          </div>
        </div>
      </div>
      <div className="settings-section">
        <div className="settings-section-title">{t('settings.myData')}</div>
        <div className="download-data-btn settings-btn" onClick={openPersonalDataModal}>
          <img src={downloadIcon} alt="download" width="15" height="21" />
          {t('settings.downloadPersonalData')}
        </div>
      </div>
      <div className="settings-section">
        <div className="settings-section-title">{t('settings.deleteAccount')}</div>
        <div className="request-delete-account-btn settings-btn" onClick={deleteAccount}>
          {t('settings.deleteAccountBtn')}
        </div>
      </div>
    </div>
  );
};

export default AccountPersonalDataBlock;
