import { actionChannel, ActionPattern, call, fork, put, select, take } from 'redux-saga/effects';
import {
  ACTIVE_ORDERS_FETCH_REQUEST,
  ADD_ORDER_ITEM_REQUEST,
  ADD_REVIEW_REQUEST,
  CHANGE_ORDER_ITEM_QUANTITY_REQUEST,
  COMPLETE_ORDER_ON_CHECKOUT_REQUEST,
  CREATE_CART_FROM_ORDER_REQUEST,
  CREATE_CART_REQUEST,
  DELETE_CART_REQUEST,
  FETCH_CART_REQUEST,
  FETCH_LAST_ORDER_REQUEST,
  FETCH_ORDER_DETAILS_REQUEST,
  FETCH_ORDER_PAYMENT_METHODS_REQUEST,
  FETCH_ORDER_SHIPPING_METHODS_REQUEST,
  INACTIVE_ORDERS_FETCH_REQUEST,
  REMOVE_ORDER_ITEM_REQUEST,
  SET_ORDER_ADDRESS_REQUEST,
  SET_ORDER_PAYMENT_METHOD_REQUEST,
  TRIGGER_REVIEW_REQUEST,
} from 'redux/types/OrderTypes';
import { Action } from 'redux';
import {
  addOrderItemAPI,
  addReviewAPI,
  changeOrderItemQuantityAPI,
  completeOrderOnCheckoutAPI,
  createCartAPI,
  createCartFromOrderAPI,
  deleteCartAPI,
  fetchOrderPaymentMethodsAPI,
  fetchOrderShippingMethodsAPI,
  getOrderDetailsAPI,
  getOrdersAPI,
  removeOrderItemAPI,
  setOrderAddressAPI,
  setOrderPaymentMethodAPI,
} from 'redux/api/OrderApi';
import {
  addOrderItemError,
  addOrderItemSuccess,
  changeOrderItemQuantityError,
  changeOrderItemQuantitySuccess,
  completeOrderOnCheckoutError,
  completeOrderOnCheckoutSuccess,
  createCartError,
  createCartSuccess,
  fetchCartError,
  fetchCartRequest,
  fetchCartSuccess,
  fetchOrderShippingMethodsError,
  fetchOrderShippingMethodsSuccess,
  fetchOrderDetailsError,
  fetchOrderDetailsSuccess,
  removeOrderItemError,
  removeOrderItemSuccess,
  setOrderAddressError,
  setOrderAddressSuccess,
  fetchOrderPaymentMethodsSuccess,
  fetchOrderPaymentMethodsError,
  setOrderPaymentMethodSuccess,
  setOrderPaymentMethodError,
  deleteCartSuccess,
  deleteCartError,
  setOrderAddressRequest,
  createCartFromOrderSuccess,
  createCartFromOrderError,
  setOrderShippingMethod,
  fetchOrderShippingMethodsRequest,
  activeOrdersFetchSuccess,
  activeOrdersFetchError,
  inactiveOrdersFetchSuccess,
  inactiveOrdersFetchError,
  lastOrderFetchSuccess,
  lastOrderFetchError,
  fetchOrderPaymentMethodsRequest,
  setOrderPaymentMethodRequest,
  addReviewSuccess,
  addReviewError,
  triggerReviewSuccess,
  triggerReviewError,
  setReviewModal,
} from 'redux/actions/OrderActions';
import {
  orderDetailsSelector,
  ordersSelector,
  shippingMethodSelector,
} from 'redux/selector/OrderSelector';
import { getCartState, getDeviceState, getSelectedAddressState } from 'redux/src/StatesGetter';
import NavigationService from 'services/NavigationService';
import { getOrderItem } from 'redux/actions/OrderItemActions';
import { setSelectedCompany } from 'redux/actions/CompanyActions';
import Alerts from 'components/Alerts';
import i18next from 'i18next';
import ErrorType from 'types/Error';
import { registerError } from '../actions/UserActions';

function* getActiveOrders(actionType: ActionPattern<Action<any>>) {
  const getOrdersChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getOrdersChannel);
    try {
      const safeAction = {
        itemsPerPage: 30,
        page: 1,
        state: '',
        ...payload,
      };
      const { data: orders } = yield call(getOrdersAPI, safeAction);
      yield put(
        activeOrdersFetchSuccess({
          data: ordersSelector(orders),
          page: payload.page,
          perPage: payload.itemsPerPage,
        }),
      );
    } catch ({ message }) {
      yield put(activeOrdersFetchError({ message }));
    }
  }
}

function* getInactiveOrders(actionType: ActionPattern<Action<any>>) {
  const getOrdersChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getOrdersChannel);
    try {
      const safeAction = {
        itemsPerPage: 30,
        page: 1,
        state: '',
        ...payload,
      };
      const { data: orders } = yield call(getOrdersAPI, safeAction);
      yield put(
        inactiveOrdersFetchSuccess({
          data: ordersSelector(orders),
          page: payload.page,
          perPage: payload.itemsPerPage,
        }),
      );
    } catch ({ message }) {
      yield put(inactiveOrdersFetchError({ message }));
    }
  }
}

function* getLastOrder(actionType: ActionPattern<Action<any>>) {
  const getLastOrderChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getLastOrderChannel);
    try {
      const safeAction = {
        itemsPerPage: 30,
        page: 1,
        state: '',
        ...payload,
      };
      const { data: orders } = yield call(getOrdersAPI, safeAction);
      yield put(lastOrderFetchSuccess(ordersSelector(orders)));
    } catch ({ message }) {
      yield put(lastOrderFetchError({ message }));
    }
  }
}

function* fetchOrderDetails(actionType: ActionPattern<Action<any>>) {
  const orderDetailsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload: tokenValue } = yield take(orderDetailsChannel);
    try {
      const { data: order } = yield call(getOrderDetailsAPI, tokenValue);
      yield put(fetchOrderDetailsSuccess(orderDetailsSelector(order)));
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      }
      yield put(fetchOrderDetailsError(error?.message));
    }
  }
}

function* fetchCart(actionType: ActionPattern<Action<any>>) {
  const fetchCartChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(fetchCartChannel);
    try {
      // eslint-disable-next-line prefer-const
      let { id, tokenValue } = yield select(getCartState);
      if (!id) {
        const { regId } = yield select(getDeviceState);
        const { data: cart } = yield call(createCartAPI, {
          deviceRegId: regId,
          platform: `Web`,
        });
        yield put(createCartSuccess(orderDetailsSelector(cart)));
        tokenValue = cart.tokenValue;
      }
      const response = yield call(getOrderDetailsAPI, tokenValue);
      yield put(fetchCartSuccess(orderDetailsSelector(response.data)));
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      }
      yield put(fetchCartError(error.message));
    }
  }
}

function* createCart(actionType: ActionPattern<Action<any>>) {
  const createCartChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(createCartChannel);
    try {
      const { regId } = yield select(getDeviceState);
      const { data: cart } = yield call(createCartAPI, {
        deviceRegId: regId,
        platform: `Web`,
      });
      yield put(createCartSuccess(orderDetailsSelector(cart)));
    } catch ({ message }) {
      yield put(createCartError({ message }));
    }
  }
}

function* createCartFromOrder(actionType: ActionPattern<Action<any>>) {
  const createCartFromOrderChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(createCartFromOrderChannel);
    try {
      const { data: cart } = yield call(createCartFromOrderAPI, payload);
      yield put(createCartFromOrderSuccess(orderDetailsSelector(cart)));
    } catch ({ message }) {
      yield put(createCartFromOrderError({ message }));
    }
  }
}

function* deleteCart(actionType: ActionPattern<Action<any>>) {
  const deleteCartChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deleteCartChannel);
    try {
      const { tokenValue } = yield select(getCartState);
      yield call(deleteCartAPI, tokenValue);
      yield put(deleteCartSuccess({}));
      yield put(setSelectedCompany({}));
    } catch ({ message }) {
      yield put(deleteCartError({ message }));
    }
  }
}

function* addOrderItem(actionType: ActionPattern<Action<any>>) {
  const addOrderItemChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addOrderItemChannel);
    try {
      // eslint-disable-next-line prefer-const
      let { id, tokenValue, shippingAddress } = yield select(getCartState);
      let updatedCart;
      if (!id) {
        const address = yield select(getSelectedAddressState);
        const { regId } = yield select(getDeviceState);
        const { data: cart } = yield call(createCartAPI, {
          deviceRegId: regId,
          platform: `Web`,
        });
        tokenValue = cart.tokenValue;
        yield put(createCartSuccess(orderDetailsSelector(cart)));
        updatedCart = yield call(addOrderItemAPI, {
          tokenValue,
          data: payload,
        });
        yield put(
          setOrderAddressRequest({
            tokenValue,
            toUpdate: {
              email: '',
              shippingAddress: { ...address },
              billingAddress: { ...address },
            },
          }),
        );
      } else {
        updatedCart = yield call(addOrderItemAPI, {
          tokenValue,
          data: payload,
        });

        if (!shippingAddress) {
          const address = yield select(getSelectedAddressState);
          yield put(
            setOrderAddressRequest({
              tokenValue,
              toUpdate: {
                email: '',
                shippingAddress: { ...address },
                billingAddress: { ...address },
              },
            }),
          );
        }
      }
      // Add to cart response is missing some properties, so we fetch the cart again
      yield put(addOrderItemSuccess({}));
      if (updatedCart.data.shippingAddress) {
        yield put(fetchOrderShippingMethodsRequest({}));
      }
      yield put(fetchCartRequest({}));
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      }
      yield put(addOrderItemError(error.message));
    }
  }
}

function* changeOrderItemQuantity(actionType: ActionPattern<Action<any>>) {
  const changeOrderItemQuantityChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(changeOrderItemQuantityChannel);
    try {
      const { tokenValue } = yield select(getCartState);
      const { data: cart } = yield call(changeOrderItemQuantityAPI, {
        tokenValue,
        data: payload,
      });
      yield put(changeOrderItemQuantitySuccess(orderDetailsSelector(cart)));
      const { items } = yield select(getCartState);
      yield put(getOrderItem(items.find((item) => item.id === +payload.orderItemId)));
      if (cart.shippingAddress) {
        yield put(fetchOrderShippingMethodsRequest({}));
      }
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      }
      yield put(changeOrderItemQuantityError(error.message));
    }
  }
}

function* removeOrderItem(actionType: ActionPattern<Action<any>>) {
  const removeOrderItemChannel = yield actionChannel(actionType);
  while (true) {
    const { payload: itemId } = yield take(removeOrderItemChannel);
    try {
      const { tokenValue, shippingAddress } = yield select(getCartState);
      yield call(removeOrderItemAPI, {
        tokenValue,
        itemId,
      });
      yield put(removeOrderItemSuccess({ itemId }));
      if (shippingAddress) {
        yield put(fetchOrderShippingMethodsRequest({}));
      }
      yield put(fetchCartRequest({}));
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      }
      yield put(removeOrderItemError(error.message));
    }
  }
}

function* setOrderAddress(actionType: ActionPattern<Action<any>>) {
  const setOrderAddressChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(setOrderAddressChannel);
    try {
      const { tokenValue } = yield select(getCartState);
      const { data: cart } = yield call(setOrderAddressAPI, {
        tokenValue,
        data: payload.toUpdate,
      });

      // There are missing properties, so we fetch the cart again
      yield put(setOrderAddressSuccess({}));
      yield put(fetchOrderShippingMethodsRequest({}));
      yield put(fetchOrderPaymentMethodsRequest({}));
      yield put(fetchCartRequest({}));
    } catch (error: ErrorType | any) {
      if (error?.status === 500) {
        Alerts.errorAlert(`${i18next.t('alerts.addAddressToCartError')}`);
      } else if (error?.status === 400) {
        Alerts.infoAlert(i18next.t('product.selectedAddressNoDelivery'));
      } else if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      }
      yield put(setOrderAddressError({}));
    }
  }
}

function* setOrderPaymentMethod(actionType: ActionPattern<Action<any>>) {
  const setOrderPaymentMethodChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(setOrderPaymentMethodChannel);
    try {
      const { tokenValue, payments } = yield select(getCartState);
      const { data: cart } = yield call(setOrderPaymentMethodAPI, {
        tokenValue,
        paymentId: payments[0]?.id,
        data: payload,
      });
      // There are missing properties, so we fetch the cart again
      yield put(setOrderPaymentMethodSuccess({}));
      yield put(fetchCartRequest({}));
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      }
      yield put(setOrderPaymentMethodError(error.message));
    }
  }
}

function* completeOrderOnCheckout(actionType: ActionPattern<Action<any>>) {
  const completeOrderOnCheckoutChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(completeOrderOnCheckoutChannel);
    try {
      const { tokenValue } = yield select(getCartState);
      yield call(completeOrderOnCheckoutAPI, {
        tokenValue,
        data: payload,
      });
      const { data: order } = yield call(getOrderDetailsAPI, tokenValue);
      yield put(fetchOrderDetailsSuccess(orderDetailsSelector(order)));
      yield put(setSelectedCompany({}));
      yield put(completeOrderOnCheckoutSuccess({}));
      yield put(setOrderShippingMethod({}));
      NavigationService.navigate('/order/success');
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      } else if (error?.status === 400) {
        Alerts.errorAlert(error?.message.replace('(400)', ''));
      }
      yield put(completeOrderOnCheckoutError(error?.message));
    }
  }
}

function* fetchOrderShippingMethods(actionType: ActionPattern<Action<any>>) {
  const fetchOrderShippingMethodsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(fetchOrderShippingMethodsChannel);
    try {
      const { tokenValue } = yield select(getCartState);
      const { data: shippingMethods } = yield call(fetchOrderShippingMethodsAPI, tokenValue);
      const shippingMethodsArray = shippingMethodSelector(shippingMethods);
      if (shippingMethodsArray?.options?.length === 0) {
        Alerts.infoAlert(`${i18next.t('noAvailableIntervals')}`);
      }
      yield put(fetchOrderShippingMethodsSuccess(shippingMethodsArray));
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      }
      yield put(fetchOrderShippingMethodsError(error?.message));
    }
  }
}

function* fetchOrderPaymentMethods(actionType: ActionPattern<Action<any>>) {
  const fetchOrderPaymentMethodsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(fetchOrderPaymentMethodsChannel);
    try {
      const { tokenValue, payments, checkoutState } = yield select(getCartState);
      if (payments.length > 0) {
        const { data: paymentMethods } = yield call(fetchOrderPaymentMethodsAPI, {
          tokenValue,
          paymentId: payments[0]?.id,
        });
        yield put(fetchOrderPaymentMethodsSuccess(paymentMethods));
      } else {
        yield put(fetchOrderPaymentMethodsSuccess(payments));
      }
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        Alerts.errorAlert(`${i18next.t('alerts.cartNotValid')}`);
        yield put(deleteCartSuccess({}));
      }
      yield put(fetchOrderPaymentMethodsError(error?.message));
    }
  }
}

function* triggerReviewModal(actionType: ActionPattern<Action<any>>) {
  const triggerReviewModalChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(triggerReviewModalChannel);
    try {
      const { token } = payload;
      const { data: order } = yield call(getOrderDetailsAPI, token);
      yield put(
        setReviewModal({
          open: true,
          data: {
            driver: {
              picture: order.vehicle.currentDriver.fullProfilePictureUrl,
              name: `${order.vehicle.currentDriver.firstName} ${order.vehicle.currentDriver.lastName}`,
            },
            orderId: order.id,
            numberOfStars: 0,
            comment: '',
          },
        }),
      );
      yield put(triggerReviewSuccess({}));
    } catch ({ message }) {
      yield put(triggerReviewError({ message }));
    }
  }
}

function* addReview(actionType: ActionPattern<Action<any>>) {
  const addReviewChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addReviewChannel);
    try {
      const response = yield call(addReviewAPI, payload);
      Alerts.successAlert(`${i18next.t('alerts.reviewAdded')}`);
      yield put(addReviewSuccess({}));
    } catch ({ message }) {
      yield put(addReviewError({ message }));
    }
  }
}

function* orderSaga() {
  yield fork(getActiveOrders, ACTIVE_ORDERS_FETCH_REQUEST);
  yield fork(getInactiveOrders, INACTIVE_ORDERS_FETCH_REQUEST);
  yield fork(getLastOrder, FETCH_LAST_ORDER_REQUEST);
  yield fork(fetchOrderDetails, FETCH_ORDER_DETAILS_REQUEST);
  yield fork(fetchCart, FETCH_CART_REQUEST);
  yield fork(createCart, CREATE_CART_REQUEST);
  yield fork(createCartFromOrder, CREATE_CART_FROM_ORDER_REQUEST);
  yield fork(deleteCart, DELETE_CART_REQUEST);
  yield fork(addOrderItem, ADD_ORDER_ITEM_REQUEST);
  yield fork(changeOrderItemQuantity, CHANGE_ORDER_ITEM_QUANTITY_REQUEST);
  yield fork(removeOrderItem, REMOVE_ORDER_ITEM_REQUEST);
  yield fork(setOrderAddress, SET_ORDER_ADDRESS_REQUEST);
  yield fork(setOrderPaymentMethod, SET_ORDER_PAYMENT_METHOD_REQUEST);
  yield fork(completeOrderOnCheckout, COMPLETE_ORDER_ON_CHECKOUT_REQUEST);
  yield fork(fetchOrderShippingMethods, FETCH_ORDER_SHIPPING_METHODS_REQUEST);
  yield fork(fetchOrderPaymentMethods, FETCH_ORDER_PAYMENT_METHODS_REQUEST);
  yield fork(addReview, ADD_REVIEW_REQUEST);
  yield fork(triggerReviewModal, TRIGGER_REVIEW_REQUEST);
}

export default orderSaga;
