import { AnyAction } from 'redux';
import {
  ASSOCIATE_DEVICE_ERROR,
  ASSOCIATE_DEVICE_REQUEST,
  ASSOCIATE_DEVICE_SUCCESS,
  GET_DEVICE_REG_ID_ERROR,
  GET_DEVICE_REG_ID_REQUEST,
  GET_DEVICE_REG_ID_SUCCESS,
  UPDATE_DEVICE_REG_ID_ERROR,
  UPDATE_DEVICE_REG_ID_REQUEST,
  UPDATE_DEVICE_REG_ID_SUCCESS,
} from 'redux/types/DeviceTypes';

export const updateDeviceRegIdRequest = (payload: any): AnyAction => ({
  type: UPDATE_DEVICE_REG_ID_REQUEST,
  payload,
});

export const updateDeviceRegIdSuccess = (payload: any): AnyAction => ({
  type: UPDATE_DEVICE_REG_ID_SUCCESS,
  payload,
});

export const updateDeviceRegIdError = (payload: any): AnyAction => ({
  type: UPDATE_DEVICE_REG_ID_ERROR,
  payload,
});

export const getDeviceRegIdRequest = (payload: any): AnyAction => ({
  type: GET_DEVICE_REG_ID_REQUEST,
  payload,
});

export const getDeviceRegIdSuccess = (payload: any): AnyAction => ({
  type: GET_DEVICE_REG_ID_SUCCESS,
  payload,
});

export const getDeviceRegIdError = (payload: any): AnyAction => ({
  type: GET_DEVICE_REG_ID_ERROR,
  payload,
});

export const associateDeviceRequest = (payload: any): AnyAction => ({
  type: ASSOCIATE_DEVICE_REQUEST,
  payload,
});

export const associateDeviceSuccess = (payload: any): AnyAction => ({
  type: ASSOCIATE_DEVICE_SUCCESS,
  payload,
});

export const associateDeviceError = (payload: any): AnyAction => ({
  type: ASSOCIATE_DEVICE_ERROR,
  payload,
});
