/**
 * @param items
 * @param uniqueKey
 * @returns {*}
 */
export const antiDuplication = (items: any[], uniqueKey: string) => {
  const uniq = {};
  if (!Array.isArray(items)) {
    return [];
  }
  return items.filter((item) => {
    // eslint-disable-next-line no-return-assign
    return !uniq[item[uniqueKey]] && (uniq[item[uniqueKey]] = true);
  });
};

export const replaceArrayRowProps = (arr, key, value, newProps) => {
  return arr?.map((row) => (row[key] !== value ? row : { ...row, ...newProps }));
};

/**
 * @param items
 * @param uniqueKey
 * @param commonKey
 * @param commonKeyValue
 */
export const antiDuplicationAndStranger = (
  items: any[],
  uniqueKey: string | number,
  commonKey: string | number,
  commonKeyValue: any,
) => {
  // const uniq = {};
  if (!Array.isArray(items)) {
    return [];
  }
  return items.filter((item) => {
    // eslint-disable-next-line no-return-assign
    // @ts-ignore
    // return (
    //   item[commonKey] === commonKeyValue && !uniq[item[uniqueKey]] && (uniq[item[uniqueKey]] = true)
    // );
    return item;
  });
};
