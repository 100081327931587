import StorageService from '../services/StorageService';
import { default as StringConstants } from '../constants/StringConstants.json';
import { setReviewModal } from '../redux/actions/OrderActions';

const resolveBottlesOnCart = (cart) => {
  return cart.items
    .filter((item) => {
      return item.product.code === '11L' || item.product.code === '19L';
    })
    .reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0);
};

const resolveAccessoriesOnCart = (cart) => {
  return cart.items.filter((item) => {
    return item.product.code !== '11L' && item.product.code !== '19L';
  }).length;
};

const handleReviewLater = async (customer) => {
  const reviewLaterData = await StorageService.getData(StringConstants.REVIEW_LATER, false);
  if (reviewLaterData) {
    const reviewLater = JSON.parse(reviewLaterData);
    if (reviewLater?.data?.user === customer) {
      return { open: true, data: reviewLater.data };
    }
  }
  return { open: false, data: {} };
};

export { resolveBottlesOnCart, resolveAccessoriesOnCart, handleReviewLater };
