import 'styles/components/AccountPersonalDataBlock.scss';
import React, { useState, useEffect } from 'react';
import { getAuthState } from 'redux/src/StatesGetter';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setProfileDataRequest } from 'redux/actions/ProfileActions';
import Alerts from 'components/Alerts';
import { calcPhone, calcPrefix } from 'types/Prefix';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import labels from 'react-phone-number-input/locale/en.json';
import { isValidPhoneNumber } from 'react-phone-number-input';
import dropIcon from 'assets/images/dropdown-arrow-icon.svg';

const AccountPersonalDataBlock = ({ profile }) => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const dispatch = useDispatch();

  const [profileFirstName, setProfileFirstName] = useState(profile.firstName);
  const [profileLastName, setProfileLastName] = useState(profile.lastName);
  const [profilePhone, setProfilePhone] = useState(calcPhone(profile.phoneNumber));
  const [profilePrefix, setProfilePrefix] = useState(calcPrefix(profile.phoneNumber));

  const handleProfileFirstNameChange = (e) => setProfileFirstName(e.target.value.trim());
  const handleProfileLastNameChange = (e) => setProfileLastName(e.target.value.trim());
  const handlePhoneChange = (e) => setProfilePhone(e.target.value.trim());

  const handlePrefixChange = (e) => setProfilePrefix(e.target.value.trim());

  const updatePatientProfile = () => {
    if (!profileFirstName || !profileLastName) {
      Alerts.warningAlert(t('allFieldsMandatory'));
      return;
    }

    if (!isValidPhoneNumber(`${profilePrefix}${profilePhone}`)) {
      Alerts.warningAlert(t('alerts.invalidPhoneNumber'));
      return;
    }

    const patientData = {
      firstName: profileFirstName,
      lastName: profileLastName,
      phoneNumber: `${profilePrefix}${profilePhone}`,
    };

    Alerts.okCancelAlert(t('warning'), t('account.saveProfile'), () => {
      dispatch(
        setProfileDataRequest({
          id: profile.id,
          patientData,
        }),
      );
    });
  };

  useEffect(() => {
    setProfileFirstName(profile.firstName);
    setProfileLastName(profile.lastName);
    setProfilePhone(calcPhone(profile.phoneNumber));
    setProfilePrefix(calcPrefix(profile.phoneNumber));
  }, [profile]);

  return (
    <div className="account-personal-data">
      <div className="mobile-block-title">{t('settings.personalData')}</div>
      <div className="personal-data">
        <div className="form-input">
          <input
            disabled
            autoComplete="off"
            id="email"
            name="email"
            type="email"
            defaultValue={profile.email}
            className="form-control"
            placeholder=" "
          />
          <label htmlFor="email">{t('email')}</label>
        </div>

        <div className="form-input">
          <input
            autoComplete="off"
            id="firstName"
            name="firstName"
            type="text"
            className="form-control"
            defaultValue={profileFirstName}
            placeholder=" "
            onChange={handleProfileFirstNameChange}
          />
          <label htmlFor="firstName">{t('firstName')}</label>
        </div>

        <div className="form-input">
          <input
            autoComplete="off"
            id="lastName"
            name="lastName"
            type="text"
            className="form-control"
            defaultValue={profileLastName}
            placeholder=" "
            onChange={handleProfileLastNameChange}
          />
          <label htmlFor="lastName">{t('lastName')}</label>
        </div>

        <div className="telephone-wrapper">
          <div className="form-input prefix-wrapper">
            <select
              autoComplete="off"
              id="prefix"
              name="prefix"
              className="form-control"
              placeholder=" "
              onChange={handlePrefixChange}
              defaultValue={calcPrefix(profile?.phoneNumber) ?? '+40'}
            >
              {getCountries().map((country) => (
                <option key={country} value={`+${getCountryCallingCode(country)}`}>
                  {labels[country]} +{getCountryCallingCode(country)}
                </option>
              ))}
            </select>
            <label htmlFor="prefix">{t('choosePrefix')}</label>
            <div className="countryPrefix">
              <span>{profilePrefix}</span>
              <img src={dropIcon} alt="dropIcon" />
            </div>
          </div>
          <div className="form-input">
            <input
              autoComplete="off"
              id="telephone"
              name="telephone"
              type="text"
              className="form-control"
              placeholder=" "
              defaultValue={profilePhone}
              onChange={handlePhoneChange}
            />
            <label htmlFor="telephone">{t('phoneNumber')}</label>
          </div>
        </div>
      </div>

      <button type="button" className="green-button" onClick={updatePatientProfile}>
        {t('saveChanges')}
      </button>
    </div>
  );
};

export default AccountPersonalDataBlock;
