import 'styles/components/map/Map.scss';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { getEnv } from 'services/EnvService';
import { mapStyle, libraries } from 'constants/MapThemeConstant';
import pin from 'assets/images/google-pin.svg';
import zoomIn from 'assets/images/map-zoom-in.svg';
import zoomOut from 'assets/images/map-zoom-out.svg';
import getLocation from 'assets/images/map-get-location-icon.svg';
import { getAddressModalState, getAuthState, getLocalAddressState } from 'redux/src/StatesGetter';
import { useTranslation } from 'react-i18next';
import Geocode from 'react-geocode';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setLocalAddress } from 'redux/actions/AddressActions';
import { formatAddressData } from 'modules/AddressUtils';
import { defaultShippingAddress } from 'redux/selector/AddressSelector';

const Map = ({ setMapInitialized, disabled, setAddAddressInfoShow }) => {
  const apiKey = getEnv('GOOGLE_MAP_API') ?? '';
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  Geocode.setApiKey(apiKey);

  const [mapref, setMapRef] = useState(null);
  const [zoom, setZoom] = useState(15);

  const localAddress = useSelector(getLocalAddressState, shallowEqual);
  const addressModalState = useSelector(getAddressModalState, shallowEqual);
  const selectedAddress = useSelector(getLocalAddressState, shallowEqual);

  const center = {
    lat: parseFloat(localAddress?.latitude ?? defaultShippingAddress.latitude),
    lng: parseFloat(localAddress?.longitude ?? defaultShippingAddress.longitude),
  };

  const getNewAddress = (newLat, newLng) => {
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        dispatch(
          setLocalAddress({
            ...formatAddressData({
              ...response.results[0],
              ...localAddress,
              lat: `${response.results[0].geometry.location.lat}`,
              lng: `${response.results[0].geometry.location.lng}`,
            }),
          }),
        );
      },
      (error) => {
        console.error(error);
      },
    );
  };

  const setNewPin = (e) => {
    if (!disabled) {
      const newLat = e.latLng.lat();
      const newLng = e.latLng.lng();
      // @ts-ignore
      const newZoom = mapref.getZoom();
      getNewAddress(parseFloat(newLat), parseFloat(newLng));
    }
  };

  const handleOnLoad = (map) => {
    setMapRef(map);
    setMapInitialized(true);
  };

  const handleCenterChanged = () => {
    if (mapref && !disabled) {
      // @ts-ignore
      const newCenter = mapref.getCenter();
      // @ts-ignore
      const newZoom = mapref.getZoom();

      getNewAddress(parseFloat(newCenter.lat()), parseFloat(newCenter.lng()));
    }
  };

  const requestLocation = () => {
    const successCallback = (position) => {
      if (addressModalState.open) {
        getNewAddress(position.coords.latitude, position.coords.longitude);
      }
    };

    const errorCallback = (error) => {
      if (addressModalState.open) {
        console.log(error);
      }
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  const handleZoomChange = (change) => {
    setZoom(zoom + change);
    requestLocation();
  };

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerClassName={`addressMap ${disabled && 'collapsed'}`}
        center={center}
        zoom={zoom}
        options={{
          styles: mapStyle,
          disableDefaultUI: true,
        }}
        onLoad={handleOnLoad}
        onDragEnd={handleCenterChanged}
        onClick={setNewPin}
      >
        <div className="pin">
          <img src={pin} width="36" height="43" alt="pin" />
        </div>
        {!disabled && (
          <>
            <div className="drag-message">{t('movePinMessage')}</div>
            <div className="zoom-btn zoom-in" onClick={() => handleZoomChange(1)}>
              <img src={zoomIn} alt="zoomIn" />
            </div>
            <div className="zoom-btn zoom-out" onClick={() => handleZoomChange(-1)}>
              <img src={zoomOut} alt="zoomOut" />
            </div>
            <div className="get-gps-location" onClick={requestLocation}>
              <img src={getLocation} alt="get-gps-location" />
            </div>
          </>
        )}
        {disabled && (
          <div className="map-overlay">
            <div className="adjust-btn" onClick={() => setAddAddressInfoShow(false)}>
              {t('adjust')}
            </div>
          </div>
        )}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default Map;
