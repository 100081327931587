import ApiUtils from 'modules/ApiUtils';
import { default as ApiConstants } from 'constants/ApiConstants.json';

const todayScheduleApi = ({ day }) =>
  ApiUtils.getRequest(`${ApiConstants.SPECIFIC_DAY_SCHEDULE}/${day}`);

const weeklyScheduleApi = ({ numOfDays }) =>
  ApiUtils.getRequest(`${ApiConstants.WEEKLY_SCHEDULE}/${numOfDays}`);

export { todayScheduleApi, weeklyScheduleApi };
