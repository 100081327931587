import 'styles/components/AccountCompaniesBlock.scss';
import { useTranslation } from 'react-i18next';
import editIcon from 'assets/images/edit-address-icon.svg';
import deleteIcon from 'assets/images/delete-icon.svg';
import plusIcon from 'assets/images/plus-icon.svg';
import { getCustomerCompaniesState, getSelectedCompanyState } from 'redux/src/StatesGetter';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Alerts from 'components/Alerts';
import {
  deleteCustomerCompanyRequest,
  fetchCustomerCompaniesRequest,
  setCompanyModal,
  setLocalCompany,
} from 'redux/actions/CompanyActions';

const AccountLegalInfoBlock = () => {
  const { t, i18n } = useTranslation();
  const selectedCompany = useSelector(getSelectedCompanyState, shallowEqual);
  const companies = useSelector(getCustomerCompaniesState, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomerCompaniesRequest({}));
  }, []);

  const editCompany = (clickedCompany) => {
    dispatch(setLocalCompany({ ...clickedCompany }));
    dispatch(
      setCompanyModal({ open: true, setSelected: clickedCompany.id === selectedCompany.id }),
    );
  };

  const deleteCompany = (clickedCompany) => {
    Alerts.okCancelAlert(t('warning'), t('alerts.companyDelete'), () => {
      dispatch(deleteCustomerCompanyRequest(clickedCompany));
    });
  };

  return (
    <div className="account-companies-block">
      <div className="mobile-block-title">{t('settings.customerCompanies')}</div>
      <div className="add-new-company-button" onClick={() => editCompany({})}>
        <img src={plusIcon} alt="plus-icon" />
        <span>{t('addNewCompany')}</span>
      </div>
      <div className="companies-wrapper">
        {companies?.data?.length > 0 &&
          companies?.data?.map((company) => {
            return (
              <div className="company" key={company.id}>
                <div className="company-text">
                  <span>{`${company.company}`}</span>
                  <span>{`CUI: ${company.uniqueRegistrationCode}`}</span>
                  <span>{`${company.street} ${company.city}`}</span>
                </div>
                {selectedCompany.id !== company.id && (
                  <div className="delete-company" onClick={() => deleteCompany(company)}>
                    <img src={deleteIcon} alt="delete-company" />
                  </div>
                )}
                <div className="edit-company" onClick={() => editCompany(company)}>
                  <img src={editIcon} alt="edit-company" />
                </div>
              </div>
            );
          })}
      </div>
      {/* <SelectCompanyModal /> */}
    </div>
  );
};
export default AccountLegalInfoBlock;
