import 'styles/pages/StaticPage.scss';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';

const StaticPage = ({ url }) => {
  const [staticPageData, setStaticPageData] = useState({
    description: '',
    id: 0,
    name: '',
    slug: '',
  });

  useEffect(() => {
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        setStaticPageData({ ...data });
      });
  }, []);

  return (
    <div className="container static-page">
      <div className="title-section">
        <h1>{staticPageData.name}</h1>
      </div>
      <div className="static-page-content">{parse(staticPageData.description)}</div>
    </div>
  );
};

export default StaticPage;
