import ApiUtils from 'modules/ApiUtils';
import { default as ApiConstants } from 'constants/ApiConstants.json';

const getOrdersAPI = ({ page, itemsPerPage, state }) =>
  ApiUtils.getRequest(
    `${ApiConstants.ORDERS_ENDPOINT}?page=${page}&itemsPerPage=${itemsPerPage}&state=${state}`,
  );

const getOrderDetailsAPI = (tokenValue) =>
  ApiUtils.getRequest(`${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}`);

const createCartAPI = (payload) => ApiUtils.postRequest(ApiConstants.ORDERS_ENDPOINT, payload);

const createCartFromOrderAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.CREATE_CART_FROM_ORDER_ENDPOINT, payload);

const deleteCartAPI = (tokenValue) =>
  ApiUtils.deleteRequest(`${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}`);

const addOrderItemAPI = ({ tokenValue, data }) =>
  ApiUtils.patchRequest(`${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}/items`, data, {
    'Content-Type': 'application/merge-patch+json; charset=utf-8',
  });

const changeOrderItemQuantityAPI = ({ tokenValue, data }) =>
  ApiUtils.patchRequest(`${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}/change-quantity`, data, {
    'Content-Type': 'application/merge-patch+json; charset=utf-8',
  });

const removeOrderItemAPI = ({ tokenValue, itemId }) =>
  ApiUtils.deleteRequest(`${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}/items/${itemId}`);

const setOrderAddressAPI = ({ tokenValue, data }) =>
  ApiUtils.patchRequest(`${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}/address`, data, {
    'Content-Type': 'application/merge-patch+json; charset=utf-8',
  });

const setOrderPaymentMethodAPI = ({ tokenValue, paymentId, data }) =>
  ApiUtils.patchRequest(
    `${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}/payments/${paymentId}`,
    data,
    {
      'Content-Type': 'application/merge-patch+json; charset=utf-8',
    },
  );

const completeOrderOnCheckoutAPI = ({ tokenValue, data }) =>
  ApiUtils.patchRequest(`${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}/complete`, data, {
    'Content-Type': 'application/merge-patch+json; charset=utf-8',
  });

const fetchOrderShippingMethodsAPI = (tokenValue) =>
  ApiUtils.getRequest(`${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}/delivery-options`);

const fetchOrderPaymentMethodsAPI = ({ tokenValue, paymentId }) =>
  ApiUtils.getRequest(
    `${ApiConstants.ORDERS_ENDPOINT}/${tokenValue}/payments/${paymentId}/methods`,
  );

const addReviewAPI = (payload) => ApiUtils.postRequest(ApiConstants.ADD_REVIEW_ENDPOINT, payload);

export {
  getOrdersAPI,
  getOrderDetailsAPI,
  createCartAPI,
  createCartFromOrderAPI,
  deleteCartAPI,
  addOrderItemAPI,
  changeOrderItemQuantityAPI,
  removeOrderItemAPI,
  setOrderAddressAPI,
  completeOrderOnCheckoutAPI,
  fetchOrderShippingMethodsAPI,
  fetchOrderPaymentMethodsAPI,
  setOrderPaymentMethodAPI,
  addReviewAPI,
};
