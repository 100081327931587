import _ from 'lodash';

const getCurrencyIdByCode = (_r, ref, value, key) => {
  try {
    return _r.find((r) => r[ref] === value)[key];
  } catch {
    return _r[0][key];
  }
};

const capitalizeFirstLetter = (str: string | null | undefined) => {
  if (str === null || str === undefined) {
    return '';
  }
  return str?.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

const convertAmount = (amount: string | number) => {
  if (!amount) {
    return 0;
  }
  if (typeof amount === 'string') {
    return parseInt(amount, 10)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const getObjectKeyByNestedValue = (object, subKey, value) => {
  return Object.keys(object).find((key) => object[key][subKey] === value);
};

const makeSelectOptions = (dataArray, label, value) => {
  if (!Array.isArray(dataArray) || !dataArray.length) {
    return [{ label: '', value: '' }];
  }
  return dataArray.map((row) => {
    return {
      label: row[label],
      value: row[value],
    };
  });
};

const makeSelectOptions2 = (dataArray, label, value, name2) => {
  if (!Array.isArray(dataArray) || !dataArray.length) {
    return [{ label: '', value: '', name2: '' }];
  }
  return dataArray.map((row) => {
    return {
      label: row[label],
      value: row[value],
      name2: row[name2],
    };
  });
};

const sortObjectByDateKey = (param: Record<string, string>[], key: string | number) =>
  param.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());

const sortObjectByKey = (param: Record<string, number>[], key: string | number) => {
  if (param && Array.isArray(param) && param.length) {
    return param.sort((a, b) => a[key] - b[key]);
  }
  return [];
};

const maybeLongText = (value, limit) => {
  return _.truncate(value, {
    length: limit,
    separator: ' ',
  });
};

const formatExpirationCard = (event) => {
  const inputChar = String.fromCharCode(event.keyCode);
  const code = event.keyCode;
  const allowedKeys = [8];
  if (allowedKeys.indexOf(code) !== -1) {
    return;
  }

  // eslint-disable-next-line no-param-reassign
  event.target.value = event.target.value
    .replace(
      /^([1-9]\/|[2-9])$/g,
      '0$1/', // 3 > 03/
    )
    .replace(
      /^(0[1-9]|1[0-2])$/g,
      '$1/', // 11 > 11/
    )
    .replace(
      /^([0-1])([3-9])$/g,
      '0$1/$2', // 13 > 01/3
    )
    .replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
      '$1/$2', // 141 > 01/41
    )
    .replace(
      /^([0]+)\/|[0]+$/g,
      '0', // 0/ > 0 and 00 > 0
    )
    .replace(
      /[^\d/]|^[/]*$/g,
      '', // To allow only digits and `/`
    )
    .replace(
      /\/\//g,
      '/', // Prevent entering more than 1 `/`
    );
};

const parseToInt = (param: string | number) => {
  if (!param) {
    return 0;
  }
  if (param.constructor === String) {
    return parseInt(param, 10);
  }
  return param;
};

/**
 * @param dataArray
 * @param labelKey
 * @returns {*[]}
 */
const makeSectionListFormat = (dataArray, labelKey) => {
  if (!Array.isArray(dataArray) || !dataArray.length) {
    return [];
  }
  const key = (row) => row[labelKey];
  const groupToKey = (dataGroup, groupKey) => ({
    key: groupKey,
    data: dataGroup,
  });
  return _.chain(dataArray).groupBy(key).map(groupToKey).value();
};

const convertIsoDate = (date) => {
  // return date;
  return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
};

const makeRandomIdentifier = (length: number) => {
  const result: string[] = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};

const stringFormat = (phrase: string, ...args: string[]) =>
  phrase?.split('%s%').reduce((aggregate, chunk, i) => aggregate + chunk + (args[i] || ''), '');

const arrayToObject = (arr: Record<string, string>[]) => {
  const obj = {};
  if (Array.isArray(arr) && arr.length) {
    arr.map((row) => {
      // eslint-disable-next-line prefer-destructuring
      obj[row[0]] = row[1] ?? '';
      return true;
    });
  }
  return obj;
};

const randomColor = () =>
  `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;

const roundToTwo = (num: number) => +`${Math.round(Number(`${num}e+2`))}e-2`;

const toFixedTwo = (num: number) => {
  const rounded = +`${Math.round(Number(`${num}e+2`))}e-2`;
  if (rounded) {
    return rounded.toString();
  }
  return '0.00';
};

const shortenText = (text, char) => {
  if (text === null || text === undefined) {
    return null;
  }
  return text.length < char ? text : `${text.substring(0, char - 3)}...`;
};

const zeroPad = (num) => {
  if (num >= 0 && num <= 9) return `0${num}`;
  return num.toString();
};

const addDays = (date, daysToAdd) => {
  return date.setDate(date.getDate() + daysToAdd);
};

const isNumeric = (str) => {
  if (typeof str !== 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
};

const isPhone = (phone: string) => {
  const phonePattern = /^\d{9}$/;
  return !!phone.match(phonePattern);
};

export {
  makeSectionListFormat,
  capitalizeFirstLetter,
  convertAmount,
  getObjectKeyByNestedValue,
  makeSelectOptions,
  makeSelectOptions2,
  parseToInt,
  convertIsoDate,
  maybeLongText,
  shortenText,
  getCurrencyIdByCode,
  sortObjectByKey,
  sortObjectByDateKey,
  formatExpirationCard,
  addDays,
  makeRandomIdentifier,
  stringFormat,
  arrayToObject,
  roundToTwo,
  toFixedTwo,
  randomColor,
  zeroPad,
  isNumeric,
  isPhone,
};
