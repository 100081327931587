import 'styles/components/SocialLogin.scss';
import { getEnv } from 'services/EnvService';
import googleIcon from 'assets/images/googleIcon.svg';
import facebookIcon from 'assets/images/facebookIcon.svg';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { requestLogin, socialPreRegister } from 'redux/actions/AuthActions';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { useNavigate } from 'react-router-dom';
import { parseJwt } from 'modules/AuthUtils';
import { getUserPreRegisterState } from 'redux/src/StatesGetter';
import SocialRegisterModal from './modals/SocialRegisterModal';

const SocialLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socialData = useSelector(getUserPreRegisterState, shallowEqual);

  const [loginType, setLoginType] = useState('');

  const socialRegisterModalClose = () => {
    dispatch(socialPreRegister({}));
  };

  const doSocialLogin = (socialLoginType, loginData) => {
    switch (socialLoginType) {
      case 'facebook':
        dispatch(
          socialPreRegister({
            type: 'facebook',
            data: {
              firstName: loginData.given_name,
              lastName: loginData.family_name,
              email: loginData.email,
              phoneNumber: '-',
              socialPlatform: 'google',
              socialId: loginData.sub,
              token: loginData.token,
              platform: 'web',
              newAccount: false,
              subscribedToNewsletter: false,
            },
          }),
        );
        dispatch(
          requestLogin({
            type: 'facebook',
            data: {
              firstName: loginData.first_name,
              lastName: loginData.last_name,
              email: loginData.email,
              phoneNumber: '-',
              socialPlatform: 'facebook',
              socialId: loginData.id,
              token: loginData.accessToken,
              platform: 'web',
              newAccount: false,
              subscribedToNewsletter: false,
            },
          }),
        );
        break;
      case 'google':
        dispatch(
          socialPreRegister({
            type: 'google',
            data: {
              firstName: loginData.given_name,
              lastName: loginData.family_name,
              email: loginData.email,
              phoneNumber: '-',
              socialPlatform: 'google',
              socialId: loginData.sub,
              token: loginData.token,
              platform: 'web',
              newAccount: false,
              subscribedToNewsletter: false,
            },
          }),
        );
        dispatch(
          requestLogin({
            type: 'google',
            data: {
              firstName: loginData.given_name,
              lastName: loginData.family_name,
              email: loginData.email,
              phoneNumber: '-',
              socialPlatform: 'google',
              socialId: loginData.sub,
              token: loginData.token,
              platform: 'web',
              newAccount: false,
              subscribedToNewsletter: false,
            },
          }),
        );
        break;
      case 'apple':
        break;
      default:
        break;
    }
  };

  const googleClick = (e) => {
    e.preventDefault();
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: getEnv('GG_APP_ID'),
      callback: (response) => {
        doSocialLogin('google', { token: response.credential, ...parseJwt(response.credential) });
      },
    });
    // @ts-ignore
    google.accounts.id.prompt(); // also display the One Tap dialog
  };

  const onFacebookLogin = (e) => {
    e.preventDefault();
    // @ts-ignore
    document.getElementsByClassName('facebookLoginBTN')[0]?.click();
  };

  useEffect(() => {
    if (!document.querySelector('#gsi-client')) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.id = 'gsi-client';
      script.defer = true;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  // @ts-ignore
  return (
    <>
      <div className="social-login">
        <LoginSocialFacebook
          appId={getEnv('FB_APP_ID') || ''}
          onResolve={({ data }) => {
            doSocialLogin('facebook', data);
          }}
          onReject={(err) => {
            console.log(err);
          }}
          className="hidden facebookLoginBTN"
        >
          <></>
        </LoginSocialFacebook>
        <a className="login-button cursor-pointer" href="/" onClick={onFacebookLogin}>
          <img src={facebookIcon} alt="Facebook Login" />
        </a>
        <a className="login-button cursor-pointer" href="/" onClick={googleClick}>
          <img src={googleIcon} alt="Google Login" />
        </a>
      </div>
      <SocialRegisterModal
        showModal={socialData?.registerModal ?? false}
        modalClose={socialRegisterModalClose}
      />
    </>
  );
};

export default SocialLogin;
