import 'styles/pages/ProductPage.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getAuthState,
  getCartState,
  getGeneralInfoState,
  getProductState,
  getSelectedAddressState,
} from 'redux/src/StatesGetter';
import { productsFetchRequest } from 'redux/actions/ProductActions';
import PacketPrice from 'components/PacketPrice';
import { productPriceObj } from 'modules/ProductUtils';
import QuantityChange from 'components/QuantityChange';
import { addOrderItemRequest } from 'redux/actions/OrderActions';
import Order from 'types/Order';
import { setAddressModal } from 'redux/actions/AddressActions';
import Alerts from 'components/Alerts';
import { resolveAccessoriesOnCart, resolveBottlesOnCart } from 'modules/OrderUtils';
import parse from 'html-react-parser';

type QuantityObjectType = {
  pvqpId: undefined | number;
  productVariantId: undefined | number;
  image: undefined | string;
  originalPrice: undefined | number;
  price: undefined | number;
  originalPriceAsString: undefined | string;
  priceAsString: undefined | string;
  quantity: undefined | number;
};

const ProductPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productCode } = useParams();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const cart: Order = useSelector(getCartState, shallowEqual);
  const { data: product } = useSelector(getProductState, shallowEqual);
  const { primaryImage, productName, code, description, aquaDetails } = product;
  const [itemsInCart, setItemsInCart] = useState(0);
  const [isAccessory, setIsAccessory] = useState(code !== '11L' && code !== '19L');
  const [quantitySelected, setQuantitySelected] = useState(
    code !== '11L' && code !== '19L' ? 1 : 2,
  );

  const generalInfo = useSelector(getGeneralInfoState, shallowEqual);
  const address = useSelector(getSelectedAddressState, shallowEqual);
  const [emptyAddress, setEmptyAddress] = useState(Object.keys(address).length === 0);

  useEffect(() => {
    setEmptyAddress(Object.keys(address).length === 0);
  }, [address]);

  const [quantityObject, setQuantityObject] = useState<QuantityObjectType>({
    pvqpId: undefined,
    productVariantId: undefined,
    image: undefined,
    originalPrice: undefined,
    price: undefined,
    originalPriceAsString: undefined,
    priceAsString: undefined,
    quantity: undefined,
  });

  const setQuantity = (change) => {
    const newQuantity = quantitySelected + change;
    if (itemsInCart + newQuantity > 6 && !isAccessory) {
      Alerts.warningAlert(t('product.maxBottlesReached'));
      return;
    }
    setQuantitySelected(newQuantity);
  };

  const addToCart = () => {
    if (emptyAddress) {
      dispatch(setAddressModal({ open: true, setSelected: true, billing: false }));
      return;
    }

    if (address.delivery.ok === false) {
      Alerts.infoAlert(t('product.selectedAddressNoDelivery'));
      return;
    }

    if (address.delivery.ok && address.delivery.type === 'call') {
      Alerts.warningAlert(`${t('product.onlyPhoneOrders')} ${address.delivery.phoneNumber}`);
      return;
    }

    dispatch(
      addOrderItemRequest({
        productCode: product.code,
        productVariantCode: product.variants[0].code,
        quantity: quantitySelected,
        notes: '',
      }),
    );
  };

  useEffect(() => {
    dispatch(productsFetchRequest({ code: productCode }));
  }, []);

  useEffect(() => {
    if (aquaDetails) {
      setQuantityObject(productPriceObj(aquaDetails, quantitySelected));
    }
  }, [quantitySelected, product]);

  useEffect(() => {
    setQuantitySelected(code !== '11L' && code !== '19L' ? 1 : 2);
    setIsAccessory(code !== '11L' && code !== '19L');
  }, [product]);

  useEffect(() => {
    if (!isAccessory) {
      setItemsInCart(resolveBottlesOnCart(cart));
    } else {
      setItemsInCart(resolveAccessoriesOnCart(cart));
    }
  }, [cart]);

  return (
    product && (
      <div className="product-page-wrapper">
        <div className="product-image-wrapper">
          <img src={primaryImage} alt="" />
        </div>
        <div className="product-wrapper">
          <div className="product-content-wrapper">
            <div className="product-quantity-wrapper">
              <div className="product-name-wrapper">
                <span className="product-name">{productName}</span>
                <QuantityChange
                  quantitySelected={quantitySelected}
                  itemsInCart={itemsInCart}
                  setQuantity={setQuantity}
                  accessory={isAccessory}
                  maxQuantity={generalInfo.maxQuantity}
                />
              </div>
              <div className="selected-packet-preview">
                <img className="smallImage" src={quantityObject.image} alt={productName} />
                <div>
                  <PacketPrice
                    quantity={quantitySelected}
                    code={code}
                    productName={productName}
                    priceAsString={quantityObject.priceAsString}
                    price={quantityObject.price}
                    originalPrice={quantityObject.originalPrice}
                    originalPriceAsString={quantityObject.originalPriceAsString}
                  />
                </div>
              </div>
              <div
                onClick={addToCart}
                className={`add-to-cart-button ${
                  itemsInCart + quantitySelected > 6 && !isAccessory ? 'disabled' : ''
                }`}
              >
                <span className="add-to-cart-text">{`${t('addToCart')} ·`}</span>
                <span className="add-to-cart-price">{quantityObject.priceAsString}</span>
              </div>
            </div>
            {description && (
              <div className="product-description-wrapper">
                <span className="product-description-title">{t('description')}</span>
                <div className="product-description">{parse(description)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};
export default ProductPage;
