import { AnyAction } from 'redux';
import {
  COMPANY_MODAL,
  FETCH_COMPANY_DATA_SUCCESS,
  FETCH_CUSTOMER_COMPANIES_SUCCESS,
  GET_CUSTOMER_COMPANY_SUCCESS,
  SELECT_COMPANY_MODAL,
  SET_LOCAL_COMPANY,
  SET_SELECTED_COMPANY,
} from 'redux/types/CompanyTypes';
import { ADDRESS_MODAL } from 'redux/types/AddressTypes';

const CompanyReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case GET_CUSTOMER_COMPANY_SUCCESS:
    case SET_SELECTED_COMPANY:
      return action.payload;
    default:
      return state;
  }
};

const LocalCompanyReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case FETCH_COMPANY_DATA_SUCCESS:
    case SET_LOCAL_COMPANY:
      return action.payload;
    default:
      return state;
  }
};

const CustomerCompaniesReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case FETCH_CUSTOMER_COMPANIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const CompanyModalReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case COMPANY_MODAL:
      return action.payload;
    default:
      return state;
  }
};

const SelectCompanyModalReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case SELECT_COMPANY_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export {
  CompanyReducer,
  CustomerCompaniesReducer,
  LocalCompanyReducer,
  CompanyModalReducer,
  SelectCompanyModalReducer,
};
