import { AnyAction } from 'redux';
import {
  GET_DAY_SCHEDULE_REQUEST,
  GET_DAY_SCHEDULE_SUCCESS,
  GET_DAY_SCHEDULE_ERROR,
  GET_WEEKLY_SCHEDULE_REQUEST,
  GET_WEEKLY_SCHEDULE_SUCCESS,
  GET_WEEKLY_SCHEDULE_ERROR,
  WEEKLY_SCHEDULE_MODAL,
} from 'redux/types/ScheduleTypes';

export const getDayScheduleRequest = (payload): AnyAction => ({
  type: GET_DAY_SCHEDULE_REQUEST,
  payload,
});

export const getDayScheduleSuccess = (payload): AnyAction => ({
  type: GET_DAY_SCHEDULE_SUCCESS,
  payload,
});

export const getDayScheduleError = (payload): AnyAction => ({
  type: GET_DAY_SCHEDULE_ERROR,
  payload,
});

export const getWeeklyScheduleRequest = (payload): AnyAction => ({
  type: GET_WEEKLY_SCHEDULE_REQUEST,
  payload,
});

export const getWeeklyScheduleSuccess = (payload): AnyAction => ({
  type: GET_WEEKLY_SCHEDULE_SUCCESS,
  payload,
});

export const getWeeklyScheduleError = (payload): AnyAction => ({
  type: GET_WEEKLY_SCHEDULE_ERROR,
  payload,
});

export const weeklyScheduleModal = (payload): AnyAction => ({
  type: WEEKLY_SCHEDULE_MODAL,
  payload,
});
