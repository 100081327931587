import HttpGunService from 'services/HttpGunService';
import StorageService from 'services/StorageService';
import { default as StringConstants } from 'constants/StringConstants.json';
import { default as ApiConstants } from 'constants/ApiConstants.json';
import { parseToJson } from 'modules/JsonUtils';
import { getEnv } from 'services/EnvService';
import LocalEventsService from 'services/LocalEventsService';
import { logOutCleanUp } from 'modules/AuthUtils';

const getDestination = () => {
  if (document.getElementById('isDoctorApp')) return 'doctor';
  return 'patient';
};

const getActiveRole = async (): Promise<string | null> => {
  return StorageService.getData(StringConstants.ACTIVE_ROLE, null);
};

const getDefaultHeaders = () => {
  return {
    'X-Client-Platform': 'WEB',
  };
};

const makeUrl = async (url: string) => {
  const locale = await StorageService.getData(StringConstants.APP_LANGUAGE, 'ro');

  if (url === ApiConstants.LOGIN_ENDPOINT || url === ApiConstants.REFRESH_TOKEN_ENDPOINT)
    return `${getEnv('BASE_URL')}/api/v2${url}`;
  return `${getEnv('BASE_URL')}/api/${locale}/v2${url}`;
};

const refreshTokenExceptions = [ApiConstants.REFRESH_TOKEN_ENDPOINT, ApiConstants.LOGIN_ENDPOINT];

const noTokenUrls = [
  ApiConstants.REFRESH_TOKEN_ENDPOINT,
  ApiConstants.LOGIN_ENDPOINT,
  ApiConstants.FORGOT_PASSWORD_ENDPOINT,
  ApiConstants.REGISTER_ENDPOINT,
];

const getErrorMessage = (statusCode, message) => {
  return getEnv('IS_PRODUCTION') === 'false' ? `(${statusCode}) ${message}` : message;
};

const handleErrorResponse = (response: { status: any; text?: () => Promise<any> }, url: any) => {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve) => {
    if (response?.status) {
      (response as any)
        ?.text()
        .then((responseData) => {
          if (typeof responseData === 'string') {
            const errorData = parseToJson(responseData);
            return resolve(getErrorMessage(response?.status, errorData.message));
          }
          const message = responseData?.message ?? `internal server error @ ${url}`;
          return resolve(getErrorMessage(response?.status, message));
        })
        .catch((err) => {
          return resolve(getErrorMessage(response?.status, err.message));
        });
    } else {
      return resolve((response as any)?.message);
    }
  });
};

const doRefreshToken = async () => {
  const fullUrl = await makeUrl(ApiConstants.REFRESH_TOKEN_ENDPOINT);
  const refreshToken = await StorageService.getData(StringConstants.REFRESH_TOKEN, null);
  console.log(
    `%cRefreshToken:\n%c${refreshToken}`,
    'color:#FF5F1F; font-family:monospace; font-size: 15px;',
    'color:#F28C28; font-family:monospace; font-size: 15px;',
  );
  const defaultHeaders: Record<string, string | undefined> = getDefaultHeaders();
  return new Promise((resolve, reject) => {
    HttpGunService.postR(
      fullUrl,
      { refresh_token: refreshToken as any },
      { headers: defaultHeaders },
    )
      .then(async ({ data }: any) => {
        console.log(`%cNew Tokens:`, 'color:#FF5F1F; font-family:monospace; font-size: 15px;');
        console.table(data);
        await StorageService.setData(StringConstants.USER_TOKEN, data.token);
        await StorageService.setData(StringConstants.REFRESH_TOKEN, data.refresh_token);
        return resolve(data.token);
      })
      .catch(() => {
        console.log('error token');
        logOutCleanUp();
        LocalEventsService.emit('forceLogout');
        return resolve(null);
      });
  });
};

const requester = async (
  method: string,
  url: string,
  data: Record<string, unknown> | null,
  freshToken: string | null = null,
  customHeaders: Record<string, unknown> | null = null,
) => {
  const fullUrl = await makeUrl(url);
  let token = freshToken ?? (await StorageService.getData(StringConstants.USER_TOKEN, null));
  if (noTokenUrls.indexOf(url) > -1 || noTokenUrls.indexOf(url.slice(0, -1)) > -1) {
    token = null;
  }
  const defaultHeaders: Record<string, string | undefined> = getDefaultHeaders();
  let headers: any = defaultHeaders;
  if (customHeaders) {
    headers = { ...defaultHeaders, ...customHeaders } as any;
  }
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    // @ts-ignore
    HttpGunService[method](fullUrl, data, { headers }, token)
      // eslint-disable-next-line consistent-return
      .then((response: any) => {
        return resolve(response);
      })
      // eslint-disable-next-line consistent-return
      .catch((error: { status: any; text?: () => Promise<any> }) => {
        if (error?.status === 401 && refreshTokenExceptions.indexOf(url) === -1) {
          doRefreshToken()
            // eslint-disable-next-line consistent-return
            .then((newToken) => {
              if (newToken) {
                HttpGunService[method](fullUrl, data, { headers }, newToken)
                  .then((resp) => {
                    return resolve(resp);
                  })
                  .catch((err) => {
                    console.warn(`try ${JSON.stringify(err)}`);
                    return reject(err);
                  });
              } else {
                return resolve({ data: {} });
              }
            })
            .catch((refreshError) => {
              console.warn(`else2 ${JSON.stringify(refreshError)}`);
              return reject(refreshError);
            });
        } else {
          handleErrorResponse(error, url)
            .then((message) => {
              return reject({ message, status: error?.status });
            })
            .catch((err) => {
              return reject(err);
            });
        }
      });
  });
};

const postRequest = async (
  url: string,
  data: Record<string, unknown> | null = null,
  customHeaders: Record<string, unknown> | null = null,
) => {
  return requester('postR', url, data, null, customHeaders);
};

const patchRequest = async (
  url: string,
  data: Record<string, unknown> | null = null,
  customHeaders: Record<string, unknown> | null = null,
) => {
  return requester('patchR', url, data, null, customHeaders);
};

const getRequest = async (url: string, customHeaders: Record<string, unknown> | null = null) => {
  return requester('getR', url, null, null, customHeaders);
};

const putRequest = async (
  url: string,
  data: Record<string, unknown> | null = null,
  customHeaders: Record<string, unknown> | null = null,
) => {
  return requester('putR', url, data, null, customHeaders);
};

const deleteRequest = async (
  url: string,
  data: Record<string, unknown> | null = null,
  customHeaders: Record<string, unknown> | null = null,
) => {
  return requester('deleteR', url, data, null, customHeaders);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
  doRefreshToken,
  makeUrl,
};
