import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/NextDaysScheduleModal.scss';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import {
  getGeneralInfoState,
  getWeeklyScheduleModalState,
  getWeeklyScheduleState,
} from 'redux/src/StatesGetter';
import { weeklyScheduleModal } from 'redux/actions/ScheduleActions';
import phoneIcon from 'assets/images/phone-icon.svg';

const NextDaysScheduleModal = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const generalInfo = useSelector(getGeneralInfoState, shallowEqual);
  const schedule = useSelector(getWeeklyScheduleState, shallowEqual);
  const modalState = useSelector(getWeeklyScheduleModalState, shallowEqual);

  const closeDownloadDataModal = () => {
    dispatch(weeklyScheduleModal({ open: false }));
  };

  return (
    <Modal
      show={modalState.open}
      onHide={closeDownloadDataModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="next-days-schedule-modal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">{t('scheduleModal.program')}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="schedule-hours-wrapper">
          {schedule.length > 0 &&
            schedule.map((day) => (
              <div key={`schedule_${day.date}`} className="schedule-day">
                <span>{`${day.date}`}</span>
                <span className="time-intervals">
                  {day.status === 'workday'
                    ? `${day.timeStart} - ${day.timeEnd}`
                    : t('scheduleModal.closed')}
                </span>
              </div>
            ))}
        </div>
        {generalInfo.phoneNumber && (
          <div className="schedule-contact-wrapper">
            <span>{t('scheduleModal.telephoneContact')}</span>
            <a href={`tel:${generalInfo?.phoneNumber ?? ''}`} className="schedule-phone-btn">
              <img width="14" height="18" src={phoneIcon} alt="phone" />
              <span>{generalInfo.phoneNumber}</span>
            </a>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default NextDaysScheduleModal;
