import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';

import Alerts from 'components/Alerts';
import i18next from 'i18next';
import { GET_CHANNEL_REQUEST } from 'redux/types/GeneralTypes';
import { getChannelAPI } from 'redux/api/GeneralApi';
import { getChannelError, getChannelSuccess } from 'redux/actions/GeneralActions';
import { channelSelector } from 'redux/selector/GeneralSelector';

function* getChannel(actionType: ActionPattern<Action<any>>) {
  const getDataChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getDataChannel);
    try {
      const response = yield call(getChannelAPI, 'default');
      yield put(getChannelSuccess(channelSelector(response)));
    } catch ({ message }) {
      console.log(message);
      yield put(getChannelError({ message }));
    }
  }
}

function* generalSaga() {
  yield fork(getChannel, GET_CHANNEL_REQUEST);
}

export default generalSaga;
