import ApiUtils from 'modules/ApiUtils';
import { default as ApiConstants } from 'constants/ApiConstants.json';

const getProfileAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.getRequest(`${ApiConstants.GET_PROFILE_DATA_ENDPOINT}/`, payload);

const setProfileDataAPI = ({ id, patientData }) =>
  ApiUtils.putRequest(`${ApiConstants.SET_PROFILE_DATA_ENDPOINT}/${id}`, patientData);

export { getProfileAPI, setProfileDataAPI };
