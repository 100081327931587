import { AnyAction } from 'redux';
import {
  PRODUCT_VARIANT_FETCH_ERROR,
  PRODUCT_VARIANT_FETCH_REQUEST,
  PRODUCT_VARIANT_FETCH_SUCCESS,
} from 'redux/types/ProductVariantTypes';

export const productVariantFetchRequest = (payload): AnyAction => ({
  type: PRODUCT_VARIANT_FETCH_REQUEST,
  payload,
});

export const productVariantFetchSuccess = (payload): AnyAction => ({
  type: PRODUCT_VARIANT_FETCH_SUCCESS,
  payload,
});

export const productVariantFetchError = (payload): AnyAction => ({
  type: PRODUCT_VARIANT_FETCH_ERROR,
  payload,
});
