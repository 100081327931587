/* eslint-disable consistent-return */

const noBodyMethods = ['GET', 'HEAD'];

const formatBodyAndHeader = (
  method: string,
  data: { constructor: ObjectConstructor },
  header: any,
  requestToken: string | null = null,
) => {
  let formattedData = data;
  let formattedHeader = {
    accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    ...header,
  };
  if (data?.constructor === Object) {
    // @ts-ignore
    formattedData = JSON.stringify(data);
  }
  // @ts-ignore
  if (data?.constructor === FormData) {
    formattedHeader = {};
  }
  if (requestToken) {
    formattedHeader = { ...formattedHeader, Authorization: `Bearer ${requestToken}` };
  }
  if (!data || noBodyMethods.indexOf(method) > -1) {
    // @ts-ignore
    formattedData = undefined;
  }
  return { body: formattedData, headers: formattedHeader };
};

const watchResponseInterceptor = (headers: { constructor: ObjectConstructor }) => {
  if (headers && headers.constructor === Object) {
    return true;
  }
};

const fetcher = async (
  method: string,
  url: RequestInfo,
  data: { constructor: ObjectConstructor },
  header: unknown,
  token: string | null = null,
) => {
  const { body, headers } = formatBodyAndHeader(method, data, header, token);
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method,
        body: body as unknown as ReadableStream<Uint8Array>,
        headers,
      });
      if (response.status === 204) {
        return resolve({ data: {} });
      }
      try {
        if (response.ok) {
          response
            .json()
            .then((responseData) => {
              return resolve({ data: responseData });
            })
            .catch(() => {
              return resolve({});
            });
        } else {
          return reject(response);
        }
      } catch (error) {
        return reject(error);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

const getR = async (
  url: RequestInfo,
  data: { constructor: ObjectConstructor },
  options: Record<string, unknown> = {},
  token: string | null = null,
) => {
  const { headers = {} } = options;
  return fetcher('GET', url, data, headers, token);
};

const postR = async (
  url: RequestInfo,
  data: any,
  options: Record<string, unknown> = {},
  token: string | null = null,
) => {
  const { headers = {} } = options;
  return fetcher('POST', url, data, headers, token);
};

const putR = async (
  url: RequestInfo,
  data: { constructor: ObjectConstructor },
  options: Record<string, unknown> = {},
  token: string | null = null,
) => {
  const { headers = {} } = options;
  return fetcher('PUT', url, data, headers, token);
};

const deleteR = async (
  url: RequestInfo,
  data: { constructor: ObjectConstructor },
  options: Record<string, unknown> = {},
  token: string | null = null,
) => {
  const { headers = {} } = options;
  return fetcher('DELETE', url, data, headers, token);
};

const patchR = async (
  url: RequestInfo,
  data: { constructor: ObjectConstructor },
  options: Record<string, unknown> = {},
  token: string | null = null,
) => {
  const { headers = {} } = options;
  return fetcher('PATCH', url, data, headers, token);
};

export default { getR, postR, patchR, putR, deleteR };
