export const FETCH_ADDRESS_LIST_REQUEST = 'FETCH_ADDRESS_LIST_REQUEST';
export const FETCH_ADDRESS_LIST_SUCCESS = 'FETCH_ADDRESS_LIST_SUCCESS';
export const FETCH_ADDRESS_LIST_ERROR = 'FETCH_ADDRESS_LIST_ERROR';

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_ERROR = 'ADD_ADDRESS_ERROR';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_ERROR = 'UPDATE_ADDRESS_ERROR';

export const DELETE_ADDRESS_REQUEST = 'DELETE_ADDRESS_REQUEST';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_ERROR = 'DELETE_ADDRESS_ERROR';

export const LOCAL_ADDRESS = 'LOCAL_ADDRESS';

export const SELECTED_ADDRESS_REQUEST = 'SELECTED_ADDRESS_REQUEST';
export const SELECTED_ADDRESS_SUCCESS = 'SELECTED_ADDRESS_SUCCESS';
export const SELECTED_ADDRESS_ERROR = 'SELECTED_ADDRESS_ERROR';

export const ADDRESS_MODAL = 'ADDRESS_MODAL';
export const SELECT_ADDRESS_MODAL = 'SELECT_ADDRESS_MODAL';
export const CONTACT_INFO_MODAL = 'CONTACT_INFO_MODAL';

export const CONTACT_INFO = 'CONTACT_INFO';
