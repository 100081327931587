import { convertPrice, convertPriceToString } from 'modules/CurrencyUtils';
import Order from 'types/Order';
import { getSlotTime, orderDateETAFormat } from 'modules/DateUtils';
import { orderItemSelector } from 'redux/selector/OrderItemSelector';
import OrderItem from 'types/OrderItem';

const defaultOrder: Order = {
  id: undefined,
  tokenValue: null,
  number: null,
  items: [],
  totalQuantity: 0,
  totalUniqueItems: 0,
  itemsTotal: 0,
  itemsTotalAsString: '0 RON',
  total: 0,
  totalAsString: '0 RON',
  adjustmentsTotal: 0,
  adjustmentsTotalAsString: '0 RON',
  orderPromotionTotal: 0,
  orderPromotionTotalAsString: '0 RON',
  taxTotal: 0,
  taxTotalAsString: '0 RON',
  shippingTotal: 0,
  shippingTotalAsString: '0 RON',
  notes: null,
  payments: [],
  shipments: [],
  shippingAddress: null,
  billingAddress: null,
  checkoutState: null,
  paymentState: null,
  shippingState: null,
  state: null,
  checkoutCompletedAt: null,
  currencyCode: 'RON',
  localeCode: 'ro',
  timeBeforeDeliveryInMinutes: 1,
  vehicle: null,
};

const orderDetailsSelector = (order: Record<string, any>): Order => {
  const resolveItems = (items): OrderItem[] => {
    if (!items || !items.length) {
      return [];
    }

    return items.map((item: Record<string, any>): OrderItem => orderItemSelector(item));
  };

  const resolvedItems = resolveItems(order.items);
  const totalQuantity = resolvedItems.reduce(
    (previousQuantity, currentItem) => previousQuantity + currentItem.quantity,
    0,
  );

  return {
    id: order?.id,
    tokenValue: order.tokenValue,
    number: order.number ?? '',
    items: resolvedItems,
    totalQuantity,
    totalUniqueItems: resolvedItems.length ?? 0,
    itemsTotal: convertPrice(order.itemsTotal),
    itemsTotalAsString: convertPriceToString(order.itemsTotal),
    total: convertPrice(order.total),
    totalAsString: convertPriceToString(order.total),
    adjustmentsTotal: convertPrice(order.adjustmentsTotal),
    adjustmentsTotalAsString: convertPriceToString(order.adjustmentsTotal),
    orderPromotionTotal: convertPrice(order.orderPromotionTotal),
    orderPromotionTotalAsString: convertPriceToString(order.orderPromotionTotal),
    taxTotal: convertPrice(order.taxTotal),
    taxTotalAsString: convertPriceToString(order.taxTotal),
    shippingTotal: convertPrice(order.shippingTotal),
    shippingTotalAsString: convertPriceToString(order.shippingTotal),
    delivery: {
      deliveryDate: order.deliveryDate,
      deliveryEndETA: order.deliveryEndETA,
      deliveryStartETA: order.deliveryStartETA,
      deliveryState: order.deliveryState,
      deliveryHours: orderDateETAFormat(order.deliveryStartETA, order.deliveryEndETA),
    },
    notes: order.notes,
    payments: order.payments,
    shipments: order.shipments,
    shippingAddress: order.shippingAddress,
    billingAddress: order.billingAddress,
    checkoutState: order.checkoutState,
    paymentState: order.paymentState,
    shippingState: order.shippingState,
    state: order.state,
    checkoutCompletedAt: order.checkoutCompletedAt,
    currencyCode: order.currencyCode,
    localeCode: order.localeCode,
    timeBeforeDeliveryInMinutes: order.timeBeforeDeliveryInMinutes ?? 1,
    vehicle: order.vehicle,
  };
};

const ordersSelector = (orders): Order[] => {
  return orders.map((order): Order => {
    return orderDetailsSelector(order);
  });
};

const shippingMethodSelector = (shippingMethod) => {
  if (shippingMethod.delivery.type === 'asap') {
    return {
      type: shippingMethod?.delivery?.type ?? 'asap',
      etaStart: shippingMethod?.delivery?.asap_options?.diff?.start ?? 1,
      etaEnd: shippingMethod?.delivery?.asap_options?.diff?.end ?? 30,
      slotStart: shippingMethod?.delivery?.asap_options?.slot?.start?.date ?? '',
      slotEnd: shippingMethod?.delivery?.asap_options?.slot?.end?.date ?? '',
      vehicle_id: shippingMethod?.delivery?.asap_options?.vehicle_id ?? 1,
    };
  }

  return {
    type: shippingMethod?.delivery?.type ?? 'planned',
    options: shippingMethod?.delivery?.planned_options[0]?.days?.map((day) => {
      return {
        day: day.day,
        slots: day.slots.map((slot) => {
          return {
            available: slot.available,
            startETA: slot.start.date,
            endETA: slot.end.date,
            timeSlot: `${getSlotTime(slot.start.date)} - ${getSlotTime(slot.end.date)}`,
          };
        }),
      };
    }),
    vehicle_id: shippingMethod?.delivery?.planned_options[0]?.vehicle_id ?? 0,
  };
};

export { defaultOrder, orderDetailsSelector, ordersSelector, shippingMethodSelector };
