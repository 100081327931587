import 'styles/components/PacketPrice.scss';
import { useTranslation } from 'react-i18next';

const PacketPrice = (props) => {
  const {
    quantity,
    code,
    priceAsString,
    price,
    originalPrice,
    originalPriceAsString,
    productName,
    bottlePacket: isPacket,
  } = props;

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="product-batch-name">
        {isPacket ? `${t('packet')} ${quantity} x ${productName}` : `${productName} x ${quantity}`}
      </div>
      <div className="product-batch-price">
        <span className="product-batch-final-price">{priceAsString}</span>
        {price !== originalPrice && (
          <span className="product-batch-init-price">{originalPriceAsString}</span>
        )}
      </div>
    </>
  );
};

export default PacketPrice;
