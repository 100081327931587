import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import {
  CREATE_CUSTOMER_COMPANY_REQUEST,
  DELETE_CUSTOMER_COMPANY_REQUEST,
  FETCH_COMPANY_DATA_REQUEST,
  FETCH_CUSTOMER_COMPANIES_REQUEST,
  GET_CUSTOMER_COMPANY_REQUEST,
  UPDATE_CUSTOMER_COMPANY_REQUEST,
} from 'redux/types/CompanyTypes';
import {
  createCustomerCompanyApi,
  deleteCustomerCompanyApi,
  fetchCompanyDataApi,
  fetchCustomerCompaniesApi,
  getCustomerCompanyApi,
  updateCustomerCompanyApi,
} from 'redux/api/CompanyApi';
import {
  createCustomerCompanyError,
  createCustomerCompanySuccess,
  deleteCustomerCompanyError,
  deleteCustomerCompanySuccess,
  fetchCompanyDataError,
  fetchCompanyDataSuccess,
  fetchCustomerCompaniesError,
  fetchCustomerCompaniesRequest,
  fetchCustomerCompaniesSuccess,
  getCustomerCompanyError,
  getCustomerCompanySuccess,
  setCompanyModal,
  updateCustomerCompanyError,
  updateCustomerCompanySuccess,
} from 'redux/actions/CompanyActions';
import Alerts from 'components/Alerts';
import { companySelector } from 'redux/selector/CompanySelector';
import i18next from 'i18next';

function* createCompany(actionType: ActionPattern<Action<any>>) {
  const createCompanyChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(createCompanyChannel);
    try {
      yield call(createCustomerCompanyApi, payload);
      Alerts.successAlert(`${i18next.t('alerts.companyAdded')}`);
      yield put(fetchCustomerCompaniesRequest({}));
      yield put(createCustomerCompanySuccess({}));
    } catch ({ message }) {
      Alerts.errorAlert(`${i18next.t('alerts.errorAddCompany')}`);
      yield put(createCustomerCompanyError({ message }));
    }
  }
}

function* getCompany(actionType: ActionPattern<Action<any>>) {
  const getCompanyChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getCompanyChannel);
    try {
      const { id } = payload;
      yield call(getCustomerCompanyApi, { id });
      yield put(getCustomerCompanySuccess({}));
    } catch ({ message }) {
      yield put(getCustomerCompanyError({ message }));
    }
  }
}

function* fetchCustomerCompanies(actionType: ActionPattern<Action<any>>) {
  const fetchCompaniesChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(fetchCompaniesChannel);
    try {
      const response = yield call(fetchCustomerCompaniesApi);
      yield put(fetchCustomerCompaniesSuccess(response));
    } catch ({ message }) {
      yield put(fetchCustomerCompaniesError({ message }));
    }
  }
}

function* updateCompany(actionType: ActionPattern<Action<any>>) {
  const updateCompanyChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateCompanyChannel);
    try {
      const { id, data } = payload;
      yield call(updateCustomerCompanyApi, { id, data });
      Alerts.successAlert(`${i18next.t('alerts.companyUpdated')}`);
      yield put(fetchCustomerCompaniesRequest({}));
      yield put(updateCustomerCompanySuccess({}));
    } catch ({ message }) {
      Alerts.errorAlert(`${i18next.t('alerts.errorUpdateCompany')}`);
      yield put(updateCustomerCompanyError({ message }));
    }
  }
}

function* deleteCompany(actionType: ActionPattern<Action<any>>) {
  const deleteCompanyChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deleteCompanyChannel);
    try {
      const { id } = payload;
      yield call(deleteCustomerCompanyApi, { id });
      yield put(fetchCustomerCompaniesRequest({}));
      Alerts.successAlert(`${i18next.t('alerts.companyDeleted')}`);
      yield put(deleteCustomerCompanySuccess({}));
    } catch ({ message }) {
      Alerts.errorAlert(`${i18next.t('alerts.errorDeleteCompany')}`);
      yield put(deleteCustomerCompanyError({ message }));
    }
  }
}

function* fetchCompanyData(actionType: ActionPattern<Action<any>>) {
  const fetchCompanyDataChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(fetchCompanyDataChannel);
    try {
      const { cui } = payload;
      const response = yield call(fetchCompanyDataApi, { cui });
      const filteredData = companySelector(response.data.companyData);
      if (filteredData.company === '') {
        Alerts.errorAlert(`${i18next.t('alerts.companyNotFound')}`);
      }
      yield put(fetchCompanyDataSuccess(filteredData));
    } catch ({ message }) {
      yield put(fetchCompanyDataError({ message }));
    }
  }
}

function* companySaga() {
  yield fork(createCompany, CREATE_CUSTOMER_COMPANY_REQUEST);
  yield fork(getCompany, GET_CUSTOMER_COMPANY_REQUEST);
  yield fork(fetchCustomerCompanies, FETCH_CUSTOMER_COMPANIES_REQUEST);
  yield fork(updateCompany, UPDATE_CUSTOMER_COMPANY_REQUEST);
  yield fork(deleteCompany, DELETE_CUSTOMER_COMPANY_REQUEST);
  yield fork(fetchCompanyData, FETCH_COMPANY_DATA_REQUEST);
}

export default companySaga;
