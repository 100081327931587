import StaticPage from 'pages/support/StaticPage';
import { getEnv } from 'services/EnvService';
import { default as ApiConstants } from 'constants/ApiConstants.json';
import { useTranslation } from 'react-i18next';

const AboutDeliveryPage = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  return (
    <StaticPage
      url={`${getEnv('BASE_URL')}/api/${locale}/v2${ApiConstants.ABOUT_DELIVERY_ENDPOINT}`}
    />
  );
};

export default AboutDeliveryPage;
