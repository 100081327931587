import 'styles/components/OrdersHistoryOrderBlock.scss';
import { useTranslation } from 'react-i18next';
import activeBullet from 'assets/images/active-bullet.svg';
import { orderHistoryDateFormat } from 'modules/DateUtils';

const OrdersHistoryOrderBlock = ({ order, clickEvent }) => {
  const { t, i18n } = useTranslation();
  const { number, state, totalAsString, checkoutCompletedAt, items, tokenValue } = order;

  return (
    <div
      className="order"
      onClick={() => {
        clickEvent(tokenValue);
      }}
    >
      <div className="order-id-wrapper">
        <span className="order-id">
          {`${t('order.order')} ${number}`}
          {state === 'new' ? <img src={activeBullet} alt="active-bullet" /> : ''}
        </span>
        <span className="order-total">{totalAsString}</span>
      </div>
      <div className="order-date">{orderHistoryDateFormat(checkoutCompletedAt)}</div>
      <div className="order-items-wrapper">
        {items.length > 0 &&
          items.map((item) => {
            return (
              <div key={item.variant.code} className="order-item">
                {item.quantity > 1
                  ? `${t('packet')} ${item.quantity} x ${item.productName}`
                  : `${item.quantity} x ${item.productName}`}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default OrdersHistoryOrderBlock;
