import 'styles/pages/CartPage.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  getAuthState,
  getAvailableShippingMethodsState,
  getCartState,
  getInvoiceTypeState,
  getShippingMethodsState,
} from 'redux/src/StatesGetter';
import {
  completeOrderOnCheckoutRequest,
  deleteCartRequest,
  fetchCartRequest,
  fetchOrderPaymentMethodsRequest,
  fetchOrderShippingMethodsRequest,
  setOrderShippingMethod,
} from 'redux/actions/OrderActions';
import noItemsInCart from 'assets/images/no-items-cart-icon.svg';
import deleteAddressIcon from 'assets/images/delete-icon.svg';
import CartDeliveryBlock from 'components/CartDeliveryBlock';
import CartPaymentBlock from 'components/CartPaymentBlock';
import CartCheckoutBlock from 'components/CartCheckoutBlock';
import CartProducts from 'components/CartProducts';
import Alerts from 'components/Alerts';
import Order from 'types/Order';
import ApiUtils from 'modules/ApiUtils';
import { default as ApiConstants } from 'constants/ApiConstants.json';
import { getEnv } from 'services/EnvService';
import CartBillingBlock from '../components/CartBillingBlock';
import CartContactInfoModal from '../components/modals/CartContactInfoModal';

const CartPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const cart: Order = useSelector(getCartState, shallowEqual);
  const companyInvoice = useSelector(getInvoiceTypeState, shallowEqual);

  const shippingMethodState = useSelector(getShippingMethodsState, shallowEqual);
  const availableShippingMethods = useSelector(getAvailableShippingMethodsState, shallowEqual);

  useEffect(() => {
    if (availableShippingMethods.type === 'asap') {
      dispatch(
        setOrderShippingMethod({
          slotStart: availableShippingMethods.slotStart,
          slotEnd: availableShippingMethods.slotEnd,
          type: availableShippingMethods.type,
          vehicle_id: availableShippingMethods.vehicle_id,
        }),
      );
    }
  }, [availableShippingMethods]);

  const startPaymentFunction = () => {
    const w = window.open(
      'about:blank',
      'Popup',
      'toolbar=no, location=yes, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30',
    )!;

    ApiUtils.doRefreshToken().then(async (newToken) => {
      console.log(`${ApiConstants.START_PAYMENT_ENDPOINT}/${cart.id}?web=1`);
      const url = await ApiUtils.makeUrl(`${ApiConstants.START_PAYMENT_ENDPOINT}/${cart.id}?web=1`);
      fetch(`${url}`, {
        headers: {
          Authorization: `Bearer ${newToken}`,
        },
      })
        .then(function (response) {
          return response.text();
        })
        .then((data) => {
          w.document.open();
          w.document.write(data);
          w.document.close();
          const timer = window.setInterval(() => {
            if (w.closed) {
              clearInterval(timer);
              dispatch(fetchCartRequest({}));
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const checkout = async () => {
    if (!isLoggedIn) {
      Alerts.okCancelAlert(t('warning'), t('alerts.youNeedToLogin'), () => {
        navigate('/login');
      });
      return;
    }

    if (availableShippingMethods.type !== shippingMethodState.type) {
      Alerts.warningAlert(t('alerts.selectProgramDateError'));
      return;
    }

    if (
      companyInvoice &&
      (!cart.billingAddress?.uniqueRegistrationCode ||
        cart.billingAddress?.uniqueRegistrationCode === '')
    ) {
      Alerts.warningAlert(t('alerts.selectCompanyError'));
      return;
    }

    Alerts.okCancelAlert(
      t('cart.completeOrderOnCheckout'),
      t('cart.completeOrderOnCheckoutConfirmation'),
      () => {
        // @ts-ignore
        if (cart?.payments?.length > 0 && cart?.payments[0]?.method?.code === 'cash') {
          dispatch(
            completeOrderOnCheckoutRequest({
              deliveryType: shippingMethodState?.type ?? '',
              deliveryStart: shippingMethodState?.slotStart ?? '',
              deliveryEnd: shippingMethodState?.slotEnd ?? '',
              vehicleId: `${shippingMethodState?.vehicle_id}` ?? '1',
              notes: '',
            }),
          );
        } else {
          startPaymentFunction();
        }
      },
      () => {},
    );
  };

  useEffect(() => {
    // @ts-ignore
    if (cart?.payments[0]?.state === 'completed') {
      dispatch(
        completeOrderOnCheckoutRequest({
          deliveryType: shippingMethodState?.type ?? '',
          deliveryStart: shippingMethodState?.slotStart ?? '',
          deliveryEnd: shippingMethodState?.slotEnd ?? '',
          vehicleId: `${shippingMethodState?.vehicle_id}` ?? '1',
          notes: '',
        }),
      );
    }
  }, [cart]);

  const deleteCart = () => dispatch(deleteCartRequest({}));

  useEffect(() => {
    dispatch(fetchCartRequest({}));

    if (cart.shippingAddress) {
      dispatch(fetchOrderShippingMethodsRequest({}));
      dispatch(fetchOrderPaymentMethodsRequest({}));
    }
  }, []);

  return (
    <div className="cart-page-wrapper container">
      <div className="d-flex justify-content-between my-4">
        <div className="cart-page-title">{t('cart.shoppingCart')}</div>
        {cart.items.length > 0 && (
          <button type="button" className="delete-cart" onClick={deleteCart}>
            <img src={deleteAddressIcon} alt="delete-address" />
            {t('emptyCart')}
          </button>
        )}
      </div>
      <div className="cart-page-content">
        {cart.items.length > 0 ? (
          <div className="row">
            <div className="col-12 col-lg-6">
              <CartProducts cart={cart} />
            </div>
            <div className="col-12 col-lg-6">
              <CartDeliveryBlock cart={cart} />
              {cart.shippingAddress && <CartPaymentBlock cart={cart} />}
              {cart.shippingAddress && <CartBillingBlock cart={cart} />}
              <CartCheckoutBlock checkout={checkout} cart={cart} />
            </div>
          </div>
        ) : (
          <div className="no-items-wrapper">
            <div className="empty-cart-img-wrapper">
              <img src={noItemsInCart} alt="no-items-on-cart" />
            </div>
            <span className="empty-cart-text">{t('cart.emptyCart')}</span>
          </div>
        )}
      </div>
      <CartContactInfoModal />
    </div>
  );
};

export default CartPage;
