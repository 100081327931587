import { Action, combineReducers, compose, Reducer, Store } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'redux/saga';
import * as localForage from 'localforage';
import { FORCE_LOGOUT_SUCCESS, LOGOUT_SUCCESS } from 'redux/types/AuthTypes';
import { PERSIST, PURGE } from 'redux-persist/es/constants';
import { AuthReducer, PreRegisterReducer } from 'redux/reducers/AuthReducer';
import {
  AccessoryProductsReducer,
  AllProductsReducer,
  ProductReducer,
} from 'redux/reducers/ProductReducer';
import { DownloadDataModalStateReducer, ProfileReducer } from 'redux/reducers/ProfileReducer';
import {
  ActiveOrdersReducer,
  AvailableShippingMethodsReducer,
  CartReducer,
  InactiveOrdersReducer,
  InvoiceReducer,
  LastOrdersReducer,
  OrderDetailsReducer,
  PaymentMethodsReducer,
  ReviewModalReducer,
  ShippingMethodsReducer,
} from 'redux/reducers/OrderReducer';
import {
  AddressListReducer,
  AddressModalReducer,
  ContactInfoModalReducer,
  ContactInfoReducer,
  LocalAddressReducer,
  SelectAddressModalReducer,
  SelectedAddressReducer,
} from 'redux/reducers/AddressReducer';
import { OrderItemReducer } from 'redux/reducers/OrderItemReducer';
import { errorReducer, loadingReducer } from 'redux/src/GenericReducer';
import {
  TodayScheduleReducer,
  WeeklyScheduleModalReducer,
  WeeklyScheduleReducer,
} from 'redux/reducers/ScheduleReducer';
import {
  CompanyModalReducer,
  CompanyReducer,
  CustomerCompaniesReducer,
  LocalCompanyReducer,
  SelectCompanyModalReducer,
} from 'redux/reducers/CompanyReducer';
import { DeviceReducer } from 'redux/reducers/DeviceReducer';
import { GetChannelDataReducer } from 'redux/reducers/GeneralReducer';
import TranslationsReducer from 'redux/reducers/TranslationsReducer';

// @ts-ignore
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const persistRootKey = `AquaVia_web`;

const appReducer = combineReducers({
  loadingState: loadingReducer,
  errorState: errorReducer,
  authState: AuthReducer as Reducer<void, Action<any>>,
  generalInfoState: GetChannelDataReducer as Reducer<void, Action<any>>,
  allProductsState: AllProductsReducer as Reducer<void, Action<any>>,
  productState: ProductReducer as Reducer<void, Action<any>>,
  userProfileState: ProfileReducer as Reducer<void, Action<any>>,
  userPreRegisterState: PreRegisterReducer as Reducer<void, Action<any>>,
  activeOrdersState: ActiveOrdersReducer as Reducer<void, Action<any>>,
  inactiveOrdersState: InactiveOrdersReducer as Reducer<void, Action<any>>,
  lastOrderState: LastOrdersReducer as Reducer<void, Action<any>>,
  orderDetailsState: OrderDetailsReducer as Reducer<void, Action<any>>,
  cartState: CartReducer as Reducer<void, Action<any>>,
  orderItemState: OrderItemReducer as Reducer<void, Action<any>>,
  shippingMethodsState: ShippingMethodsReducer as Reducer<void, Action<any>>,
  availableShippingMethodsState: AvailableShippingMethodsReducer as Reducer<void, Action<any>>,
  paymentMethodsState: PaymentMethodsReducer as Reducer<void, Action<any>>,
  accessoryProductsState: AccessoryProductsReducer as Reducer<void, Action<any>>,
  selectedAddressState: SelectedAddressReducer as Reducer<void, Action<any>>,
  addressListState: AddressListReducer as Reducer<void, Action<any>>,
  localAddressState: LocalAddressReducer as Reducer<void, Action<any>>,
  addressModalState: AddressModalReducer as Reducer<void, Action<any>>,
  selectAddressModalState: SelectAddressModalReducer as Reducer<void, Action<any>>,
  contactInfoModalState: ContactInfoModalReducer as Reducer<void, Action<any>>,
  contactInfoState: ContactInfoReducer as Reducer<void, Action<any>>,
  todayScheduleState: TodayScheduleReducer as Reducer<void, Action<any>>,
  weeklyScheduleModalState: WeeklyScheduleModalReducer as Reducer<void, Action<any>>,
  weeklyScheduleState: WeeklyScheduleReducer as Reducer<void, Action<any>>,
  selectedCompanyState: CompanyReducer as Reducer<void, Action<any>>,
  localCompanyState: LocalCompanyReducer as Reducer<void, Action<any>>,
  customerCompaniesState: CustomerCompaniesReducer as Reducer<void, Action<any>>,
  companyModalState: CompanyModalReducer as Reducer<void, Action<any>>,
  selectCompanyModalState: SelectCompanyModalReducer as Reducer<void, Action<any>>,
  downloadDataModalState: DownloadDataModalStateReducer as Reducer<void, Action<any>>,
  deviceState: DeviceReducer as Reducer<void, Action<any>>,
  invoiceTypeState: InvoiceReducer as Reducer<void, Action<any>>,
  reviewModalState: ReviewModalReducer as Reducer<void, Action<any>>,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS || action.type === FORCE_LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: persistRootKey,
  storage: localForage,
  version: 1,
  blacklist: ['loadingState', 'errorState'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store: Store = configureStore({
  reducer: persistedReducer,
  devTools: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [PERSIST, PURGE],
      },
    }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

const storeDispatcher = (action) => store.dispatch(action);

const storeStateGetter = (state) => store.getState()[state];

export { persistor, store, storeStateGetter, storeDispatcher };
