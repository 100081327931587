const convertPrice = (price) => {
  if (!price) return 0;
  return price / 100;
};

const convertPriceToString = (price) => {
  if (!price) return '0 RON';
  return `${price / 100} RON`;
};

export { convertPrice, convertPriceToString };
