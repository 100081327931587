import { defaultBillingAddress } from 'redux/selector/AddressSelector';
import Address from 'types/Address';

export const formatAddressData = (data) => {
  const finalData: Address = { ...defaultBillingAddress };

  finalData.latitude = data.lat ?? data.latitude ?? '';
  finalData.longitude = data.lng ?? data.longitude ?? '';
  finalData.streetNumber = data.streetNumber ?? '';
  finalData.postcode = data.postcode ?? '';
  finalData.countryCode = data.countryCode ?? 'RO';
  finalData.provinceCode = data.provinceCode ?? 'RO-B';
  finalData.provinceName = data.provinceName ?? 'Bucuresti';
  finalData.street = data.street ?? '';
  finalData.city = data.city ?? '';
  finalData.phoneNumber = data.phoneNumber ?? '';
  finalData.additionalInfo = data.additionalInfo ?? '';
  finalData.firstName = data.firstName ?? '';
  finalData.lastName = data.lastName ?? '';
  finalData.company = data.company ?? '';
  finalData.uniqueRegistrationCode = data.uniqueRegistrationCode ?? '';
  finalData.commercialRegisterNumber = data.commercialRegisterNumber ?? '';
  finalData.bankAccount = data.bankAccount ?? '';
  finalData.bankName = data.bankName ?? '';
  finalData.id = data.id ?? undefined;

  data.address_components?.forEach((item) => {
    if (item.types.includes('street_number')) finalData.streetNumber = item.long_name;
    if (item.types.includes('postal_code')) finalData.postcode = item.long_name;
    if (item.types.includes('route')) finalData.street = item.long_name;
    if (item.types.includes('locality')) finalData.city = item.long_name;
    if (item.types.includes('country')) finalData.countryCode = item.short_name;
    if (item.types.includes('administrative_area_level_1')) {
      if (item.short_name === 'IF') {
        finalData.provinceCode = 'RO-IF';
        finalData.provinceName = 'Ilfov';
      } else {
        finalData.provinceCode = 'RO-B';
        finalData.provinceName = 'Bucuresti';
      }
    }
  });

  return finalData;
};

export const addressAsString = (data) => {
  if (!data?.street && !data?.streetNumber && !data?.city) return '';

  const hasStreetAndNum = data.street?.length > 0 || data.streetNumber?.length > 0;

  return `${data.street}${data.streetNumber?.length > 0 ? ' ' : ''}${data.streetNumber}${
    hasStreetAndNum && (data.city?.length > 0 || data.postcode?.length) > 0 ? ',' : ''
  }${hasStreetAndNum && data.city?.length > 0 ? ' ' : ''}${data.city}${
    data.city?.length > 0 && data.postcode?.length > 0 ? ' ' : ''
  }${data.postcode}`;
};
