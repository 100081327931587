import 'styles/components/CartItem.scss';
import editIcon from 'assets/images/edit-icon.svg';
import deleteIcon from 'assets/images/delete-icon.svg';
import { useTranslation } from 'react-i18next';
import Alerts from 'components/Alerts';
import PacketPrice from 'components/PacketPrice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeOrderItemRequest } from 'redux/actions/OrderActions';
import { getOrderItem } from 'redux/actions/OrderItemActions';
import { getOrderItemState } from '../redux/src/StatesGetter';

const CartItem = ({ item, showCartItemModal }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const {
    productName,
    quantity,
    total,
    totalAsString,
    totalBeforeDiscount,
    totalBeforeDiscountAsString,
    product,
  } = item;
  const { isPacket, code, image, quantityPriceItem } = product;

  const removeConfirm = () =>
    Alerts.okCancelAlert(
      t('cart.removeFromCart'),
      t('cart.removeFromCartConfirmation'),
      () => {
        dispatch(removeOrderItemRequest(item.id));
      },
      () => {},
    );

  const setSelectedProduct = () => {
    dispatch(getOrderItem(item));
  };

  return (
    <div className="cart-item">
      <img className="cart-item-image" src={quantityPriceItem?.image ?? image} alt={productName} />
      <div className="cart-item-data">
        <PacketPrice
          quantity={quantity}
          code={code}
          productName={productName}
          priceAsString={totalAsString}
          price={total}
          originalPrice={totalBeforeDiscount}
          originalPriceAsString={totalBeforeDiscountAsString}
          bottlePacket={isPacket}
        />
      </div>
      <div className="cart-item-edit-wrapper">
        <div className="cart-item-delete" onClick={removeConfirm}>
          <img src={deleteIcon} alt="delete" />
        </div>
        <div
          className="cart-item-edit"
          onClick={() => {
            showCartItemModal();
            setSelectedProduct();
          }}
        >
          <img src={editIcon} alt="edit" />
        </div>
      </div>
    </div>
  );
};

export default CartItem;
