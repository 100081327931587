import { AnyAction } from 'redux';
import { GET_CHANNEL_SUCCESS } from 'redux/types/GeneralTypes';

const GetChannelDataReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case GET_CHANNEL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export { GetChannelDataReducer };
