import { getEnv } from 'services/EnvService';
import { sortObjectByKey } from './Utils';

const aquaImage = (url, filter = 'FILTER_ORIGINAL') => {
  return `${getEnv('GCS_API_URL')}${getEnv(`${filter}`)}${url}`;
};

const resolvePrimaryImagePath = (imageArray) => {
  return imageArray[0].path;
};

export { aquaImage, resolvePrimaryImagePath };
