import { AnyAction } from 'redux';
import {
  ADDRESS_MODAL,
  CONTACT_INFO,
  CONTACT_INFO_MODAL,
  FETCH_ADDRESS_LIST_SUCCESS,
  LOCAL_ADDRESS,
  SELECT_ADDRESS_MODAL,
  SELECTED_ADDRESS_REQUEST,
  SELECTED_ADDRESS_SUCCESS,
} from 'redux/types/AddressTypes';

const AddressListReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_ADDRESS_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const SelectedAddressReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case SELECTED_ADDRESS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const SelectAddressModalReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case SELECT_ADDRESS_MODAL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const ContactInfoModalReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case CONTACT_INFO_MODAL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const ContactInfoReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case CONTACT_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const LocalAddressReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case LOCAL_ADDRESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const AddressModalReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case ADDRESS_MODAL:
      return { ...action.payload };
    default:
      return { ...state };
  }
};

export {
  AddressListReducer,
  SelectedAddressReducer,
  LocalAddressReducer,
  AddressModalReducer,
  SelectAddressModalReducer,
  ContactInfoModalReducer,
  ContactInfoReducer,
};
