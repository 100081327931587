import 'styles/pages/Auth.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Alerts from 'components/Alerts';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getAuthState } from 'redux/src/StatesGetter';
import { useNavigate } from 'react-router-dom';
import { getEnv } from 'services/EnvService';
import { requestLogin } from 'redux/actions/AuthActions';
import forgotPassIcon from 'assets/images/forgot-pass-icon.svg';
import showPassIcon from 'assets/images/show-password.svg';
import hidePassIcon from 'assets/images/hide-password.svg';
import SocialLogin from 'components/SocialLogin';

const Login = () => {
  const { t, i18n } = useTranslation();

  const [selectedLocale, setSelectedLocale] = useState(getEnv('LOCALE'));
  const [iAgree, setIAgree] = useState(false);
  const [iAgree2, setIAgree2] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);

  const dispatchLogin = (type, data) => {
    dispatch(requestLogin({ type, data }));
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const onLogin = (e) => {
    e.preventDefault();
    if (!email || !password) {
      Alerts.warningAlert(t('allFieldsMandatory'));
      // return;
    }
    dispatchLogin('classic', { email, password });
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  return (
    <section className="login">
      <form onSubmit={onLogin}>
        <div className="title-section">
          <h1>{t('authentication')}</h1>
        </div>
        <SocialLogin />
        <div className="form-input">
          <input
            type="email"
            name="email"
            id="email"
            className="form-control"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder=" "
          />
          <label htmlFor="email">{t('email')}</label>
        </div>
        <div className="password-eye">
          <input
            value={password}
            name="password"
            id="password"
            onChange={(event) => setPassword(event.target.value)}
            type={passwordShown ? 'text' : 'password'}
            className="form-control"
            placeholder=" "
          />
          <label htmlFor="password">{t('password')}</label>
          <div className="eye" onClick={togglePasswordVisibility}>
            <img src={passwordShown ? hidePassIcon : showPassIcon} alt="show-password" />
          </div>
        </div>
        <button type="submit" className="green-button">
          {t('login')}
        </button>
        <div onClick={() => navigate('/forgot-password')} className="forgot-pass-btn">
          <img src={forgotPassIcon} width="16" height="16" alt="forgot-password" />
          {t('forgotPassword')}
        </div>
        <button type="button" onClick={() => navigate('/register')} className="white-button">
          {`${t('createNewAccount')}`}
          <img src="pages/auth/Login" alt="" />
        </button>
      </form>
    </section>
  );
};

export default Login;
