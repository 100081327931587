let navigationRef;

const curryingNavigation = (newRef = null) => {
  if (!navigationRef) {
    navigationRef = newRef;
  }

  return (routeName = '', routeProps = {}) => {
    if (routeName.length) {
      navigationRef(routeName, routeProps);
    }

    return (goBackwards = 1) => {
      navigationRef(-goBackwards);
    };
  };
};

const setNavHistoryRef = (navRef) => {
  curryingNavigation(navRef);
};

const navigate = (routeName: string, routeProps = {}): void => {
  curryingNavigation()(routeName, routeProps);
};

const goBack = (times = 1): void => {
  curryingNavigation()()(times);
};

export default {
  setNavHistoryRef,
  navigate,
  goBack,
};
