import { useTranslation } from 'react-i18next';
import 'styles/components/CartCheckoutBlock.scss';
import Order from 'types/Order';

const CartCheckoutBlock = ({ checkout, cart }) => {
  const { t, i18n } = useTranslation();
  const { itemsTotalAsString, shippingTotal, shippingTotalAsString, totalAsString }: Order = cart;

  return (
    <div className="cart-checkout-block mt-3">
      <div className="checkout-product-price">
        <span>{t('cart.product')}</span>
        <span className="price-bold">{itemsTotalAsString}</span>
      </div>
      <div className="checkout-delivery-fee-price">
        <span>{t('cart.deliveryFee')}</span>
        <span className="price-bold">
          {shippingTotal === 0 ? t('cart.freeOfCharge') : shippingTotalAsString}
        </span>
      </div>
      <div className="checkout-total-price">
        <span>{t('cart.total')}</span>
        <span>{totalAsString}</span>
      </div>
      <button
        type="button"
        className={`green-button checkout ${!cart.shippingAddress ? 'disabled' : ''}`}
        onClick={checkout}
      >
        {t('cart.placeOrder')}
      </button>
    </div>
  );
};

export default CartCheckoutBlock;
