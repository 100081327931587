import { AnyAction } from 'redux';
import {
  FETCH_ORDER_DETAILS_REQUEST,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_ERROR,
  CHANGE_ORDER_ITEM_QUANTITY_REQUEST,
  CHANGE_ORDER_ITEM_QUANTITY_SUCCESS,
  CHANGE_ORDER_ITEM_QUANTITY_ERROR,
  REMOVE_ORDER_ITEM_REQUEST,
  REMOVE_ORDER_ITEM_SUCCESS,
  REMOVE_ORDER_ITEM_ERROR,
  ADD_ORDER_ITEM_REQUEST,
  ADD_ORDER_ITEM_SUCCESS,
  ADD_ORDER_ITEM_ERROR,
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  FETCH_CART_ERROR,
  CREATE_CART_REQUEST,
  CREATE_CART_SUCCESS,
  CREATE_CART_ERROR,
  COMPLETE_ORDER_ON_CHECKOUT_REQUEST,
  COMPLETE_ORDER_ON_CHECKOUT_SUCCESS,
  COMPLETE_ORDER_ON_CHECKOUT_ERROR,
  SET_ORDER_ADDRESS_REQUEST,
  SET_ORDER_ADDRESS_SUCCESS,
  SET_ORDER_ADDRESS_ERROR,
  FETCH_ORDER_SHIPPING_METHODS_REQUEST,
  FETCH_ORDER_SHIPPING_METHODS_SUCCESS,
  FETCH_ORDER_SHIPPING_METHODS_ERROR,
  FETCH_ORDER_PAYMENT_METHODS_REQUEST,
  FETCH_ORDER_PAYMENT_METHODS_SUCCESS,
  FETCH_ORDER_PAYMENT_METHODS_ERROR,
  SET_ORDER_PAYMENT_METHOD_REQUEST,
  SET_ORDER_PAYMENT_METHOD_SUCCESS,
  SET_ORDER_PAYMENT_METHOD_ERROR,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  DELETE_CART_ERROR,
  CREATE_CART_FROM_ORDER_REQUEST,
  CREATE_CART_FROM_ORDER_SUCCESS,
  CREATE_CART_FROM_ORDER_ERROR,
  SET_ORDER_SHIPPING_METHOD,
  INACTIVE_ORDERS_FETCH_ERROR,
  ACTIVE_ORDERS_FETCH_SUCCESS,
  INACTIVE_ORDERS_FETCH_SUCCESS,
  ACTIVE_ORDERS_FETCH_REQUEST,
  ACTIVE_ORDERS_FETCH_ERROR,
  INACTIVE_ORDERS_FETCH_REQUEST,
  FETCH_LAST_ORDER_REQUEST,
  FETCH_LAST_ORDER_SUCCESS,
  FETCH_LAST_ORDER_ERROR,
  COMPANY_INVOICE_TYPE,
  REVIEW_MODAL,
  ADD_REVIEW_SUCCESS,
  ADD_REVIEW_ERROR,
  ADD_REVIEW_REQUEST,
  REVIEW_LATER,
  TRIGGER_REVIEW_ERROR,
  TRIGGER_REVIEW_SUCCESS,
  TRIGGER_REVIEW_REQUEST,
} from 'redux/types/OrderTypes';

export const activeOrdersFetchRequest = (payload): AnyAction => ({
  type: ACTIVE_ORDERS_FETCH_REQUEST,
  payload,
});

export const activeOrdersFetchSuccess = (payload): AnyAction => ({
  type: ACTIVE_ORDERS_FETCH_SUCCESS,
  payload,
});

export const activeOrdersFetchError = (payload): AnyAction => ({
  type: ACTIVE_ORDERS_FETCH_ERROR,
  payload,
});

export const inactiveOrdersFetchRequest = (payload): AnyAction => ({
  type: INACTIVE_ORDERS_FETCH_REQUEST,
  payload,
});

export const inactiveOrdersFetchSuccess = (payload): AnyAction => ({
  type: INACTIVE_ORDERS_FETCH_SUCCESS,
  payload,
});

export const inactiveOrdersFetchError = (payload): AnyAction => ({
  type: INACTIVE_ORDERS_FETCH_ERROR,
  payload,
});

export const lastOrderFetchRequest = (payload): AnyAction => ({
  type: FETCH_LAST_ORDER_REQUEST,
  payload,
});

export const lastOrderFetchSuccess = (payload): AnyAction => ({
  type: FETCH_LAST_ORDER_SUCCESS,
  payload,
});

export const lastOrderFetchError = (payload): AnyAction => ({
  type: FETCH_LAST_ORDER_ERROR,
  payload,
});

export const fetchOrderDetailsRequest = (payload): AnyAction => ({
  type: FETCH_ORDER_DETAILS_REQUEST,
  payload,
});

export const fetchOrderDetailsSuccess = (payload): AnyAction => ({
  type: FETCH_ORDER_DETAILS_SUCCESS,
  payload,
});

export const fetchOrderDetailsError = (payload): AnyAction => ({
  type: FETCH_ORDER_DETAILS_ERROR,
  payload,
});

export const fetchCartRequest = (payload): AnyAction => ({
  type: FETCH_CART_REQUEST,
  payload,
});

export const fetchCartSuccess = (payload): AnyAction => ({
  type: FETCH_CART_SUCCESS,
  payload,
});

export const fetchCartError = (payload): AnyAction => ({
  type: FETCH_CART_ERROR,
  payload,
});

export const deleteCartRequest = (payload): AnyAction => ({
  type: DELETE_CART_REQUEST,
  payload,
});

export const deleteCartSuccess = (payload): AnyAction => ({
  type: DELETE_CART_SUCCESS,
  payload,
});

export const deleteCartError = (payload): AnyAction => ({
  type: DELETE_CART_ERROR,
  payload,
});

export const createCartRequest = (payload): AnyAction => ({
  type: CREATE_CART_REQUEST,
  payload,
});

export const createCartSuccess = (payload): AnyAction => ({
  type: CREATE_CART_SUCCESS,
  payload,
});

export const createCartError = (payload): AnyAction => ({
  type: CREATE_CART_ERROR,
  payload,
});

export const createCartFromOrderRequest = (payload): AnyAction => ({
  type: CREATE_CART_FROM_ORDER_REQUEST,
  payload,
});

export const createCartFromOrderSuccess = (payload): AnyAction => ({
  type: CREATE_CART_FROM_ORDER_SUCCESS,
  payload,
});

export const createCartFromOrderError = (payload): AnyAction => ({
  type: CREATE_CART_FROM_ORDER_ERROR,
  payload,
});

export const addOrderItemRequest = (payload): AnyAction => ({
  type: ADD_ORDER_ITEM_REQUEST,
  payload,
});

export const addOrderItemSuccess = (payload): AnyAction => ({
  type: ADD_ORDER_ITEM_SUCCESS,
  payload,
});

export const addOrderItemError = (payload): AnyAction => ({
  type: ADD_ORDER_ITEM_ERROR,
  payload,
});

export const changeOrderItemQuantityRequest = (payload): AnyAction => ({
  type: CHANGE_ORDER_ITEM_QUANTITY_REQUEST,
  payload,
});

export const changeOrderItemQuantitySuccess = (payload): AnyAction => ({
  type: CHANGE_ORDER_ITEM_QUANTITY_SUCCESS,
  payload,
});

export const changeOrderItemQuantityError = (payload): AnyAction => ({
  type: CHANGE_ORDER_ITEM_QUANTITY_ERROR,
  payload,
});

export const removeOrderItemRequest = (payload): AnyAction => ({
  type: REMOVE_ORDER_ITEM_REQUEST,
  payload,
});

export const removeOrderItemSuccess = (payload): AnyAction => ({
  type: REMOVE_ORDER_ITEM_SUCCESS,
  payload,
});

export const removeOrderItemError = (payload): AnyAction => ({
  type: REMOVE_ORDER_ITEM_ERROR,
  payload,
});

export const setOrderAddressRequest = (payload): AnyAction => ({
  type: SET_ORDER_ADDRESS_REQUEST,
  payload,
});

export const setOrderAddressSuccess = (payload): AnyAction => ({
  type: SET_ORDER_ADDRESS_SUCCESS,
  payload,
});

export const setOrderAddressError = (payload): AnyAction => ({
  type: SET_ORDER_ADDRESS_ERROR,
  payload,
});

export const setOrderPaymentMethodRequest = (payload): AnyAction => ({
  type: SET_ORDER_PAYMENT_METHOD_REQUEST,
  payload,
});

export const setOrderPaymentMethodSuccess = (payload): AnyAction => ({
  type: SET_ORDER_PAYMENT_METHOD_SUCCESS,
  payload,
});

export const setOrderPaymentMethodError = (payload): AnyAction => ({
  type: SET_ORDER_PAYMENT_METHOD_ERROR,
  payload,
});

export const completeOrderOnCheckoutRequest = (payload): AnyAction => ({
  type: COMPLETE_ORDER_ON_CHECKOUT_REQUEST,
  payload,
});

export const completeOrderOnCheckoutSuccess = (payload): AnyAction => ({
  type: COMPLETE_ORDER_ON_CHECKOUT_SUCCESS,
  payload,
});

export const completeOrderOnCheckoutError = (payload): AnyAction => ({
  type: COMPLETE_ORDER_ON_CHECKOUT_ERROR,
  payload,
});

export const fetchOrderShippingMethodsRequest = (payload): AnyAction => ({
  type: FETCH_ORDER_SHIPPING_METHODS_REQUEST,
  payload,
});

export const fetchOrderShippingMethodsSuccess = (payload): AnyAction => ({
  type: FETCH_ORDER_SHIPPING_METHODS_SUCCESS,
  payload,
});

export const fetchOrderShippingMethodsError = (payload): AnyAction => ({
  type: FETCH_ORDER_SHIPPING_METHODS_ERROR,
  payload,
});

export const setOrderShippingMethod = (payload): AnyAction => ({
  type: SET_ORDER_SHIPPING_METHOD,
  payload,
});

export const fetchOrderPaymentMethodsRequest = (payload): AnyAction => ({
  type: FETCH_ORDER_PAYMENT_METHODS_REQUEST,
  payload,
});

export const fetchOrderPaymentMethodsSuccess = (payload): AnyAction => ({
  type: FETCH_ORDER_PAYMENT_METHODS_SUCCESS,
  payload,
});

export const fetchOrderPaymentMethodsError = (payload): AnyAction => ({
  type: FETCH_ORDER_PAYMENT_METHODS_ERROR,
  payload,
});

export const setCompanyInvoiceType = (payload): AnyAction => ({
  type: COMPANY_INVOICE_TYPE,
  payload,
});

export const setReviewModal = (payload): AnyAction => ({
  type: REVIEW_MODAL,
  payload,
});

export const addReviewRequest = (payload): AnyAction => ({
  type: ADD_REVIEW_REQUEST,
  payload,
});

export const addReviewSuccess = (payload): AnyAction => ({
  type: ADD_REVIEW_SUCCESS,
  payload,
});

export const addReviewError = (payload): AnyAction => ({
  type: ADD_REVIEW_ERROR,
  payload,
});

export const triggerReviewRequest = (payload): AnyAction => ({
  type: TRIGGER_REVIEW_REQUEST,
  payload,
});

export const triggerReviewSuccess = (payload): AnyAction => ({
  type: TRIGGER_REVIEW_SUCCESS,
  payload,
});

export const triggerReviewError = (payload): AnyAction => ({
  type: TRIGGER_REVIEW_ERROR,
  payload,
});
