import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AppRoutes from 'components/AppRoutes';
import { createErrorMessageSelector, createLoadingSelector } from 'redux/src/GenericSelectors';
import { getAuthState, getErrorState, getUserProfileState } from 'redux/src/StatesGetter';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import React, { useEffect } from 'react';
import CustomErrorUtils from 'modules/CustomErrorUtils';
import { useTranslation } from 'react-i18next';
import NavigationService from 'services/NavigationService';
import LoadingSpinner from 'components/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { registerLocalEvents, removeLocalEvents } from 'modules/EventListener';
import { getDeviceRegIdRequest } from 'redux/actions/DeviceActions';
import { handleReviewLater } from 'modules/OrderUtils';
import { setReviewModal } from 'redux/actions/OrderActions';
import { checkReviewLaterHour } from 'modules/DateUtils';
import { getChannelRequest } from 'redux/actions/GeneralActions';
import i18next from 'i18next';
import StorageService from 'services/StorageService';
import { default as StringConstants } from 'constants/StringConstants.json';

function App() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);
  const errorState = useSelector(getErrorState, shallowEqual);
  const profile = useSelector(getUserProfileState, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    NavigationService.setNavHistoryRef(navigate);
    registerLocalEvents();

    dispatch(getDeviceRegIdRequest({}));
    dispatch(getChannelRequest({}));

    (async () => {
      const locale = await StorageService.getData(StringConstants.APP_LANGUAGE, 'ro');
      await i18next.changeLanguage(locale);

      if (isLoggedIn && profile.id) {
        const { data, open } = await handleReviewLater(`/api/ro/v2/shop/customers/${profile.id}`);
        if (open && checkReviewLaterHour(data.timestamp)) {
          dispatch(setReviewModal({ open, data }));
        }
      }
    })();

    return () => {
      removeLocalEvents();
    };
  }, []);

  useEffect(() => {
    const error = createErrorMessageSelector(errorState);
    if (error?.message) {
      CustomErrorUtils.errorHandler(t('error'), error.message);
    }
  }, [errorState]);

  return (
    <>
      <Navigation />
      <AppRoutes isLoggedIn={isLoggedIn} />
      <LoadingSpinner isLoading={isLoading} />
      <Footer />
    </>
  );
}

export default App;
