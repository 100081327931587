import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/CompanyModal.scss';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAuthState, getCompanyModalState, getLocalCompanyState } from 'redux/src/StatesGetter';
import Alerts from 'components/Alerts';
import useCompany from 'modules/CompanyFormHook';
import {
  createCustomerCompanyRequest,
  fetchCompanyDataRequest,
  setCompanyModal,
  setSelectedCompany,
  updateCustomerCompanyRequest,
} from 'redux/actions/CompanyActions';
import { setCompanyInvoiceType } from '../../redux/actions/OrderActions';

const CompanyModal = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const localCompany = useSelector(getLocalCompanyState, shallowEqual);
  const modalState = useSelector(getCompanyModalState, shallowEqual);
  const [save, setSave] = useState(false);

  const companyState = useCompany(localCompany);

  useEffect(() => {
    if (localCompany.id || (localCompany.uniqueRegistrationCode && localCompany.company)) {
      setSave(true);
      return;
    }
    setSave(false);
  }, [localCompany]);

  useEffect(() => {
    if (
      localCompany?.uniqueRegistrationCode === companyState.companyUnRegistrationCode &&
      localCompany?.company !== ''
    ) {
      setSave(true);
    } else {
      setSave(false);
    }
  }, [companyState.companyUnRegistrationCode, localCompany?.uniqueRegistrationCode]);

  const closeCompanyModal = () => {
    dispatch(setCompanyModal({ open: false, setSelected: false }));
    if (modalState?.changeToPhysical) {
      dispatch(setCompanyInvoiceType(false));
    }
  };

  const addCompany = () => {
    if (save) {
      if (
        companyState.companyFirstName === '' ||
        companyState.companyLastName === '' ||
        companyState.companyPhoneNumber === ''
      ) {
        Alerts.warningAlert(t('alerts.companyFormMandatoryFields'));
        return;
      }
      const updateData = {
        company: companyState.companyName,
        uniqueRegistrationCode: companyState.companyUnRegistrationCode,
        commercialRegisterNumber: companyState.companyCommercialRegisterNumber,
        bank: companyState.companyBank,
        bankAccount: companyState.companyBankAccount,
        firstName: companyState.companyFirstName ?? '',
        lastName: companyState.companyLastName ?? '',
        phoneNumber: companyState.companyPhoneNumber ?? '',
        city: companyState.companyCity,
        provinceName: companyState.companyProvince,
        street: companyState.companyStreet,
        postCode: '',
        additionalInfo: companyState.companyAdditionalInfo,
      };
      if (companyState.companyID === '') {
        dispatch(createCustomerCompanyRequest(updateData));
      } else {
        dispatch(updateCustomerCompanyRequest({ id: companyState.companyID, data: updateData }));
      }

      if (modalState.setSelected) {
        dispatch(setSelectedCompany({ ...updateData, id: companyState.companyID }));
      }
      dispatch(setCompanyModal({ open: false, setSelected: false }));
    } else {
      dispatch(fetchCompanyDataRequest({ cui: companyState.companyUnRegistrationCode }));
    }
  };

  return (
    <Modal
      show={modalState?.open}
      onHide={closeCompanyModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={modalState?.changeToPhysical ? 'static' : true}
      className="companyModal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">{t('account.company')}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group-title">
          <span>{t('cart.companyBilling')}</span>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="uniqueRegistrationCode"
            name="uniqueRegistrationCode"
            type="text"
            className="form-control"
            maxLength={9}
            defaultValue={localCompany.uniqueRegistrationCode}
            placeholder=" "
            disabled={save}
            onChange={companyState.handleCompanyUnRegistrationCodeChange}
          />
          <label htmlFor="uniqueRegistrationCode">{t('account.cif')}</label>
        </div>
        {save && (
          <>
            <div className="form-input">
              <input
                autoComplete="off"
                id="companyName"
                name="companyName"
                type="text"
                className="form-control"
                defaultValue={localCompany.company}
                placeholder=" "
                disabled
                onChange={companyState.handleCompanyNameChange}
              />
              <label htmlFor="companyName">{t('account.companyName')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="commercialRegisterNumber"
                name="commercialRegisterNumber"
                type="text"
                className="form-control"
                defaultValue={localCompany.commercialRegisterNumber}
                placeholder=" "
                disabled={localCompany.commercialRegisterNumber !== ''}
                onChange={companyState.handleCompanyCommercialRegisterNumberChange}
              />
              <label htmlFor="commercialRegisterNumber">
                {t('account.commercialRegisterNumber')}
              </label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="bankAccount"
                name="bankAccount"
                type="text"
                className="form-control"
                defaultValue={localCompany.bankAccount}
                placeholder=" "
                onChange={companyState.handleCompanyBankAccountChange}
              />
              <label htmlFor="bankAccount">{t('account.bankAccount')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="bank"
                name="bank"
                type="text"
                className="form-control"
                defaultValue={localCompany.bank}
                placeholder=" "
                onChange={companyState.handleCompanyBankChange}
              />
              <label htmlFor="bank">{t('account.bankName')}</label>
            </div>
            <div className="form-group-title">
              <span>{t('cart.billingAddress')}</span>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="companyCity"
                name="companyCity"
                type="text"
                className="form-control"
                defaultValue={localCompany.city}
                placeholder=" "
                disabled
                onChange={companyState.handleCompanyCityChange}
              />
              <label htmlFor="companyCity">{t('account.city')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="companyProvince"
                name="companyProvince"
                type="text"
                className="form-control"
                defaultValue={localCompany.provinceName}
                placeholder=" "
                disabled
                onChange={companyState.handleCompanyProvinceChange}
              />
              <label htmlFor="companyProvince">{t('account.county')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="companyStreet"
                name="companyStreet"
                type="text"
                className="form-control"
                defaultValue={localCompany.street}
                placeholder=" "
                disabled
                onChange={companyState.handleCompanyStreetChange}
              />
              <label htmlFor="commercialRegisterNumber">{t('account.street')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="additionalInfo"
                name="additionalInfo"
                type="text"
                className="form-control"
                defaultValue={localCompany.additionalInfo}
                placeholder=" "
                onChange={companyState.handleCompanyAdditionalInfoChange}
              />
              <label htmlFor="bankAccount">{t('account.additionalInfo')}</label>
            </div>

            <div className="form-group-title">
              <span>{t('cart.billingContacts')}</span>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="companyFirstName"
                name="firstName"
                type="text"
                className="form-control"
                defaultValue={localCompany.firstName}
                placeholder=" "
                onChange={companyState.handleCompanyFirstNameChange}
              />
              <label htmlFor="firstName">{t('firstName')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="companyLastName"
                name="lastName"
                type="text"
                className="form-control"
                defaultValue={localCompany.lastName}
                placeholder=" "
                onChange={companyState.handleCompanyLastNameChange}
              />
              <label htmlFor="lastName">{t('lastName')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="companyPhone"
                name="phoneNumber"
                type="text"
                className="form-control"
                defaultValue={localCompany.phoneNumber}
                placeholder=" "
                onChange={companyState.handleCompanyPhoneNumberChange}
              />
              <label htmlFor="lastName">{t('phoneNumber')}</label>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div
          onClick={addCompany}
          className={`modal-button ${
            companyState.companyUnRegistrationCode.length < 1 && 'disabled'
          }`}
        >
          <span className="modal-button-text">
            {save ? t('continue') : t('account.retrieveData')}
          </span>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default CompanyModal;
