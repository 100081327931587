import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/AddToCartModal.scss';

import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { aquaImage } from 'modules/ImageUtils';
import PacketPrice from 'components/PacketPrice';
import QuantityChange from 'components/QuantityChange';
import { productPriceObj } from 'modules/ProductUtils';
import { addOrderItemRequest } from 'redux/actions/OrderActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCartState, getGeneralInfoState, getSelectedAddressState } from 'redux/src/StatesGetter';
import { setAddressModal } from 'redux/actions/AddressActions';
import Alerts from 'components/Alerts';
import Order from 'types/Order';
import { resolveAccessoriesOnCart, resolveBottlesOnCart } from 'modules/OrderUtils';

type QuantityObjectType = {
  pvqpId: undefined | number;
  productVariantId: undefined | number;
  image: undefined | string;
  originalPrice: undefined | number;
  price: undefined | number;
  originalPriceAsString: undefined | string;
  priceAsString: undefined | string;
  quantity: undefined | number;
};

const AddToCartModal = ({ showModal, modalClose, item }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { aquaDetails, primaryImage, quantity, code, description, productName, hasBottle } = item;
  const [quantitySelected, setQuantitySelected] = useState(quantity);
  const address = useSelector(getSelectedAddressState, shallowEqual);
  const [emptyAddress, setEmptyAddress] = useState(Object.keys(address).length === 0);

  const [itemsInCart, setItemsInCart] = useState(0);
  const [isAccessory, setIsAccessory] = useState(code !== '11L' && code !== '19L');

  const cart: Order = useSelector(getCartState, shallowEqual);
  const generalInfo = useSelector(getGeneralInfoState, shallowEqual);

  const [quantityObject, setQuantityObject] = useState<QuantityObjectType>({
    pvqpId: undefined,
    productVariantId: undefined,
    image: undefined,
    originalPrice: undefined,
    price: undefined,
    priceAsString: undefined,
    originalPriceAsString: undefined,
    quantity: undefined,
  });

  const addToCart = () => {
    if (emptyAddress) {
      modalClose();
      dispatch(setAddressModal({ open: true, setSelected: true, billing: false }));
      return;
    }

    if (address.delivery.ok === false) {
      Alerts.infoAlert(t('product.selectedAddressNoDelivery'));
      return;
    }

    if (address.delivery.ok && address.delivery.type === 'call') {
      Alerts.warningAlert(`${t('product.onlyPhoneOrders')} ${address.delivery.phoneNumber}`);
      return;
    }

    dispatch(
      addOrderItemRequest({
        productCode: code,
        productVariantCode: code,
        quantity: quantitySelected,
        notes: '',
      }),
    );

    modalClose();
  };

  const setQuantity = (change) => {
    const newQuantity = quantitySelected + change;
    if (itemsInCart + newQuantity > 6 && !isAccessory) {
      Alerts.warningAlert(t('product.maxBottlesReached'));
      return;
    }
    setQuantitySelected(newQuantity);
  };

  useEffect(() => {
    if (aquaDetails && quantitySelected) {
      setQuantityObject(productPriceObj(aquaDetails, quantitySelected));
    }
  }, [quantitySelected, item]);

  useEffect(() => {
    setEmptyAddress(Object.keys(address).length === 0);
  }, [address]);

  useEffect(() => {
    setQuantitySelected(item.quantity);
    setIsAccessory(code !== '11L' && code !== '19L');
  }, [item]);

  useEffect(() => {
    if (!isAccessory) {
      setItemsInCart(resolveBottlesOnCart(cart));
    } else {
      setItemsInCart(resolveAccessoriesOnCart(cart));
    }
  }, [cart]);

  return (
    <Modal
      show={showModal}
      onHide={modalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-to-cart-modal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">{t('amount')}</span>
      </Modal.Header>
      <Modal.Body>
        <span className="product-name">{productName}</span>
        <img className="product-image" src={primaryImage} alt={productName} />
        <QuantityChange
          quantitySelected={quantitySelected}
          itemsInCart={itemsInCart}
          setQuantity={setQuantity}
          accessory={isAccessory}
          maxQuantity={generalInfo.maxQuantity}
        />
        <div className="selected-packet-preview">
          <img className="smallImage" src={quantityObject.image} alt={productName} />
          <div>
            <PacketPrice
              quantity={quantitySelected}
              productName={productName}
              code={code}
              priceAsString={quantityObject.priceAsString}
              price={quantityObject.price}
              originalPrice={quantityObject.originalPrice}
              originalPriceAsString={quantityObject.originalPriceAsString}
              bottlePacket={hasBottle}
            />
          </div>
        </div>
        {description && (
          <div className="selected-packet-description">
            <span className="description-title">{t('description')}</span>
            <span className="description-text">{description}</span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div onClick={addToCart} className="modal-button">
          <span className="modal-button-text">{`${t('addToCart')} ·`}</span>
          <span className="modal-button-price">{quantityObject.priceAsString}</span>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddToCartModal;
