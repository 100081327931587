import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alerts from 'components/Alerts';
import { useParams } from 'react-router-dom';
import warningIcon from 'assets/images/warning-icon.svg';
import { verifyAccountRequest } from 'redux/actions/AuthActions';

const AccountActivation = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { token } = useParams();
  const [activationToken, setActivationToken] = useState(token);

  const onPress = (e) => {
    e.preventDefault();
    if (!activationToken) {
      Alerts.errorAlert(t('resetPasswordError'));
      return;
    }

    dispatch(verifyAccountRequest({ token: activationToken }));
  };

  useEffect(() => {
    setActivationToken(token);

    dispatch(verifyAccountRequest({ token }));
  }, [token]);

  return (
    <section className="forgot-password">
      <form onSubmit={onPress}>
        <div className="title-section">
          <h1>{t('activationAccount')}</h1>
        </div>
        <div className="info-message">
          <img src={warningIcon} alt="warning-icon" />
          {t('tokenText')}
        </div>
        <div className="form-input">
          <input
            type="text"
            id="activationToken"
            name="activationToken"
            className="form-control"
            value={activationToken}
            onChange={(event) => setActivationToken(event.target.value)}
            placeholder=" "
          />
          <label htmlFor="token">{t('token')}</label>
        </div>
        <button type="submit" className="green-button">
          {t('activate')}
        </button>
      </form>
    </section>
  );
};

export default AccountActivation;
