import StorageService from 'services/StorageService';
import { default as StringConstants } from 'constants/StringConstants.json';
// import firebase from 'firebase';

export const loginCheckPoint = async () => {
  const userToken = await StorageService.getData(StringConstants.USER_TOKEN, null);
  const isProfileCompleted = await StorageService.getData(
    StringConstants.IS_PROFILE_COMPLETED,
    'true',
  );
  const isConfirmed = await StorageService.getData(StringConstants.IS_CONFIRMED, 'true');
  if (userToken) {
    // const deviceRegId = await firebase.getWebToken();
    return {
      action: 'login',
      // deviceRegId,
      isProfileCompleted: isProfileCompleted.indexOf('Not') === -1,
      isConfirmed: isConfirmed.indexOf('Not') === -1,
    };
  }
  return { action: 'logOut', deviceRegId: null, isProfileCompleted: true, isConfirmed: true };
};

export const isLoggedIn = async () => {
  const userToken = await StorageService.getData(StringConstants.USER_TOKEN, '');
  return userToken?.length;
};

export const handleLoginResponse = async (data) => {
  await StorageService.setData(StringConstants.USER_TOKEN, data.token);
  await StorageService.setData(StringConstants.REFRESH_TOKEN, data.refresh_token);
};

export const logOutCleanUp = () => {
  const keys = ['REFRESH_TOKEN', 'USER_TOKEN'];
  keys.forEach(async (key) => {
    await StorageService.removeItem(key);
  });
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
