import { AnyAction } from 'redux';
import {
  COMPANY_MODAL,
  CREATE_CUSTOMER_COMPANY_ERROR,
  CREATE_CUSTOMER_COMPANY_REQUEST,
  CREATE_CUSTOMER_COMPANY_SUCCESS,
  DELETE_CUSTOMER_COMPANY_ERROR,
  DELETE_CUSTOMER_COMPANY_REQUEST,
  DELETE_CUSTOMER_COMPANY_SUCCESS,
  FETCH_COMPANY_DATA_ERROR,
  FETCH_COMPANY_DATA_REQUEST,
  FETCH_COMPANY_DATA_SUCCESS,
  FETCH_CUSTOMER_COMPANIES_ERROR,
  FETCH_CUSTOMER_COMPANIES_REQUEST,
  FETCH_CUSTOMER_COMPANIES_SUCCESS,
  GET_CUSTOMER_COMPANY_ERROR,
  GET_CUSTOMER_COMPANY_REQUEST,
  GET_CUSTOMER_COMPANY_SUCCESS,
  SELECT_COMPANY_MODAL,
  SET_LOCAL_COMPANY,
  SET_SELECTED_COMPANY,
  UPDATE_CUSTOMER_COMPANY_ERROR,
  UPDATE_CUSTOMER_COMPANY_REQUEST,
  UPDATE_CUSTOMER_COMPANY_SUCCESS,
} from 'redux/types/CompanyTypes';

export const fetchCustomerCompaniesRequest = (payload): AnyAction => ({
  type: FETCH_CUSTOMER_COMPANIES_REQUEST,
  payload,
});

export const fetchCustomerCompaniesSuccess = (payload): AnyAction => ({
  type: FETCH_CUSTOMER_COMPANIES_SUCCESS,
  payload,
});

export const fetchCustomerCompaniesError = (payload): AnyAction => ({
  type: FETCH_CUSTOMER_COMPANIES_ERROR,
  payload,
});

export const getCustomerCompanyRequest = (payload): AnyAction => ({
  type: GET_CUSTOMER_COMPANY_REQUEST,
  payload,
});

export const getCustomerCompanySuccess = (payload): AnyAction => ({
  type: GET_CUSTOMER_COMPANY_SUCCESS,
  payload,
});

export const getCustomerCompanyError = (payload): AnyAction => ({
  type: GET_CUSTOMER_COMPANY_ERROR,
  payload,
});

export const updateCustomerCompanyRequest = (payload): AnyAction => ({
  type: UPDATE_CUSTOMER_COMPANY_REQUEST,
  payload,
});

export const updateCustomerCompanySuccess = (payload): AnyAction => ({
  type: UPDATE_CUSTOMER_COMPANY_SUCCESS,
  payload,
});

export const updateCustomerCompanyError = (payload): AnyAction => ({
  type: UPDATE_CUSTOMER_COMPANY_ERROR,
  payload,
});

export const deleteCustomerCompanyRequest = (payload): AnyAction => ({
  type: DELETE_CUSTOMER_COMPANY_REQUEST,
  payload,
});

export const deleteCustomerCompanySuccess = (payload): AnyAction => ({
  type: DELETE_CUSTOMER_COMPANY_SUCCESS,
  payload,
});

export const deleteCustomerCompanyError = (payload): AnyAction => ({
  type: DELETE_CUSTOMER_COMPANY_ERROR,
  payload,
});

export const createCustomerCompanyRequest = (payload): AnyAction => ({
  type: CREATE_CUSTOMER_COMPANY_REQUEST,
  payload,
});

export const createCustomerCompanySuccess = (payload): AnyAction => ({
  type: CREATE_CUSTOMER_COMPANY_SUCCESS,
  payload,
});

export const createCustomerCompanyError = (payload): AnyAction => ({
  type: CREATE_CUSTOMER_COMPANY_ERROR,
  payload,
});

export const fetchCompanyDataRequest = (payload): AnyAction => ({
  type: FETCH_COMPANY_DATA_REQUEST,
  payload,
});

export const fetchCompanyDataSuccess = (payload): AnyAction => ({
  type: FETCH_COMPANY_DATA_SUCCESS,
  payload,
});

export const fetchCompanyDataError = (payload): AnyAction => ({
  type: FETCH_COMPANY_DATA_ERROR,
  payload,
});

export const setLocalCompany = (payload): AnyAction => ({
  type: SET_LOCAL_COMPANY,
  payload,
});

export const setSelectedCompany = (payload): AnyAction => ({
  type: SET_SELECTED_COMPANY,
  payload,
});

export const setCompanyModal = (payload): AnyAction => ({
  type: COMPANY_MODAL,
  payload,
});

export const setSelectedCompanyModal = (payload): AnyAction => ({
  type: SELECT_COMPANY_MODAL,
  payload,
});
