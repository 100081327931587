import { AnyAction } from 'redux';
import {
  ALL_PRODUCTS_FETCH_REQUEST,
  ALL_PRODUCTS_FETCH_ERROR,
  ALL_PRODUCTS_FETCH_SUCCESS,
  PRODUCT_FETCH_REQUEST,
  PRODUCT_FETCH_ERROR,
  PRODUCT_FETCH_SUCCESS,
  FETCH_ACCESSORY_PRODUCTS_REQUEST,
  FETCH_ACCESSORY_PRODUCTS_SUCCESS,
  FETCH_ACCESSORY_PRODUCTS_ERROR,
} from 'redux/types/ProductTypes';

export const allProductsFetchRequest = (payload): AnyAction => ({
  type: ALL_PRODUCTS_FETCH_REQUEST,
  payload,
});

export const allProductsFetchSuccess = (payload): AnyAction => ({
  type: ALL_PRODUCTS_FETCH_SUCCESS,
  payload,
});

export const allProductsFetchError = (payload): AnyAction => ({
  type: ALL_PRODUCTS_FETCH_ERROR,
  payload,
});

export const productsFetchRequest = (payload): AnyAction => ({
  type: PRODUCT_FETCH_REQUEST,
  payload,
});

export const productFetchSuccess = (payload): AnyAction => ({
  type: PRODUCT_FETCH_SUCCESS,
  payload,
});

export const productFetchError = (payload): AnyAction => ({
  type: PRODUCT_FETCH_ERROR,
  payload,
});

export const fetchAccessoryProductsRequest = (payload): AnyAction => ({
  type: FETCH_ACCESSORY_PRODUCTS_REQUEST,
  payload,
});

export const fetchAccessoryProductsSuccess = (payload): AnyAction => ({
  type: FETCH_ACCESSORY_PRODUCTS_SUCCESS,
  payload,
});

export const fetchAccessoryProductsError = (payload): AnyAction => ({
  type: FETCH_ACCESSORY_PRODUCTS_ERROR,
  payload,
});
