import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import Colors from 'themes/Colors';
// import CustomIcon from './CustomIcon';
import successIcon from 'assets/images/success-icon.svg';
import errorIcon from 'assets/images/error-icon.svg';
import warningIcon from 'assets/images/alert-warning-icon.svg';
import infoIcon from 'assets/images/info-icon.svg';
import i18next from 'i18next';

const simpleAlert = (title = '', body: any = '') => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button type="button" onClick={onClose}>
                {`${i18next.t('ok')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const okCancelAlert = (title = '', body = '', okayAction = () => {}, cancelAction = () => {}) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button
                className="cancel-button"
                type="button"
                onClick={() => {
                  cancelAction();
                  onClose();
                }}
              >
                {`${i18next.t('cancel')}`}
              </button>
              <button
                type="button"
                onClick={() => {
                  okayAction();
                  onClose();
                }}
                autoFocus
              >
                {`${i18next.t('ok')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const actionAlert = (
  title = '',
  body = '',
  buttonText = `${i18next.t('ok')}`,
  action = () => {},
) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button
                type="button"
                onClick={() => {
                  action();
                  onClose();
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const successAlert = (body = '', buttonText = `${i18next.t('close')}`, action = () => {}) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal alert-wrapper">
          <img src={successIcon} alt="success" />
          <div className="modal-body">
            <p>{body}</p>
            <div className="actions">
              <button
                type="button"
                onClick={() => {
                  action();
                  onClose();
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const errorAlert = (body = '', buttonText = `${i18next.t('close')}`, action = () => {}) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal alert-wrapper">
          <div className="alert-img-wrapper error">
            <img src={errorIcon} alt="error" />
          </div>
          <div className="modal-body">
            <div className="alert-top-message">{`${i18next.t('alerts.thereWasAnError')}`}</div>
            <p>{body}</p>
            <div className="actions">
              <button
                type="button"
                onClick={() => {
                  action();
                  onClose();
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const warningAlert = (body = '', buttonText = `${i18next.t('close')}`, action = () => {}) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal alert-wrapper">
          <div className="alert-img-wrapper warning">
            <img src={warningIcon} alt="warning" />
          </div>
          <div className="modal-body">
            <div className="alert-top-message">{`${i18next.t('warning')}`}</div>
            <p>{body}</p>
            <div className="actions">
              <button
                type="button"
                onClick={() => {
                  action();
                  onClose();
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const infoAlert = (body = '', buttonText = `${i18next.t('close')}`, action = () => {}) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal alert-wrapper">
          <div className="alert-img-wrapper info">
            <img src={infoIcon} alt="info" />
          </div>
          <div className="modal-body">
            <div className="alert-top-message">{`${i18next.t('info')}`}</div>
            <p>{body}</p>
            <div className="actions">
              <button
                type="button"
                onClick={() => {
                  action();
                  onClose();
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const twoActionsAlert = (
  title = '',
  body = '',
  firstButtonText = '',
  firstButtonAction = () => {},
  secondButtonText = '',
  secondButtonAction = () => {},
) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button
                type="button"
                onClick={() => {
                  firstButtonAction();
                  onClose();
                }}
              >
                {firstButtonText}
              </button>
              <button
                type="button"
                onClick={() => {
                  secondButtonAction();
                  onClose();
                }}
              >
                {secondButtonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const threeActionsAlert = (
  title = '',
  body = '',
  firstButtonText = '',
  firstButtonAction = () => {},
  secondButtonText = '',
  secondButtonAction = () => {},
  thirdButtonText = '',
  thirdButtonAction = () => {},
) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button
                type="button"
                onClick={() => {
                  firstButtonAction();
                  onClose();
                }}
              >
                {firstButtonText}
              </button>
              <button
                type="button"
                onClick={() => {
                  secondButtonAction();
                  onClose();
                }}
              >
                {secondButtonText}
              </button>
              <button
                className="cancel-button"
                type="button"
                onClick={() => {
                  thirdButtonAction();
                  onClose();
                }}
              >
                {thirdButtonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

export default {
  simpleAlert,
  okCancelAlert,
  actionAlert,
  successAlert,
  errorAlert,
  warningAlert,
  infoAlert,
  twoActionsAlert,
  threeActionsAlert,
};
