import 'styles/components/InputsHelper.scss';
import 'styles/components/modals/CartContactInfoModal.scss';
import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getAuthState,
  getCartState,
  getContactInfoModalState,
  getContactInfoState,
} from 'redux/src/StatesGetter';
import { setOrderAddressRequest } from 'redux/actions/OrderActions';
import { setContactInfo, setContactInfoModal } from 'redux/actions/AddressActions';

import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import labels from 'react-phone-number-input/locale/en.json';

import { calcPhone, calcPrefix } from 'types/Prefix';
import dropIcon from 'assets/images/dropdown-arrow-icon.svg';
import Alerts from 'components/Alerts';
import { isValidPhoneNumber } from 'react-phone-number-input';

const CartContactInfoModal = () => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const cart = useSelector(getCartState, shallowEqual);
  const { billingAddress, shippingAddress } = cart;
  const modalState = useSelector(getContactInfoModalState, shallowEqual);
  const contactInfo = useSelector(getContactInfoState, shallowEqual);
  const dispatch = useDispatch();

  const [cartContactFirstName, setCartContactFirstName] = useState(contactInfo?.firstName ?? '');
  const [cartContactLastName, setCartContactLastName] = useState(contactInfo?.lastName ?? '');
  const [cartContactPhone, setCartContactPhone] = useState('');
  const [cartContactPrefix, setCartContactPrefix] = useState('');

  const handleCartContactFirstNameChange = (e) => setCartContactFirstName(e.target.value.trim());
  const handleCartContactLastNameChange = (e) => setCartContactLastName(e.target.value.trim());
  const handleCartContactPhone = (e) => setCartContactPhone(e.target.value.trim());
  const handleCartContactPrefix = (e) => setCartContactPrefix(e.target.value.trim());

  const closeContactInfoModal = () => {
    dispatch(setContactInfoModal({ open: false, billing: false }));
  };

  useEffect(() => {
    setCartContactFirstName(contactInfo?.firstName ?? '');
    setCartContactLastName(contactInfo?.lastName ?? '');
    setCartContactPhone(calcPhone(contactInfo?.phoneNumber));
    setCartContactPrefix(calcPrefix(contactInfo?.phoneNumber));
  }, [contactInfo]);

  const updateAddress = () => {
    if (!isValidPhoneNumber(`${cartContactPrefix}${cartContactPhone}`)) {
      Alerts.warningAlert(t('alerts.invalidPhoneNumber'));
      return;
    }

    dispatch(
      setOrderAddressRequest({
        toUpdate: {
          email: '',
          shippingAddress: {
            ...shippingAddress,
            ...(!modalState.billing && {
              firstName: cartContactFirstName,
              lastName: cartContactLastName,
              phoneNumber: `${cartContactPrefix}${cartContactPhone}`,
            }),
          },
          billingAddress: {
            ...billingAddress,
            ...(modalState.billing && {
              firstName: cartContactFirstName,
              lastName: cartContactLastName,
              phoneNumber: `${cartContactPrefix}${cartContactPhone}`,
            }),
          },
        },
      }),
    );
    closeContactInfoModal();
  };

  return (
    <Modal
      show={modalState.open}
      onHide={closeContactInfoModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="change-contact-info-modal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">
          {modalState.billing ? t('cart.billingContacts') : t('cart.deliveryContacts')}
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="form-input">
          <input
            autoComplete="off"
            id="firstName"
            name="firstName"
            type="text"
            className="form-control"
            defaultValue={contactInfo.firstName}
            placeholder=" "
            onChange={handleCartContactFirstNameChange}
          />
          <label htmlFor="firstName">{t('firstName')}</label>
        </div>

        <div className="form-input">
          <input
            autoComplete="off"
            id="lastName"
            name="lastName"
            type="text"
            className="form-control"
            defaultValue={contactInfo.lastName}
            placeholder=" "
            onChange={handleCartContactLastNameChange}
          />
          <label htmlFor="lastName">{t('lastName')}</label>
        </div>
        <div className="telephone-wrapper">
          <div className="form-input prefix-wrapper">
            <select
              autoComplete="off"
              id="prefix"
              name="prefix"
              className="form-control"
              placeholder=" "
              onChange={handleCartContactPrefix}
              defaultValue={calcPrefix(contactInfo?.phoneNumber) ?? '+40'}
            >
              {getCountries().map((country) => (
                <option key={country} value={`+${getCountryCallingCode(country)}`}>
                  {labels[country]} +{getCountryCallingCode(country)}
                </option>
              ))}
            </select>
            <label htmlFor="prefix">{t('choosePrefix')}</label>
            <div className="countryPrefix">
              <span>{cartContactPrefix}</span>
              <img src={dropIcon} alt="dropIcon" />
            </div>
          </div>
          <div className="form-input">
            <input
              autoComplete="off"
              id="telephone"
              name="telephone"
              type="text"
              className="form-control"
              placeholder=" "
              onChange={handleCartContactPhone}
              defaultValue={calcPhone(contactInfo?.phoneNumber)}
            />
            <label htmlFor="telephone">{t('phoneNumber')}</label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="green-button" onClick={updateAddress}>
          {t('saveChanges')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CartContactInfoModal;
