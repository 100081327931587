import { actionChannel, ActionPattern, call, fork, put, select, take } from 'redux-saga/effects';
import { Action } from 'redux';
import {
  ADD_ADDRESS_REQUEST,
  DELETE_ADDRESS_REQUEST,
  FETCH_ADDRESS_LIST_REQUEST,
  SELECTED_ADDRESS_REQUEST,
  UPDATE_ADDRESS_REQUEST,
} from 'redux/types/AddressTypes';
import {
  addAddressError,
  deleteAddressError,
  fetchAddressError,
  fetchAddressRequest,
  fetchAddressSuccess,
  setSelectedAddress,
  setSelectedAddressSuccess,
  updateAddressError,
} from 'redux/actions/AddressActions';
import {
  createAddressAPI,
  deleteAddressByIdAPI,
  deliveryZoneCheckAPI,
  getAddressesAPI,
  updateAddressByIdAPI,
} from 'redux/api/AddressApi';
import { addressesSelector } from 'redux/selector/AddressSelector';
import Alerts from 'components/Alerts';
import { getCartState } from 'redux/src/StatesGetter';
import { setOrderAddressRequest } from 'redux/actions/OrderActions';
import i18next from 'i18next';

function* getAllAddresses(actionType: ActionPattern<Action<any>>) {
  const getAddressesChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getAddressesChannel);
    try {
      const response = yield call(getAddressesAPI);
      yield put(fetchAddressSuccess([...addressesSelector(response.data)]));
    } catch ({ message }) {
      yield put(fetchAddressError({ message }));
    }
  }
}

function* addAddress(actionType: ActionPattern<Action<any>>) {
  const addAddressesChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addAddressesChannel);
    try {
      const { newAddress, updateSelected, shippingAddress, billingAddress } = payload;
      const response = yield call(createAddressAPI, newAddress);
      Alerts.successAlert(`${i18next.t('alerts.addressAdded')}`);
      yield put(setSelectedAddress({ shippingAddress, billingAddress, selected: updateSelected }));
      yield put(fetchAddressRequest({}));
    } catch ({ message }) {
      Alerts.errorAlert(`${i18next.t('alerts.errorAddAddress')}`);
      yield put(addAddressError({ message }));
    }
  }
}

function* updateAddress(actionType: ActionPattern<Action<any>>) {
  const updateAddressesChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateAddressesChannel);
    try {
      const { updatedAddress, shippingAddress, billingAddress, updateSelected } = payload;
      const { id } = updatedAddress;
      const response = yield call(updateAddressByIdAPI, { id, payload: updatedAddress });
      Alerts.successAlert(`${i18next.t('alerts.addressUpdated')}`);
      yield put(setSelectedAddress({ shippingAddress, billingAddress, selected: updateSelected }));
      yield put(fetchAddressRequest({}));
    } catch ({ message }) {
      Alerts.errorAlert(`${i18next.t('alerts.errorUpdateAddress')}`);
      yield put(updateAddressError({ message }));
    }
  }
}

function* selectAddress(actionType: ActionPattern<Action<any>>) {
  const selectAddressChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(selectAddressChannel);
    try {
      const { shippingAddress, billingAddress, selected } = payload;
      const { id, tokenValue } = yield select(getCartState);
      let response;
      if (selected) {
        response = yield call(deliveryZoneCheckAPI, { ...shippingAddress, id: undefined });
        if (id && !response.data.ok) {
          Alerts.infoAlert(i18next.t('product.selectedAddressNoDelivery'));
          yield put(setSelectedAddressSuccess({ delivery: {} }));
        } else {
          yield put(setSelectedAddressSuccess({ ...shippingAddress, delivery: response.data }));
        }
      }
      if (id && (!selected || response.data.ok)) {
        yield put(
          setOrderAddressRequest({
            tokenValue,
            toUpdate: {
              email: '',
              shippingAddress: { ...shippingAddress },
              billingAddress: { ...billingAddress },
            },
          }),
        );
      }
    } catch ({ message }) {
      yield put(setSelectedAddressSuccess({ delivery: {} }));
      // yield put(setSelectedAddressError({ message }));
    }
  }
}

function* deleteAddress(actionType: ActionPattern<Action<any>>) {
  const deleteAddressChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deleteAddressChannel);
    try {
      const { id } = payload;
      yield call(deleteAddressByIdAPI, { id });
      Alerts.successAlert(`${i18next.t('alerts.addressDeleted')}`);
      yield put(fetchAddressRequest({}));
    } catch ({ message }) {
      Alerts.errorAlert(`${i18next.t('alerts.errorDeleteAddress')}`);
      yield put(deleteAddressError({ message }));
    }
  }
}

function* addressSaga() {
  yield fork(updateAddress, UPDATE_ADDRESS_REQUEST);
  yield fork(getAllAddresses, FETCH_ADDRESS_LIST_REQUEST);
  yield fork(addAddress, ADD_ADDRESS_REQUEST);
  yield fork(deleteAddress, DELETE_ADDRESS_REQUEST);
  yield fork(selectAddress, SELECTED_ADDRESS_REQUEST);
}

export default addressSaga;
