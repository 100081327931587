import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import PrivateRoute from 'components/PrivateRoute';
import MainPage from 'pages/MainPage';
import ProfilePage from 'pages/ProfilePage';
import ResetPassword from 'pages/auth/ResetPassword';
import Register from 'pages/auth/Register';
import Login from 'pages/auth/Login';
import ForgotPassword from 'pages/auth/ForgotPassword';
import AccountActivation from 'pages/auth/AccountActivation';
import ContactUsPage from 'pages/support/ContactUsPage';
import CartPage from 'pages/CartPage';
import OrderHistoryPage from 'pages/OrderHistoryPage';
import ProductPage from 'pages/ProductPage';
import SuccessPage from 'pages/SuccessPage';
import OrderPage from 'pages/OrderPage';
import TermsPage from 'pages/support/TermsPage';
import AboutDeliveryPage from 'pages/support/AboutDeliveryPage';
import PrivacyPolicyPage from 'pages/support/PrivacyPolicyPage';
import ReturnPolicyPage from 'pages/support/ReturnPolicyPage';
import ANPCPage from '../pages/support/ANPCPage';

const AuthRoutes = [
  {
    path: 'login',
    key: 'ROOT_LOGIN',
    exact: true,
    component: <Login />,
  },
  {
    path: 'activate-account/:token',
    key: 'ROOT_ACTIVATE_ACCOUNT',
    exact: true,
    component: <AccountActivation />,
  },
  {
    path: 'register',
    key: 'ROOT_REGISTER',
    exact: true,
    component: <Register />,
  },
  {
    path: 'forgot-password',
    key: 'ROOT_FORGOT_PASSWORD',
    exact: true,
    component: <ForgotPassword />,
  },
  {
    path: 'reset-password/:token',
    key: 'ROOT_RESET_PASSWORD',
    exact: true,
    component: <ResetPassword />,
  },
];

const GeneralRoutes = [
  {
    path: '/about-delivery',
    key: 'ROOT_DELIVERY',
    exact: true,
    component: <AboutDeliveryPage />,
  },
  {
    path: '/terms-and-conditions',
    key: 'ROOT_TERMS',
    exact: true,
    component: <TermsPage />,
  },
  {
    path: '/contact',
    key: 'ROOT_CONTACT_US',
    exact: true,
    component: <ContactUsPage />,
  },
  {
    path: '/privacy-policy',
    key: 'ROOT_PRIVACY_POLICY',
    exact: true,
    component: <PrivacyPolicyPage />,
  },
  {
    path: '/return-policy',
    key: 'ROOT_RETURN_POLICY',
    exact: true,
    component: <ReturnPolicyPage />,
  },
  {
    path: '/anpc',
    key: 'ROOT_ANPC',
    exact: true,
    component: <ANPCPage />,
  },
];

const SettingsRoutes = [
  // {
  //     path: '/account-profile/:tab',
  //     key: 'ROOT_ACCOUNT_PROFILE',
  //     exact: true,
  //     component: <AccountProfile />,
  // },
  // {
  //     path: 'account',
  //     key: 'ROOT_ACCOUNT',
  //     exact: true,
  //     component: <Account />,
  // },
];

const AccountRoutes = [
  {
    path: '/profile',
    key: 'ROOT_PROFILE',
    exact: true,
    component: <ProfilePage />,
  },
  // {
  //   path: 'rates',
  //   key: 'ROOT_RATES',
  //   exact: true,
  //   component: <Rates />,
  // },
  // {
  //   path: 'wallet/:tab',
  //   key: 'ROOT_WALLET',
  //   exact: true,
  //   component: <Wallet />,
  // },
  // {
  //   path: 'wallet/unfolded',
  //   key: 'ROOT_WALLET_UNFOLDED',
  //   exact: true,
  //   component: <WalletUnfolded />,
  // },
  // {
  //   path: 'availability-schedule',
  //   key: 'ROOT_AVAILABILITY_SCHEDULE',
  //   exact: true,
  //   component: <AvailabilitySchedule />,
  // },
];

const PublicRoutes = [
  ...AuthRoutes,
  ...GeneralRoutes,
  {
    path: '/',
    key: 'MAIN_PAGE',
    exact: true,
    component: <MainPage />,
  },
  {
    path: '/cart',
    key: 'CART_PAGE',
    exact: true,
    component: <CartPage />,
  },
  {
    path: '/product/:productCode',
    key: 'PRODUCT_PAGE',
    exact: true,
    component: <ProductPage />,
  },
];

const PrivateRoutes = [
  ...AccountRoutes,
  {
    path: '/order/success',
    key: 'CART_CHECKOUT',
    exact: true,
    component: <SuccessPage />,
  },
  {
    path: '/orders',
    key: 'ORDERS_HISTORY_PAGE',
    exact: true,
    component: <OrderHistoryPage />,
  },
  {
    path: '/orders/:token',
    key: 'ORDER_PAGE',
    exact: true,
    component: <OrderPage />,
  },
];

const AppRoutes = ({ isLoggedIn, maintenanceMode }) => {
  // if (maintenanceMode) return <Route path="." element={<WorkInProgress />} />;

  return (
    <Routes>
      {PublicRoutes.map((route) => {
        return <Route key={route.key} path={route.path} element={route.component} />;
      })}
      <Route path="/" element={<PrivateRoute isLoggedIn={isLoggedIn} redirectTo="login" />}>
        {PrivateRoutes.map((route) => {
          return <Route key={route.key} path={route.path} element={route.component} />;
        })}
      </Route>
      <Route
        path="*"
        element={
          <>
            <h1
              className="text-center"
              style={{
                marginTop: '100px',
                fontFamily: 'Rota',
                fontWeight: 200,
                fontSize: '100px',
              }}
            >
              404
            </h1>
            <p
              className="text-center"
              style={{ marginTop: '10px', fontFamily: 'Rota', fontWeight: 600 }}
            >
              Această pagină nu există. Dacă crezi că este o eroare, contactează-ne.
            </p>
          </>
        }
      />
    </Routes>
  );
};

AppRoutes.defaultProps = {
  isLoggedIn: false,
  maintenanceMode: false,
};

AppRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
  maintenanceMode: PropTypes.bool,
};

export default AppRoutes;
