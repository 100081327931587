import { useEffect, useState } from 'react';
import Company from 'types/Company';

const useCompany = (company: Company) => {
  const [companyID, setCompanyID] = useState(company?.id ?? '');
  const [companyFirstName, setCompanyFirstName] = useState(company?.firstName ?? '');
  const [companyLastName, setCompanyLastName] = useState(company?.lastName ?? '');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(company?.phoneNumber ?? '');
  const [companyUnRegistrationCode, setCompanyUnRegistrationCode] = useState(
    company?.uniqueRegistrationCode ?? '',
  );
  const [companyCommercialRegisterNumber, setCompanyCommercialRegisterNumber] = useState(
    company?.commercialRegisterNumber ?? '',
  );

  const [companyBank, setCompanyBank] = useState(company?.bank ?? '');
  const [companyBankAccount, setCompanyBankAccount] = useState(company?.bankAccount ?? '');
  const [companyName, setCompanyName] = useState(company?.company ?? '');
  const [companyCity, setCompanyCity] = useState(company?.city ?? '');
  const [companyProvince, setCompanyProvince] = useState(company?.provinceName ?? '');
  const [companyStreet, setCompanyStreet] = useState(company?.street ?? '');
  const [companyAdditionalInfo, setCompanyAdditionalInfo] = useState(company?.additionalInfo ?? '');

  const handleCompanyFirstNameChange = (e) => setCompanyFirstName(e.target.value.trim());
  const handleCompanyLastNameChange = (e) => setCompanyLastName(e.target.value.trim());
  const handleCompanyPhoneNumberChange = (e) => setCompanyPhoneNumber(e.target.value.trim());
  const handleCompanyCommercialRegisterNumberChange = (e) =>
    setCompanyCommercialRegisterNumber(e.target.value.trim());
  const handleCompanyBankAccountChange = (e) => setCompanyBankAccount(e.target.value.trim());
  const handleCompanyBankChange = (e) => setCompanyBank(e.target.value.trim());
  const handleCompanyCityChange = (e) => setCompanyCity(e.target.value.trim());
  const handleCompanyProvinceChange = (e) => setCompanyProvince(e.target.value.trim());
  const handleCompanyStreetChange = (e) => setCompanyStreet(e.target.value.trim());
  const handleCompanyNameChange = (e) => setCompanyName(e.target.value.trim());
  const handleCompanyUnRegistrationCodeChange = (e) =>
    setCompanyUnRegistrationCode(e.target.value.trim());
  const handleCompanyAdditionalInfoChange = (e) => setCompanyAdditionalInfo(e.target.value.trim());

  useEffect(() => {
    setCompanyID(company?.id ?? '');
    setCompanyName(company?.company ?? '');
    setCompanyUnRegistrationCode(company?.uniqueRegistrationCode ?? '');
    setCompanyCommercialRegisterNumber(company?.commercialRegisterNumber ?? '');
    setCompanyBank(company?.bank ?? '');
    setCompanyBankAccount(company?.bankAccount ?? '');
    setCompanyFirstName(company?.firstName ?? '');
    setCompanyLastName(company?.lastName ?? '');
    setCompanyPhoneNumber(company?.phoneNumber ?? '');
    setCompanyCity(company?.city ?? '');
    setCompanyProvince(company?.provinceName ?? '');
    setCompanyStreet(company?.street ?? '');
    setCompanyAdditionalInfo(company?.additionalInfo ?? '');
  }, [company]);

  return {
    companyID,
    companyFirstName,
    companyLastName,
    companyPhoneNumber,
    companyUnRegistrationCode,
    companyCommercialRegisterNumber,
    companyBank,
    companyBankAccount,
    companyName,
    companyCity,
    companyProvince,
    companyStreet,
    companyAdditionalInfo,
    handleCompanyUnRegistrationCodeChange,
    handleCompanyFirstNameChange,
    handleCompanyLastNameChange,
    handleCompanyPhoneNumberChange,
    handleCompanyCommercialRegisterNumberChange,
    handleCompanyBankChange,
    handleCompanyBankAccountChange,
    handleCompanyCityChange,
    handleCompanyProvinceChange,
    handleCompanyStreetChange,
    handleCompanyNameChange,
    handleCompanyAdditionalInfoChange,
    setCompanyID,
    setCompanyFirstName,
    setCompanyLastName,
    setCompanyPhoneNumber,
    setCompanyUnRegistrationCode,
    setCompanyCommercialRegisterNumber,
    setCompanyBank,
    setCompanyBankAccount,
    setCompanyName,
    setCompanyCity,
    setCompanyProvince,
    setCompanyStreet,
    setCompanyAdditionalInfo,
  };
};

export default useCompany;
