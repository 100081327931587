import { useTranslation } from 'react-i18next';
import 'styles/components/CartDeliveryBlock.scss';
import {
  getAuthState,
  getAvailableShippingMethodsState,
  getSelectedAddressState,
  getShippingMethodsState,
} from 'redux/src/StatesGetter';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  fetchAddressRequest,
  setContactInfo,
  setContactInfoModal,
  setSelectedAddressModal,
} from 'redux/actions/AddressActions';
import { addressAsString } from 'modules/AddressUtils';
import ProgramDeliveryModal from 'components/modals/ProgramDeliveryModal';
import { orderDateETAFormat, orderHistoryDateFormat } from 'modules/DateUtils';

const CartDeliveryBlock = ({ cart }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);

  const selectedAddress = useSelector(getSelectedAddressState, shallowEqual);

  const shippingMethodState = useSelector(getShippingMethodsState, shallowEqual);
  const availableShippingMethods = useSelector(getAvailableShippingMethodsState, shallowEqual);

  const { delivery } = selectedAddress;

  const { billingAddress, shippingAddress } = cart;

  const openSelectAddressModal = () => {
    if (isLoggedIn) {
      dispatch(fetchAddressRequest({}));
    }
    dispatch(setSelectedAddressModal({ open: true, setDefault: false, billing: false }));
  };

  const canDeliver = availableShippingMethods?.type === 'asap';

  const [programDeliveryModal, setProgramDeliveryModal] = useState(false);
  const openProgramDeliveryModal = () => setProgramDeliveryModal(true);
  const closeProgramDeliveryModal = () => setProgramDeliveryModal(false);

  const openContactInfoModal = () => {
    dispatch(
      setContactInfo({
        firstName: shippingAddress.firstName,
        lastName: shippingAddress.lastName,
        phoneNumber: shippingAddress.phoneNumber,
      }),
    );
    dispatch(setContactInfoModal({ open: true, billing: false }));
  };

  return (
    <>
      <div className="cart-delivery-block">
        <div className="delivery-block-title mb-3">{t('cart.delivery')}</div>
        {cart.shippingAddress && (
          <div className="delivery-block-eta">
            <div className="delivery-title">
              <span>{t('cart.whenToArrive')}</span>
              {!canDeliver &&
                shippingMethodState.type === 'planned' &&
                shippingMethodState.day !== '' &&
                shippingMethodState.slotStart !== '' && (
                  <span className="modification-btn" onClick={openProgramDeliveryModal}>
                    {t('cart.modify')}
                  </span>
                )}
            </div>
            {canDeliver && (
              <>
                <span className="delivery-text">{t('cart.asap')}</span>
                <span className="eta">{`${t('cart.arrivesAsap')} ${
                  availableShippingMethods?.etaStart
                }-${availableShippingMethods?.etaEnd} min`}</span>
              </>
            )}
            {!canDeliver &&
              shippingMethodState.type === 'planned' &&
              shippingMethodState.day !== '' &&
              shippingMethodState.slotStart !== '' && (
                <>
                  <div className="delivery-text">
                    <span>{t('order.day')}</span>
                    <span className="bold-text">
                      {shippingMethodState?.day !== ''
                        ? orderHistoryDateFormat(shippingMethodState?.day)
                        : '-'}
                    </span>
                  </div>
                  <div className="delivery-text">
                    <span>{t('order.timeSlot')}</span>
                    <span className="bold-text">
                      {shippingMethodState?.slotStart !== ''
                        ? orderDateETAFormat(
                            shippingMethodState?.slotStart,
                            shippingMethodState?.slotEnd,
                          )
                        : '-'}
                    </span>
                  </div>
                </>
              )}
            {!canDeliver && shippingMethodState.type !== 'planned' && (
              <>
                <span className="delivery-text">{t('cart.arrivesAsapNotAvailable')}</span>
                <span className="delivery-program-button" onClick={openProgramDeliveryModal}>
                  {t('cart.scheduleDelivery')}
                </span>
              </>
            )}
          </div>
        )}
        <div className="delivery-block-address">
          <div className="delivery-title">
            <span>{t('cart.deliveryAddress')}</span>
            {shippingAddress && (
              <span className="modification-btn" onClick={openSelectAddressModal}>
                {t('cart.modify')}
              </span>
            )}
          </div>
          {shippingAddress && (
            <>
              <span className="delivery-text">
                {cart.shippingAddress ? addressAsString(cart.shippingAddress) : null}
              </span>
              <span className="delivery-text">{cart.shippingAddress?.additionalInfo}</span>
            </>
          )}
        </div>
        {shippingAddress && (
          <div className="delivery-block-contact">
            <div className="delivery-title">
              <span>{t('cart.deliveryContacts')}</span>
              <span className="modification-btn" onClick={openContactInfoModal}>
                {t('cart.modify')}
              </span>
            </div>
            <span className="delivery-text">{`${cart.shippingAddress?.firstName ?? ''} ${
              cart.shippingAddress?.lastName ?? ''
            }`}</span>
            <span className="delivery-text">{`${cart.shippingAddress?.phoneNumber ?? ''}`}</span>
          </div>
        )}
        {!shippingAddress && (
          <div className="selected-address-btn" onClick={openSelectAddressModal}>
            {t('selectAddressText')}
          </div>
        )}
      </div>
      <ProgramDeliveryModal
        showModal={programDeliveryModal}
        modalClose={closeProgramDeliveryModal}
      />
    </>
  );
};

export default CartDeliveryBlock;
