import ApiUtils from 'modules/ApiUtils';
import { default as ApiConstants } from 'constants/ApiConstants.json';

const createCustomerCompanyApi = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.CREATE_CUSTOMER_COMPANY_ENDPOINT, payload);

const getCustomerCompanyApi = ({ id }) =>
  ApiUtils.getRequest(`${ApiConstants.GET_CUSTOMER_COMPANY_ENDPOINT}/${id}`);

const updateCustomerCompanyApi = ({ id, data }) =>
  ApiUtils.patchRequest(`${ApiConstants.UPDATE_CUSTOMER_COMPANY_ENDPOINT}/${id}`, data, {
    'Content-Type': 'application/merge-patch+json; charset=utf-8',
  });

const deleteCustomerCompanyApi = ({ id }) =>
  ApiUtils.deleteRequest(`${ApiConstants.DELETE_CUSTOMER_COMPANY_ENDPOINT}/${id}`);

const fetchCompanyDataApi = ({ cui }) =>
  ApiUtils.getRequest(`${ApiConstants.FETCH_COMPANY_DATA_ENDPOINT}/${cui}`);

const fetchCustomerCompaniesApi = () =>
  ApiUtils.getRequest(ApiConstants.FETCH_CUSTOMER_COMPANIES_ENDPOINT);

export {
  createCustomerCompanyApi,
  getCustomerCompanyApi,
  updateCustomerCompanyApi,
  deleteCustomerCompanyApi,
  fetchCompanyDataApi,
  fetchCustomerCompaniesApi,
};
