/* eslint-disable class-methods-use-this */
import EventEmitter from 'events';

let registeredEvents: string[] = [];
let emitter: EventEmitter | null = null;

class LocalEventsService {
  constructor() {
    if (!emitter) {
      emitter = new EventEmitter();
    }
  }

  doEmit(eventName, params) {
    emitter?.emit(eventName, params);
  }

  addListener(eventName, cb) {
    if (registeredEvents.indexOf(eventName) === -1) {
      registeredEvents.push(eventName);
      emitter?.addListener(eventName, (params: Event) => {
        cb(params);
      });
    }
  }

  removeListener = (eventName, action) => {
    registeredEvents = registeredEvents.filter((eve) => eve !== eventName);
    emitter?.removeListener(eventName, action);
  };

  turnOff() {
    registeredEvents = [];
    emitter?.removeAllListeners();
  }
}

const on = (eventName, cb) => {
  new LocalEventsService().addListener(eventName, cb);
};
const emit = (eventName, payload: Record<string, string | boolean | number> | null = null) => {
  new LocalEventsService().doEmit(eventName, payload);
};
const off = (eventName) => {
  new LocalEventsService().removeListener(eventName, () => {});
};

const offAll = () => {
  new LocalEventsService().turnOff();
};

export default { on, emit, off, offAll };
