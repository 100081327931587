import 'styles/components/AccountChangePasswordBlock.scss';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changePasswordRequest } from 'redux/actions/AuthActions';
import Alerts from 'components/Alerts';
import { useTranslation } from 'react-i18next';
import { getEnv } from 'services/EnvService';
import { getAuthState } from 'redux/src/StatesGetter';
import StorageService from 'services/StorageService';
import showPassIcon from 'assets/images/show-password.svg';
import hidePassIcon from 'assets/images/hide-password.svg';

const AccountChangePasswordBlock = ({ profile }) => {
  const { t, i18n } = useTranslation();

  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const dispatch = useDispatch();

  const [currentPassword, setCurrentPassword] = useState('');
  const handleCurrentPassword = (e) => setCurrentPassword(e.target.value);
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };

  const [newPassword, setNewPassword] = useState('');
  const handleNewPassword = (e) => setNewPassword(e.target.value);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const toggleNewPasswordVisibility = () => {
    setNewPasswordShown(!newPasswordShown);
  };

  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const handleConfirmNewPassword = (e) => setConfirmNewPassword(e.target.value);
  const [repeatNewPasswordShown, setRepeatNewPasswordShown] = useState(false);
  const toggleRepeatNewPasswordVisibility = () => {
    setRepeatNewPasswordShown(!repeatNewPasswordShown);
  };

  const onPress = () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      Alerts.warningAlert(t('account.changePasswordValidation3'));
      return;
    }

    if (!currentPassword) {
      Alerts.warningAlert(t('account.changePasswordValidation1'));
      return;
    }

    if (newPassword !== confirmNewPassword) {
      Alerts.warningAlert(t('account.changePasswordValidation2'));
      return;
    }

    dispatch(
      changePasswordRequest({
        id: profile.id,
        passData: {
          currentPassword,
          newPassword,
          confirmNewPassword,
        },
      }),
    );
  };

  return (
    <div className="account-change-password">
      <div className="mobile-block-title">{t('settings.changePassword')}</div>
      <div className="change-password">
        <div className="password-eye">
          <input
            name="current-password"
            autoComplete="off"
            id="current-password"
            type={currentPasswordShown ? 'text' : 'password'}
            className="form-control"
            placeholder=" "
            value={currentPassword}
            onChange={handleCurrentPassword}
          />
          <label htmlFor="current-password">{t('account.oldPassword')}</label>
          <div className="eye" onClick={toggleCurrentPasswordVisibility}>
            <img src={currentPasswordShown ? hidePassIcon : showPassIcon} alt="show-password" />
          </div>
        </div>

        <div className="password-eye">
          <input
            name="new-password"
            autoComplete="off"
            id="new-password"
            type={newPasswordShown ? 'text' : 'password'}
            className="form-control"
            placeholder=" "
            value={newPassword}
            onChange={handleNewPassword}
          />
          <label htmlFor="new-password">{t('account.newPassword')}</label>
          <div className="eye" onClick={toggleNewPasswordVisibility}>
            <img src={newPasswordShown ? hidePassIcon : showPassIcon} alt="show-password" />
          </div>
        </div>

        <div className="password-eye">
          <input
            name="repeat-new-password"
            autoComplete="off"
            id="repeat-new-password"
            type={repeatNewPasswordShown ? 'text' : 'password'}
            className="form-control"
            placeholder=" "
            value={confirmNewPassword}
            onChange={handleConfirmNewPassword}
          />
          <label htmlFor="repeat-new-password">{t('account.confirmNewPassword')}</label>
          <div className="eye" onClick={toggleRepeatNewPasswordVisibility}>
            <img src={repeatNewPasswordShown ? hidePassIcon : showPassIcon} alt="show-password" />
          </div>
        </div>
      </div>
      <button type="button" className="green-button" onClick={onPress}>
        {t('account.changePasswordBtn')}
      </button>
    </div>
  );
};

export default AccountChangePasswordBlock;
