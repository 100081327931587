import 'styles/pages/OrderPage.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import arrowBack from 'assets/images/arrow-back.svg';
import OrderMap from 'components/map/OrderMap';
import pin from 'assets/images/google-pin.svg';
import driverPin from 'assets/images/driver-pin.svg';
import driverCar from 'assets/images/driver-car.svg';
import { getGeneralInfoState, getOrderDetailsState } from 'redux/src/StatesGetter';
import { addressAsString, formatAddressData } from 'modules/AddressUtils';
import { orderHistoryDateFormat } from 'modules/DateUtils';
import { fetchOrderDetailsRequest, triggerReviewRequest } from '../redux/actions/OrderActions';

type LocationState = {
  token: string | null;
};

type markerObject = {
  id: number;
  latitude: number;
  longitude: number;
  icon: string;
};

const OrderPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const generalInfo = useSelector(getGeneralInfoState, shallowEqual);
  const order = useSelector(getOrderDetailsState, shallowEqual);

  const {
    delivery,
    shippingAddress,
    billingAddress,
    vehicle,
    totalAsString,
    itemsTotalAsString,
    shippingTotalAsString,
    shippingTotal,
    state,
    items,
    payments,
  } = order;

  const markers: markerObject[] = [];

  if (shippingAddress && shippingAddress?.latitude && shippingAddress?.longitude) {
    markers.push({
      id: 1,
      latitude: parseFloat(shippingAddress?.latitude),
      longitude: parseFloat(shippingAddress?.longitude),
      icon: pin,
    });
  }

  if (vehicle && vehicle.currentDriver?.latitude && vehicle.currentDriver?.longitude) {
    markers.push({
      id: 2,
      latitude: parseFloat(vehicle?.currentDriver?.latitude),
      longitude: parseFloat(vehicle?.currentDriver?.longitude),
      icon: driverPin,
    });
  }

  useEffect(() => {
    dispatch(fetchOrderDetailsRequest(token));
    const interval = setInterval(() => {
      dispatch(fetchOrderDetailsRequest(token));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const eta = order.timeBeforeDeliveryInMinutes;

  const percentage = (1 - eta / 60) * 100;

  return (
    <>
      <div className="order-page-wrapper container">
        <div className="back-to-orders-button" onClick={() => navigate('/orders')}>
          <img src={arrowBack} alt="arrow-back" />
          <span>{t('order.backToOrders')}</span>
        </div>
        <div className="order-page-title">{`${t('order.order')} ${order.number}`}</div>
        <div className="order-content">
          {markers.length > 0 && (
            <div className="order-map">
              <OrderMap address={{ lat: 44.429584, lng: 26.103231, zoom: 15 }} markers={markers} />
            </div>
          )}
          <div className="order-details-wrapper">
            <div className="order-details">
              <span className="order-details-title">{t('order.orderStatus')}</span>
              {delivery?.deliveryState === 'in_delivery' ? (
                <div className="order-detail-section order-in-delivery-wrapper">
                  <span>{t('order.orderInDelivery')}</span>
                  <div className="order-progress-bar">
                    <div className="order-progress-bar-fill" style={{ width: `${percentage}%` }}>
                      <span className="order-progress-bar-text">
                        <img src={driverCar} alt="driver-car" />
                        {`${eta} min`}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="order-detail-section order-in-queue-wrapper">
                  <span className="order-detail-section-title">{t('cart.delivery')}</span>
                  <div className="order-detail-section-content section-row">
                    <span>{t('order.status')}</span>
                    <span className="bold-text">{t(`order.${state}`)}</span>
                  </div>
                  {state !== 'cancelled' && (
                    <>
                      <div className="order-detail-section-content section-row">
                        <span>{t('order.day')}</span>
                        <span className="bold-text">
                          {orderHistoryDateFormat(delivery?.deliveryDate)}
                        </span>
                      </div>
                      <div className="order-detail-section-content section-row">
                        <span>{t('order.timeSlot')}</span>
                        <span className="bold-text">{delivery?.deliveryHours}</span>
                      </div>
                    </>
                  )}
                </div>
              )}
              {order.vehicle?.currentDriver && (
                <div className="order-detail-section order-driver-wrapper">
                  <span className="order-detail-section-title">{t('order.curier')}</span>
                  <div className="order-detail-section-content section-row">
                    <span>{`${order.vehicle?.currentDriver?.firstName ?? ''} ${
                      order.vehicle?.currentDriver?.lastName ?? ''
                    }`}</span>
                    <span className="bold-text">{order.vehicle?.currentDriver?.phoneNumber}</span>
                  </div>
                </div>
              )}
              {items?.length > 0 && (
                <div className="order-detail-section order-billing-wrapper">
                  <span className="order-detail-section-title">{t('cart.product')}</span>
                  {items?.map((item) => {
                    return (
                      <div className="order-detail-section-content section-row" key={item.id}>
                        <span>
                          {item.variant.code === '11L' || item.variant.code === '19L'
                            ? `${t('packet')} ${item.quantity} x ${item.productName}`
                            : `${item.quantity} x ${item.productName}`}
                        </span>
                        <span className="bold-text">{item.totalAsString}</span>
                      </div>
                    );
                  })}
                </div>
              )}
              {shippingAddress && (
                <>
                  <div className="order-detail-section order-products-wrapper">
                    <span className="order-detail-section-title">{t('order.deliveryAddress')}</span>
                    <div className="order-detail-section-content section-column">
                      <span className="order-address">{addressAsString(shippingAddress)}</span>
                      <span className="order-address-details">
                        {shippingAddress?.additionalInfo}
                      </span>
                    </div>
                  </div>
                  <div className="order-detail-section order-address-wrapper">
                    <span className="order-detail-section-title">{t('order.addressContacts')}</span>
                    <div className="order-detail-section-content section-column">
                      <span className="order-detail-contact-name">{`${shippingAddress?.firstName} ${shippingAddress?.lastName}`}</span>
                      <span className="order-detail-contact-phone">
                        {shippingAddress?.phoneNumber}
                      </span>
                    </div>
                  </div>
                </>
              )}
              {payments?.length > 0 && (
                <div className="order-detail-section order-contact-wrapper">
                  <span className="order-detail-section-title">{t('paymentMethod')}</span>
                  <div className="order-detail-section-content">
                    <span className="order-detail-payment">{payments[0]?.method?.name}</span>
                  </div>
                </div>
              )}
              {billingAddress && (
                <div className="order-detail-section order-billing-wrapper">
                  <span className="order-detail-section-title">{t('order.billing')}</span>

                  <div className="order-detail-billing-section">
                    <span className="order-detail-section-title">{t('order.billingAddress')}</span>
                    <div className="order-detail-section-content section-column">
                      <span className="order-address">{addressAsString(billingAddress)}</span>
                      <span className="order-address-details">
                        {billingAddress?.additionalInfo}
                      </span>
                    </div>
                  </div>
                  <div className="order-detail-billing-section">
                    <span className="order-detail-section-title">{t('order.billingContacts')}</span>
                    <div className="order-detail-section-content section-column">
                      <span className="order-detail-contact-name">{`${billingAddress?.firstName} ${billingAddress?.lastName}`}</span>
                      <span className="order-detail-contact-phone">
                        {billingAddress?.phoneNumber}
                      </span>
                    </div>
                  </div>
                  {billingAddress?.company && billingAddress?.company !== '' && (
                    <div className="order-detail-billing-section">
                      <span className="order-detail-section-title ">
                        {t('order.companyBilling')}
                      </span>
                      <div className="order-detail-subsection">
                        <span className="order-detail-section-subtitle">
                          {t('account.companyName')}:
                        </span>
                        <span className="order-detail-contact-name">{billingAddress?.company}</span>
                      </div>
                      <div className="order-detail-subsection">
                        <span className="order-detail-section-subtitle">{t('account.cif')}:</span>
                        <span className="order-detail-contact-name">
                          {billingAddress?.uniqueRegistrationCode}
                        </span>
                      </div>
                      <div className="order-detail-subsection">
                        <span className="order-detail-section-subtitle">
                          {t('account.companyRegistration')}:
                        </span>
                        <span className="order-detail-contact-name">
                          {billingAddress?.commercialRegisterNumber}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="order-total-cost-wrapper">
                <div className="order-detail-section-content section-row">
                  <span>{t('cart.product')}</span>
                  <span className="bold-text">{itemsTotalAsString}</span>
                </div>
                <div className="order-detail-section-content section-row">
                  <span>{t('cart.delivery')}</span>
                  <span className="bold-text">
                    {shippingTotal === 0 ? t('cart.freeOfCharge') : shippingTotalAsString}
                  </span>
                </div>
                <div className="order-detail-section-content section-row">
                  <span className="total-amount-text">{t('cart.total')}</span>
                  <span className="total-amount-text">{totalAsString}</span>
                </div>
              </div>
            </div>
            <div className="request-for-help-wrapper">
              <a href={`tel:${generalInfo?.phoneNumber ?? ''}`}>
                <span>{`${t('cart.requestForHelp')} ${generalInfo?.phoneNumber ?? ''}`}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderPage;
