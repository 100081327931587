export const UPDATE_DEVICE_REG_ID_REQUEST = 'UPDATE_DEVICE_REG_ID_REQUEST';
export const UPDATE_DEVICE_REG_ID_SUCCESS = 'UPDATE_DEVICE_REG_ID_SUCCESS';
export const UPDATE_DEVICE_REG_ID_ERROR = 'UPDATE_DEVICE_REG_ID_ERROR';

export const GET_DEVICE_REG_ID_REQUEST = 'GET_DEVICE_REG_ID_REQUEST';
export const GET_DEVICE_REG_ID_SUCCESS = 'GET_DEVICE_REG_ID_SUCCESS';
export const GET_DEVICE_REG_ID_ERROR = 'GET_DEVICE_REG_ID_ERROR';

export const ASSOCIATE_DEVICE_REQUEST = 'ASSOCIATE_DEVICE_REQUEST';
export const ASSOCIATE_DEVICE_SUCCESS = 'ASSOCIATE_DEVICE_SUCCESS';
export const ASSOCIATE_DEVICE_ERROR = 'ASSOCIATE_DEVICE_ERROR';
