/**
 * @ref https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
 * @param state
 * @param action
 * @returns {{}}
 */
const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_ERROR actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  };
};

/**
 * @ref https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
 * @param state
 * @param action
 * @returns {{}}
 */
const errorReducer = (state = {}, action) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|RECHECK|ERROR)/.exec(type);

  if (!matches) return state;
  const [, requestName, requestState] = matches;

  // Allow only one error per request possible at once
  if (requestState === 'ERROR' && state[requestName] && state[requestName] !== '') {
    return state;
  }

  return {
    [requestName]:
      requestState === 'ERROR' && payload
        ? {
            type: requestName,
            title: payload.title,
            message: payload.message,
            buttons: payload.buttons,
            properties: payload.properties,
          }
        : '',
  };
};

export { errorReducer, loadingReducer };
