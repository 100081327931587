export const FETCH_CUSTOMER_COMPANIES_REQUEST = 'FETCH_CUSTOMER_COMPANIES_REQUEST';
export const FETCH_CUSTOMER_COMPANIES_SUCCESS = 'FETCH_CUSTOMER_COMPANIES_SUCCESS';
export const FETCH_CUSTOMER_COMPANIES_ERROR = 'FETCH_CUSTOMER_COMPANIES_ERROR';

export const GET_CUSTOMER_COMPANY_REQUEST = 'GET_CUSTOMER_COMPANY_REQUEST';
export const GET_CUSTOMER_COMPANY_SUCCESS = 'GET_CUSTOMER_COMPANY_SUCCESS';
export const GET_CUSTOMER_COMPANY_ERROR = 'GET_CUSTOMER_COMPANY_ERROR';

export const CREATE_CUSTOMER_COMPANY_REQUEST = 'CREATE_CUSTOMER_COMPANY_REQUEST';
export const CREATE_CUSTOMER_COMPANY_SUCCESS = 'CREATE_CUSTOMER_COMPANY_SUCCESS';
export const CREATE_CUSTOMER_COMPANY_ERROR = 'CREATE_CUSTOMER_COMPANY_ERROR';

export const UPDATE_CUSTOMER_COMPANY_REQUEST = 'UPDATE_CUSTOMER_COMPANY_REQUEST';
export const UPDATE_CUSTOMER_COMPANY_SUCCESS = 'UPDATE_CUSTOMER_COMPANY_SUCCESS';
export const UPDATE_CUSTOMER_COMPANY_ERROR = 'UPDATE_CUSTOMER_COMPANY_ERROR';

export const DELETE_CUSTOMER_COMPANY_REQUEST = 'DELETE_CUSTOMER_COMPANY_REQUEST';
export const DELETE_CUSTOMER_COMPANY_SUCCESS = 'DELETE_CUSTOMER_COMPANY_SUCCESS';
export const DELETE_CUSTOMER_COMPANY_ERROR = 'DELETE_CUSTOMER_COMPANY_ERROR';

export const FETCH_COMPANY_DATA_REQUEST = 'FETCH_COMPANY_DATA_REQUEST';
export const FETCH_COMPANY_DATA_SUCCESS = 'FETCH_COMPANY_DATA_SUCCESS';
export const FETCH_COMPANY_DATA_ERROR = 'FETCH_COMPANY_DATA_ERROR';

export const SET_LOCAL_COMPANY = 'SET_LOCAL_COMPANY';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';

export const COMPANY_MODAL = 'COMPANY_MODAL';
export const SELECT_COMPANY_MODAL = 'SELECT_COMPANY_MODAL';
