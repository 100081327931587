import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Container, Nav, Navbar } from 'react-bootstrap';
import 'styles/components/Navigation.scss';
import logo from 'assets/images/logo.svg';
import homeIcon from 'assets/images/home_icon.svg';
import profileIcon from 'assets/images/profile_icon.svg';
import orderIcon from 'assets/images/orders_icon.svg';
import defaultCartIcon from 'assets/images/cart_black_icon.svg';
import whiteCartIcon from 'assets/images/cart_white_icon.svg';
import AddressModal from 'components/modals/AddressModal';
import {
  getUserProfileState,
  getCartState,
  getAuthState,
  getSelectedAddressState,
  getAddressListState,
} from 'redux/src/StatesGetter';
import { fetchProfileRequest } from 'redux/actions/ProfileActions';
import { addressAsString } from 'modules/AddressUtils';
import { Link, useLocation } from 'react-router-dom';
import SelectAddressModal from 'components/modals/SelectAddressModal';
import {
  fetchAddressRequest,
  setAddressModal,
  setSelectedAddressModal,
} from 'redux/actions/AddressActions';
import Order from 'types/Order';
import CompanyModal from 'components/modals/CompanyModal';
import ReviewModal from 'components/modals/ReviewModal';
import DownloadDataModal from 'components/modals/DownloadDataModal';
import { useTranslation } from 'react-i18next';

const Navigation = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const cart: Order = useSelector(getCartState, shallowEqual);

  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const address = useSelector(getSelectedAddressState, shallowEqual);
  const addresses = useSelector(getAddressListState, shallowEqual);
  const [emptyAddress, setEmptyAddress] = useState(Object.keys(address).length === 0);

  const openSelectAddressModal = () => {
    if (addresses?.length > 0) {
      dispatch(setSelectedAddressModal({ open: true, setDefault: true, billing: false }));
    } else {
      dispatch(
        setAddressModal({
          open: true,
          setSelected: true,
          billing: false,
        }),
      );
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchProfileRequest({}));
      dispatch(fetchAddressRequest({}));
    }
  }, []);

  useEffect(() => {
    setEmptyAddress(Object.keys(address).length === 0);
  }, [address]);

  return (
    <>
      <Navbar sticky="top" expand bg="light" variant="dark">
        <Container>
          <Link to="/">
            <img
              alt="AQUAVIA"
              src={logo}
              width="112"
              height="24"
              className="d-inline-block align-top"
            />
          </Link>
          <Nav.Item>
            <div
              className={`address-button ${!emptyAddress ? 'has-address' : ''}`}
              onClick={openSelectAddressModal}
            >
              <span>
                {!emptyAddress ? `${addressAsString(address)}` : t('chooseDeliveryAddress')}
              </span>
            </div>
          </Nav.Item>
          <Nav className="nav-links-wrapper">
            <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
              <img src={homeIcon} width="22" height="24" alt="home" />
            </Link>
            <Link
              to="/orders"
              className={
                location.pathname === '/orders' || location.pathname === '/order' ? 'active' : ''
              }
            >
              <img src={orderIcon} width="24" height="23" alt="orders" />
            </Link>
            <Link to="/profile" className={location.pathname === '/profile' ? 'active' : ''}>
              <img src={profileIcon} width="20" height="24" alt="profile" />
            </Link>
            <Link to="/cart" className={location.pathname === '/cart' ? 'active' : ''}>
              <div className={`cart-wrapper ${cart.totalQuantity > 0 ? 'has-items' : ''}`}>
                <img
                  src={cart.totalQuantity > 0 ? whiteCartIcon : defaultCartIcon}
                  width="21"
                  height="21"
                  alt="cart"
                />
                {cart.totalQuantity > 0 && <span>{cart.totalUniqueItems}</span>}
              </div>
            </Link>
          </Nav>
        </Container>
      </Navbar>
      <SelectAddressModal />
      <AddressModal />
      <CompanyModal />
      <ReviewModal />
      <DownloadDataModal />
    </>
  );
};
export default Navigation;
