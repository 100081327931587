import { sortObjectByKey } from 'modules/Utils';
import { resolvePrimaryImagePath } from 'modules/ImageUtils';
import { resolveAquaDetails, resolveProductName } from 'modules/ProductUtils';
import { convertPrice, convertPriceToString } from 'modules/CurrencyUtils';
import { default as StringConstants } from 'constants/StringConstants.json';
import StorageService from 'services/StorageService';
import i18next from 'i18next';

const allProductsSelector = (products) => {
  const waterProducts = products.filter((product) => {
    return product.mainTaxon.code === 'WATER';
  });

  const accessoriesProducts = products.filter((product) => {
    return product.mainTaxon.code === 'ACCESSORIES';
  });

  const waterArray = [].concat(
    ...waterProducts.map((product) => {
      if (product.aquaDetails.length === 0) {
        return {
          aquaDetails: resolveAquaDetails(product),
          productName: product.translations[i18next.language].name,
          primaryImage: resolvePrimaryImagePath(product.images),
          code: product.code,
          mainTaxon: product.mainTaxon.code,
          description: product.description,
          hasBottle: product.hasBottle ?? false,
          averageRating: product.averageRating,
          position: product.sortPosition,
          quantity: 1,
          id: product.variants[0].id,
        };
      }
      sortObjectByKey(product.aquaDetails, 'quantity');
      return product.aquaDetails.map((batch) => {
        return {
          aquaDetails: product.aquaDetails,
          productName: product.translations[i18next.language].name,
          primaryImage: batch.image,
          code: product.code,
          mainTaxon: product.mainTaxon.code,
          description: product.description,
          hasBottle: product.hasBottle,
          averageRating: product.averageRating,
          quantity: batch.quantity,
          position: batch.position,
          id: batch.pvqpId,
        };
      });
    }),
  );

  const accessoriesArray = [].concat(
    ...accessoriesProducts.map((product) => {
      return {
        aquaDetails: resolveAquaDetails(product),
        productName: product.translations[i18next.language].name,
        primaryImage: resolvePrimaryImagePath(product.images),
        code: product.code,
        mainTaxon: product.mainTaxon.code,
        description: product.description,
        hasBottle: product.hasBottle ?? false,
        averageRating: product.averageRating,
        position: product.sortPosition,
        quantity: 1,
        id: product.variants[0].id,
      };
    }),
  );

  return sortObjectByKey(waterArray.concat(...accessoriesArray), 'position');
};

const productSelector = (product) => {
  return {
    ...product,
    aquaDetails: resolveAquaDetails(product),
    productName: product.translations[i18next.language].name,
    primaryImage: resolvePrimaryImagePath(product.images),
  };
};

const accessoryProductsSelector = (accessories) => {
  return accessories.map((product) => {
    return {
      id: product.variants[0]?.id,
      productName: product.translations[i18next.language].name,
      primaryImage: resolvePrimaryImagePath(product.images),
      mainTaxon: product.mainTaxon.code,
      code: product.code,
      price: product.variants[0]?.price ? convertPrice(product.variants[0]?.price) : undefined,
      priceAsString: product.variants[0]?.price
        ? convertPriceToString(product.variants[0]?.price)
        : undefined,
      quantity: 1,
    };
  });
};

export { allProductsSelector, productSelector, accessoryProductsSelector };
