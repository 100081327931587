import { AnyAction } from 'redux';
import {
  VERIFY_ACCOUNT_SUCCESS,
  FORCE_LOGOUT_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SOCIAL_UNREGISTERED_USER,
} from 'redux/types/AuthTypes';
import { REGISTER_SUCCESS } from 'redux/types/UserTypes';
import { UPDATE_SHARED_STATE_REQUEST } from 'redux/types/UngroupedTypes';

const AuthReducer = (
  state = {
    isLoggedIn: undefined,
    isConfirmed: undefined,
    lastUpdate: undefined,
    forgotPassword: false,
    passwordReset: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case UPDATE_SHARED_STATE_REQUEST:
      if (action.payload.isLoggedIn) {
        return { ...state, ...action.payload };
      }
      return state;
    case VERIFY_ACCOUNT_SUCCESS:
    case LOGIN_SUCCESS:
      return { ...state, ...action.payload };
    case FORCE_LOGOUT_SUCCESS:
    case LOGOUT_SUCCESS:
      return {
        ...action.payload,
        isLoggedIn: false,
        isConfirmed: undefined,
      };
    case REGISTER_SUCCESS:
      return { ...state };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPassword: true };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, forgotPassword: false, passwordReset: true };
    default:
      return state;
  }
};

const PreRegisterReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case SOCIAL_UNREGISTERED_USER:
      return action.payload;
    default:
      return state;
  }
};

export { AuthReducer, PreRegisterReducer };
