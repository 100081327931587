import { useTranslation } from 'react-i18next';
import 'styles/components/CartBillingBlock.scss';
import { getAuthState, getInvoiceTypeState, getSelectedCompanyState } from 'redux/src/StatesGetter';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchAddressRequest,
  setContactInfo,
  setContactInfoModal,
  setSelectedAddressModal,
} from 'redux/actions/AddressActions';
import { addressAsString } from 'modules/AddressUtils';
import {
  fetchCustomerCompaniesRequest,
  setSelectedCompany,
  setSelectedCompanyModal,
} from 'redux/actions/CompanyActions';
import Alerts from 'components/Alerts';
import { useNavigate } from 'react-router-dom';
import { setCompanyInvoiceType, setOrderAddressRequest } from 'redux/actions/OrderActions';
import SelectCompanyModal from 'components/modals/SelectCompanyModal';
import plusIcon from 'assets/images/plus-icon.svg';
import removeCompanyBtn from 'assets/images/remove-company-btn.svg';

const CartBillingBlock = ({ cart }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);

  const companyInvoice = useSelector(getInvoiceTypeState, shallowEqual);
  const selectedCompany = useSelector(getSelectedCompanyState, shallowEqual);

  const { billingAddress, shippingAddress } = cart;

  const openCompaniesModal = () => {
    dispatch(setSelectedCompanyModal({ open: true }));
  };

  const openSelectAddressModal = () => {
    if (isLoggedIn) {
      dispatch(fetchAddressRequest({}));
    }
    dispatch(setSelectedAddressModal({ open: true, setDefault: false, billing: true }));
  };

  const selectCompanyClick = () => {
    if (!isLoggedIn) {
      Alerts.okCancelAlert(t('warning'), t('alerts.youNeedToLogin'), () => {
        navigate('/login');
      });
      return;
    }

    if (billingAddress?.uniqueRegistrationCode && billingAddress?.uniqueRegistrationCode !== '') {
      Alerts.okCancelAlert(t('warning'), t('cart.removeSelectedCompany'), () => {
        console.log('here');
        if (Object.keys(selectedCompany).length === 0) {
          dispatch(
            setOrderAddressRequest({
              toUpdate: {
                email: '',
                shippingAddress: {
                  ...shippingAddress,
                },
                billingAddress: {
                  ...shippingAddress,
                },
              },
            }),
          );
        } else {
          dispatch(setSelectedCompany({}));
        }
        dispatch(setCompanyInvoiceType(false));
      });
      return;
    }

    if (isLoggedIn) {
      dispatch(fetchCustomerCompaniesRequest({}));
    }

    openCompaniesModal();
  };

  const openContactInfoModal = () => {
    dispatch(
      setContactInfo({
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        phoneNumber: billingAddress.phoneNumber,
      }),
    );
    dispatch(setContactInfoModal({ open: true, billing: true }));
  };

  useEffect(() => {
    const companyID = selectedCompany?.uniqueRegistrationCode ?? '';
    if (
      billingAddress?.uniqueRegistrationCode !== companyID &&
      Object.keys(selectedCompany).length !== 0
    ) {
      console.log('changed company', selectedCompany);
      dispatch(
        setOrderAddressRequest({
          toUpdate: {
            email: '',
            shippingAddress: {
              ...shippingAddress,
            },
            billingAddress: {
              street: selectedCompany?.street ?? '',
              city: selectedCompany?.city ?? '',
              additionalInfo: selectedCompany?.additionalInfo ?? '',
              firstName: selectedCompany?.firstName ?? '',
              lastName: selectedCompany?.lastName ?? '',
              phoneNumber: selectedCompany?.phoneNumber ?? '',
              uniqueRegistrationCode: selectedCompany?.uniqueRegistrationCode ?? '',
              commercialRegisterNumber: selectedCompany?.commercialRegisterNumber ?? '',
              bankAccount: selectedCompany?.bankAccount ?? '',
              bankName: selectedCompany?.bank ?? '',
              company: selectedCompany?.company ?? '',
              postcode: '',
            },
          },
        }),
      );
    }
  }, [selectedCompany]);

  const changeInvoiceType = (e) => {
    dispatch(setCompanyInvoiceType(e.target.value === 'company'));
    if (e.target.value !== 'company') {
      dispatch(setSelectedCompany({}));
      dispatch(
        setOrderAddressRequest({
          toUpdate: {
            email: '',
            shippingAddress: {
              ...shippingAddress,
            },
            billingAddress: {
              ...shippingAddress,
            },
          },
        }),
      );
    } else {
      if (isLoggedIn) {
        dispatch(fetchCustomerCompaniesRequest({}));
      }

      openCompaniesModal();
    }
  };

  return (
    <>
      <div className="cart-billing-block mt-3">
        <div className="billing-block-title">{t('cart.billing')}</div>
        <div className="billing-block-invoice-type">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="invoiceType"
              id="invoice-individual"
              value="individual"
              onChange={changeInvoiceType}
              checked={!companyInvoice}
            />
            <label className="form-check-label" htmlFor="invoice-individual">
              {t('cart.invoiceIndiv')}
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="invoiceType"
              id="invoice-company"
              value="company"
              onChange={changeInvoiceType}
              checked={companyInvoice}
            />
            <label className="form-check-label" htmlFor="invoice-company">
              {t('cart.invoiceCompany')}
            </label>
          </div>
        </div>
        {companyInvoice && (
          <div
            className={`billing-block-company${
              billingAddress?.uniqueRegistrationCode &&
              billingAddress?.uniqueRegistrationCode !== ''
                ? ' selected'
                : ''
            }`}
          >
            {billingAddress?.uniqueRegistrationCode &&
              billingAddress?.uniqueRegistrationCode !== '' && (
                <>
                  <div className="billing-title">
                    <span>{t('cart.companyBilling')}</span>
                    {billingAddress?.uniqueRegistrationCode &&
                      billingAddress?.uniqueRegistrationCode !== '' && (
                        <div className="remove-company-btn" onClick={selectCompanyClick}>
                          <img src={removeCompanyBtn} alt="remove" />
                        </div>
                      )}
                  </div>
                  <div className="billing-company-section">
                    <span className="billing-subtitle">{t('account.companyName')}:</span>
                    <span className="billing-text">{`${billingAddress?.company ?? '-'}`}</span>
                  </div>
                  <div className="billing-company-section">
                    <span className="billing-subtitle">{t('account.cif')}:</span>
                    <span className="billing-text">{`${
                      billingAddress?.uniqueRegistrationCode ?? '-'
                    }`}</span>
                  </div>
                  <div className="billing-company-section">
                    <span className="billing-subtitle">{t('account.companyRegistration')}:</span>
                    <span className="billing-text">{`${
                      billingAddress?.commercialRegisterNumber ?? '-'
                    }`}</span>
                  </div>
                </>
              )}
            {(!billingAddress?.uniqueRegistrationCode ||
              billingAddress?.uniqueRegistrationCode === '') && (
              <div className="selected-address-btn" onClick={selectCompanyClick}>
                <img src={plusIcon} alt="plus-icon" />
                <span>{t('cart.addCompanyData')}</span>
              </div>
            )}
          </div>
        )}
        {billingAddress && (
          <div className="billing-block-contact">
            <div className="billing-title">
              <span>{t('cart.billingContacts')}</span>
              {!companyInvoice && (
                <span className="modification-btn" onClick={openContactInfoModal}>
                  {t('cart.modify')}
                </span>
              )}
            </div>
            <span className="billing-text">{`${billingAddress?.firstName ?? ''} ${
              billingAddress?.lastName ?? ''
            }`}</span>
            <span className="billing-text">{`${billingAddress?.phoneNumber ?? ''}`}</span>
          </div>
        )}
        {!billingAddress && !companyInvoice && (
          <div className="selected-address-btn" onClick={openSelectAddressModal}>
            {t('selectAddressText')}
          </div>
        )}
        <div className="billing-block-address">
          <div className="billing-title">
            <span>{t('cart.billingAddress')}</span>
            {billingAddress && !companyInvoice && (
              <span className="modification-btn" onClick={openSelectAddressModal}>
                {t('cart.modify')}
              </span>
            )}
          </div>
          {billingAddress && (
            <>
              <span className="billing-text">
                {billingAddress ? addressAsString(billingAddress) : null}
              </span>
              <span className="billing-text">{billingAddress?.additionalInfo}</span>
            </>
          )}
        </div>
      </div>
      <SelectCompanyModal />
    </>
  );
};

export default CartBillingBlock;
