export const parseToJson = (params) => {
  try {
    if (typeof params === 'string') {
      return JSON.parse(params);
    }
    return params;
  } catch {
    return {};
  }
};
