import ApiUtils from 'modules/ApiUtils';
import { default as ApiConstants } from 'constants/ApiConstants.json';

const getAddressesAPI = () => ApiUtils.getRequest(ApiConstants.FETCH_ADDRESSES_ENDPOINT);

const createAddressAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.CREATE_ADDRESS_ENDPOINT, payload);

const getAddressByIdAPI = ({ id }) =>
  ApiUtils.getRequest(`${ApiConstants.FETCH_ADDRESSES_ENDPOINT}/${id}`);

const updateAddressByIdAPI = ({ id, payload }) =>
  ApiUtils.putRequest(`${ApiConstants.UPDATE_ADDRESS_ENDPOINT}/${id}`, payload);

const deliveryZoneCheckAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.DELIVERY_ZONE_CHECK_ENDPOINT, payload);

const deleteAddressByIdAPI = ({ id }) =>
  ApiUtils.deleteRequest(`${ApiConstants.DELETE_ADDRESS_ENDPOINT}/${id}`);

export {
  getAddressesAPI,
  createAddressAPI,
  getAddressByIdAPI,
  updateAddressByIdAPI,
  deliveryZoneCheckAPI,
  deleteAddressByIdAPI,
};
