export const GET_DAY_SCHEDULE_REQUEST = 'GET_DAY_SCHEDULE_REQUEST';
export const GET_DAY_SCHEDULE_ERROR = 'GET_DAY_SCHEDULE_ERROR';
export const GET_DAY_SCHEDULE_SUCCESS = 'GET_DAY_SCHEDULE_SUCCESS';

export const GET_WEEKLY_SCHEDULE_REQUEST = 'GET_WEEKLY_SCHEDULE_REQUEST';
export const GET_WEEKLY_SCHEDULE_ERROR = 'GET_WEEKLY_SCHEDULE_ERROR';
export const GET_WEEKLY_SCHEDULE_SUCCESS = 'GET_WEEKLY_SCHEDULE_SUCCESS';

export const GET_INTERVALS_SCHEDULE_REQUEST = 'GET_INTERVALS_SCHEDULE_REQUEST';
export const GET_INTERVALS_SCHEDULE_ERROR = 'GET_INTERVALS_SCHEDULE_ERROR';
export const GET_INTERVALS_SCHEDULE_SUCCESS = 'GET_INTERVALS_SCHEDULE_SUCCESS';

export const WEEKLY_SCHEDULE_MODAL = 'WEEKLY_SCHEDULE_MODAL';
