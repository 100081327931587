import 'styles/components/HomeProductCard.scss';
import { useEffect, useState } from 'react';
import whiteCartIcon from 'assets/images/cart_white_icon.svg';
import { Link } from 'react-router-dom';
import { aquaImage } from 'modules/ImageUtils';
import { getCartState, getGeneralInfoState, getSelectedAddressState } from 'redux/src/StatesGetter';
import { shallowEqual, useSelector } from 'react-redux';
import { productPriceObj } from 'modules/ProductUtils';
import PacketPrice from 'components/PacketPrice';
import { resolveAccessoriesOnCart, resolveBottlesOnCart } from 'modules/OrderUtils';
import { useTranslation } from 'react-i18next';

type QuantityObjectType = {
  pvqpId: undefined | number;
  productVariantId: undefined | number;
  image: undefined | string;
  originalPrice: undefined | number;
  price: undefined | number;
  originalPriceAsString: undefined | string;
  priceAsString: undefined | string;
  quantity: undefined | number;
};

const HomeProductCard = ({ item, openModal }) => {
  const { aquaDetails, image, quantity, code, hasBottle, productName } = item;
  const { t, i18n } = useTranslation();

  const generalInfo = useSelector(getGeneralInfoState, shallowEqual);
  const selectedAddress = useSelector(getSelectedAddressState, shallowEqual);
  const cart = useSelector(getCartState, shallowEqual);

  const { delivery } = selectedAddress;

  const [quantityObject, setQuantityObject] = useState<QuantityObjectType>({
    pvqpId: undefined,
    productVariantId: undefined,
    image: undefined,
    originalPrice: undefined,
    price: undefined,
    priceAsString: undefined,
    originalPriceAsString: undefined,
    quantity: undefined,
  });

  useEffect(() => {
    setQuantityObject(productPriceObj(aquaDetails, quantity));
  }, [quantity, item]);

  const setOpenModal = () => {
    openModal(item);
  };

  return (
    <div className="product-card">
      <div className="product-card-image">
        <Link to={`/product/${code}`}>
          <img src={quantityObject.image} alt={code} />
        </Link>
      </div>
      <div className="product-card-content">
        <PacketPrice
          quantity={quantity}
          code={code}
          productName={productName}
          priceAsString={quantityObject.priceAsString}
          price={quantityObject.price}
          originalPrice={quantityObject.originalPrice}
          originalPriceAsString={quantityObject.originalPriceAsString}
          bottlePacket={quantity > 1}
        />
        <div
          className={`add-to-cart ${
            (quantity + resolveBottlesOnCart(cart) > generalInfo.maxQuantity && hasBottle) ||
            (delivery && (!delivery?.ok || delivery?.type !== 'deliver'))
              ? 'disabled'
              : ''
          }`}
          onClick={setOpenModal}
        >
          <img src={whiteCartIcon} alt="cart" />
          <span>{t('add')}</span>
        </div>
      </div>
    </div>
  );
};

export default HomeProductCard;
