import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { CONTACT_US_REQUEST, PERSONAL_DATA_REQUEST, REGISTER_REQUEST } from 'redux/types/UserTypes';
import { contactAPI, personalDataAPI, registerAPI } from 'redux/api/UserApi';
import {
  contactUsError,
  contactUsSuccess,
  personalDataError,
  personalDataSuccess,
  registerError,
  registerSuccess,
} from 'redux/actions/UserActions';
import Alerts from 'components/Alerts';
import firebase from 'firebase';
import { requestLogin } from 'redux/actions/AuthActions';
import ErrorType from 'types/Error';
import i18next from 'i18next';
import NavigationService from '../../services/NavigationService';

function* register(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const registerChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(registerChannel);
    try {
      const response = yield call(registerAPI, { ...payload });
      yield put(registerSuccess(response.data.user));
      Alerts.successAlert(`${i18next.t('alerts.registerCheck')}`, `${i18next.t('close')}`, () => {
        NavigationService.navigate('/login');
      });
    } catch (error: ErrorType | any) {
      if (error.status === 400) {
        Alerts.errorAlert(error.message);
        yield put(registerError({}));
      } else {
        yield put(registerError(`${error.message}`));
      }
    }
  }
}

function* downloadPersonalData(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const downloadPersonalDataChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(downloadPersonalDataChannel);
    try {
      yield call(personalDataAPI, payload);
      Alerts.successAlert(`${i18next.t('settings.dataHasBeenSent')}`);
      yield put(personalDataSuccess({}));
    } catch ({ message }) {
      yield put(personalDataError({ message }));
    }
  }
}

function* sendContactData(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const sendContactDataChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(sendContactDataChannel);
    try {
      yield call(contactAPI, payload);
      Alerts.successAlert(`${i18next.t('contactRequestMessage')}`);
      yield put(contactUsSuccess({}));
    } catch ({ message }) {
      yield put(contactUsError({ message }));
    }
  }
}

function* userSaga() {
  yield fork(register, REGISTER_REQUEST);
  yield fork(downloadPersonalData, PERSONAL_DATA_REQUEST);
  yield fork(sendContactData, CONTACT_US_REQUEST);
}

export default userSaga;
