import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/DownloadDataModal.scss';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Alerts from 'components/Alerts';
import React, { useEffect, useState } from 'react';
import {
  getDownloadDataModalState,
  getSelectAddressModalState,
  getUserProfileState,
} from 'redux/src/StatesGetter';
import { personalDataRequest } from 'redux/actions/UserActions';
import Validators from '../../modules/Validators';
import { setDownloadDataModal } from '../../redux/actions/ProfileActions';

const DownloadDataModal = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const profile = useSelector(getUserProfileState, shallowEqual);
  const modalState = useSelector(getDownloadDataModalState, shallowEqual);

  const closeDownloadDataModal = () => {
    dispatch(setDownloadDataModal({ open: false }));
  };

  const requestDownloadData = () => {
    dispatch(personalDataRequest({}));
    closeDownloadDataModal();
  };

  return (
    <Modal
      show={modalState.open}
      onHide={closeDownloadDataModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="download-data-modal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">{t('settings.myData')}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="download-data-text">
          {t('settings.personalDataEmail')}
          <b>{` ${profile.email}`}</b>
        </div>
        <div className="modal-review-submit-wrapper">
          <div className="modal-review-submit green-button" onClick={requestDownloadData}>
            {t('settings.sendData')}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DownloadDataModal;
