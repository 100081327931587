const profilePlaceholder = {
  id: undefined,
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  phoneNumber: '',
  subscribedToNewsletter: false,
  useDataInAnonymousMode: false,
  defaultAddress: null,
};

const profileSelector = (profileData) => {
  const {
    id,
    email,
    firstName,
    lastName,
    fullName,
    phoneNumber,
    subscribedToNewsletter,
    useDataInAnonymousMode,
    defaultAddress,
  } = profileData;
  return {
    id,
    email,
    firstName,
    lastName,
    fullName,
    phoneNumber,
    subscribedToNewsletter,
    useDataInAnonymousMode,
    defaultAddress: defaultAddress?.id
      ? `/api/ro/v2/shop/account/addresses/${defaultAddress.id}`
      : defaultAddress ?? null,
  };
};

export { profilePlaceholder, profileSelector };
