import { AnyAction } from 'redux';
import { GET_DEVICE_REG_ID_SUCCESS } from 'redux/types/DeviceTypes';

const DeviceReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case GET_DEVICE_REG_ID_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export { DeviceReducer };
