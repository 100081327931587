import { default as ApiConstants } from 'constants/ApiConstants.json';
import ApiUtils from 'modules/ApiUtils';

const loginUserAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.LOGIN_ENDPOINT, payload);

const socialLoginAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.SOCIAL_LOGIN_ENDPOINT, payload);

const forgotPasswordAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.FORGOT_PASSWORD_ENDPOINT, payload);

const resetPasswordAPI = ({ token, payload }) =>
  ApiUtils.patchRequest(`${ApiConstants.RESET_PASSWORD_ENDPOINT}/${token}`, payload, {
    'Content-Type': 'application/merge-patch+json; charset=utf-8',
  });

const changePasswordApi = ({ id, passData }) =>
  ApiUtils.putRequest(`${ApiConstants.CHANGE_PASSWORD_ENDPOINT}/${id}/password`, { ...passData });

const requestDeleteAccountAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.REQUEST_DELETE_ACCOUNT_ENDPOINT, payload);

const verifyAccountAPI = ({ token }) =>
  ApiUtils.patchRequest(
    `${ApiConstants.VERIFY_ACCOUNT_ENDPOINT}/${token}`,
    {},
    {
      'Content-Type': 'application/merge-patch+json; charset=utf-8',
    },
  );

export {
  loginUserAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
  changePasswordApi,
  socialLoginAPI,
  requestDeleteAccountAPI,
  verifyAccountAPI,
};
