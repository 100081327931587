import 'styles/components/InputsHelper.scss';
import 'styles/components/modals/SocialRegisterModal.scss';

import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAuthState, getUserPreRegisterState } from 'redux/src/StatesGetter';
import { requestLogin } from 'redux/actions/AuthActions';
import Alerts from 'components/Alerts';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import labels from 'react-phone-number-input/locale/en.json';
import dropIcon from 'assets/images/dropdown-arrow-icon.svg';
import { isValidPhoneNumber } from 'react-phone-number-input';

const SocialRegisterModal = ({ showModal, modalClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const socialData = useSelector(getUserPreRegisterState, shallowEqual);

  const [socialPhoneNumber, setSocialPhoneNumber] = useState('');
  const [socialPhonePrefix, setSocialPhonePrefix] = useState('+40');
  const [socialEmail, setSocialEmail] = useState('');
  const [socialLastName, setSocialLastName] = useState('');
  const [socialFirstName, setSocialFirstName] = useState('');
  const [socialAgreeTermsAndConditions, setSocialAgreeTermsAndConditions] = useState(false);
  const [socialAgreePrivacyPolicy, setSocialAgreePrivacyPolicy] = useState(false);
  const [socialSubscribedToNewsletter, setSocialSubscribedToNewsletter] = useState(false);

  useEffect(() => {
    setSocialEmail(socialData?.data?.email ?? '');
    setSocialFirstName(socialData?.data?.firstName ?? '');
    setSocialLastName(socialData?.data?.lastName ?? '');
    setSocialPhoneNumber('');
  }, [socialData]);

  const registerWithSocial = (e) => {
    e.preventDefault();

    if (!socialAgreePrivacyPolicy || !socialAgreePrivacyPolicy) {
      Alerts.warningAlert(t('byNotAccepted'));
      return;
    }

    if (!socialEmail || !socialLastName || !socialFirstName || !socialPhoneNumber) {
      Alerts.warningAlert(t('allFieldsMandatory'));
      return;
    }

    if (!isValidPhoneNumber(`${socialPhonePrefix}${socialPhoneNumber}`)) {
      Alerts.warningAlert(t('alerts.invalidPhoneNumber'));
      return;
    }

    dispatch(
      requestLogin({
        type: socialData.type,
        data: {
          firstName: socialFirstName,
          lastName: socialLastName,
          email: socialData.data.email,
          phoneNumber: `${socialPhonePrefix}${socialPhoneNumber}`,
          socialPlatform: socialData.type,
          socialId: socialData.data.socialId,
          token: socialData.data.token,
          platform: 'web',
          newAccount: true,
          subscribedToNewsletter: socialSubscribedToNewsletter,
        },
      }),
    );
    modalClose();
  };

  return (
    <Modal
      show={showModal}
      onHide={modalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="social-register-modal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">{t('createAccount')}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="telephone-wrapper">
          <div className="form-input prefix-wrapper">
            <select
              autoComplete="off"
              id="social_prefix"
              name="social_prefix"
              className="form-control"
              placeholder=" "
              onChange={(event) => setSocialPhonePrefix(event.target.value)}
              defaultValue="+40"
            >
              {getCountries().map((country) => (
                <option key={country} value={`+${getCountryCallingCode(country)}`}>
                  {labels[country]} +{getCountryCallingCode(country)}
                </option>
              ))}
            </select>
            <label htmlFor="social_prefix">{t('choosePrefix')}</label>
            <div className="countryPrefix">
              <span>{socialPhonePrefix}</span>
              <img src={dropIcon} alt="dropIcon" />
            </div>
          </div>
          <div className="form-input">
            <input
              autoComplete="new-password"
              id="social_telephone"
              name="social_telephone"
              type="text"
              className="form-control"
              placeholder=" "
              value={socialPhoneNumber}
              onChange={(event) => setSocialPhoneNumber(event.target.value)}
            />
            <label htmlFor="social_telephone">{t('phoneNumber')}</label>
          </div>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="social_email"
            name="social_email"
            type="email"
            className="form-control"
            placeholder=" "
            disabled
            value={socialEmail}
            onChange={(event) => setSocialEmail(event.target.value)}
          />
          <label htmlFor="social_email">{t('email')}</label>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="social_lastName"
            name="social_lastName"
            type="text"
            className="form-control"
            placeholder=" "
            value={socialLastName}
            onChange={(event) => setSocialLastName(event.target.value)}
          />
          <label htmlFor="social_lastName">{t('lastName')}</label>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="social_firstName"
            name="social_firstName"
            type="text"
            className="form-control"
            placeholder=" "
            value={socialFirstName}
            onChange={(event) => setSocialFirstName(event.target.value)}
          />
          <label htmlFor="social_firstName">{t('firstName')}</label>
        </div>
        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className="custom-control-input"
            id="social_agree_terms"
            type="checkbox"
            checked={socialAgreeTermsAndConditions}
            onChange={() => setSocialAgreeTermsAndConditions(!socialAgreeTermsAndConditions)}
          />
          <label htmlFor="social_agree_terms" className="custom-control-label">
            {t('iAgreeWith')}{' '}
            <a target="_blank" href="/terms-and-conditions" rel="noreferrer">
              {t('aquaTermsAndConditions')}
            </a>
          </label>
        </div>

        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className="custom-control-input"
            id="social_agree_policy"
            type="checkbox"
            checked={socialAgreePrivacyPolicy}
            onChange={() => setSocialAgreePrivacyPolicy(!socialAgreePrivacyPolicy)}
          />
          <label htmlFor="social_agree_policy" className="custom-control-label">
            {t('iUnderstandAnd')}{' '}
            <a target="_blank" href="/privacy-policy" rel="noreferrer">
              {t('privacyTitle')}
            </a>
          </label>
        </div>
        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className="custom-control-input"
            id="subscribed_to_newsletter"
            type="checkbox"
            checked={socialSubscribedToNewsletter}
            onChange={() => setSocialSubscribedToNewsletter(!socialSubscribedToNewsletter)}
          />
          <label htmlFor="subscribed_to_newsletter" className="custom-control-label">
            {t('settings.marketingCommunication')}
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="submit" className="green-button register-button" onClick={registerWithSocial}>
          {t('createAccount')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SocialRegisterModal;
