import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/AddAddressModal.scss';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Map from 'components/map/Map';
import PlacesAutocomplete from 'components/map/PlacesAutocomplete';
import {
  addAddressRequest,
  setAddressModal,
  setLocalAddress,
  setSelectedAddress,
  updateAddressRequest,
} from 'redux/actions/AddressActions';
import {
  getAuthState,
  getSelectedAddressState,
  getLocalAddressState,
  getUserProfileState,
  getAddressModalState,
  getCartState,
} from 'redux/src/StatesGetter';
import Alerts from 'components/Alerts';
import useAddress from 'modules/AddressFormHook';

const AddressModal = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const selectedAddress = useSelector(getSelectedAddressState, shallowEqual);
  const localAddress = useSelector(getLocalAddressState, shallowEqual);
  const modalState = useSelector(getAddressModalState, shallowEqual);
  const profile = useSelector(getUserProfileState, shallowEqual);
  const { firstName, lastName, phoneNumber } = profile;
  const { shippingAddress, billingAddress } = useSelector(getCartState, shallowEqual);
  const [addressInfo, setAddressInfo] = useState(localAddress.additionalInfo ?? '');
  const [addAddressInfoShow, setAddAddressInfoShow] = useState(!!localAddress.id);

  const addressState = useAddress(localAddress);

  const addAddress = () => {
    if (
      !localAddress.streetNumber ||
      !localAddress.street ||
      !localAddress.city ||
      !localAddress.postcode
    ) {
      Alerts.warningAlert(t('addressMissingFields'));
      return;
    }

    if (!addAddressInfoShow) {
      setAddAddressInfoShow(true);
      return;
    }

    if (localAddress.id) {
      dispatch(
        updateAddressRequest({
          updatedAddress: {
            ...localAddress,
            firstName: addressState.addressFirstName,
            lastName: addressState.addressLastName,
            phoneNumber: addressState.addressNumber,
          },
          shippingAddress: {
            ...shippingAddress,
            ...(!modalState?.billing && {
              ...localAddress,
              firstName: addressState.addressFirstName,
              lastName: addressState.addressLastName,
              phoneNumber: addressState.addressNumber,
            }),
          },
          billingAddress: {
            ...billingAddress,
            ...(Object.keys(billingAddress ?? {}).length === 0 && {
              ...localAddress,
              firstName: addressState.addressFirstName,
              lastName: addressState.addressLastName,
              phoneNumber: addressState.addressNumber,
            }),
          },
          updateSelected: selectedAddress?.id === localAddress?.id,
        }),
      );
    } else if (isLoggedIn) {
      dispatch(
        addAddressRequest({
          newAddress: {
            ...localAddress,
            firstName: firstName ?? '',
            lastName: lastName ?? '',
            phoneNumber: phoneNumber ?? '',
          },
          shippingAddress: {
            ...shippingAddress,
            ...(!modalState?.billing && {
              ...localAddress,
              firstName: firstName ?? '',
              lastName: lastName ?? '',
              phoneNumber: phoneNumber ?? '',
            }),
          },
          billingAddress: {
            ...billingAddress,
            ...((modalState.billing || Object.keys(billingAddress ?? {}).length === 0) && {
              ...localAddress,
              firstName: firstName ?? '',
              lastName: lastName ?? '',
              phoneNumber: phoneNumber ?? '',
            }),
          },
          updateSelected: modalState?.setSelected && !modalState?.billing,
        }),
      );
    } else {
      dispatch(
        setSelectedAddress({
          shippingAddress: {
            ...shippingAddress,
            ...(!modalState.billing && {
              ...localAddress,
              firstName: shippingAddress?.firstName ?? '',
              lastName: shippingAddress?.lastName ?? '',
              phoneNumber: shippingAddress?.phoneNumber ?? '',
            }),
          },
          billingAddress: {
            ...billingAddress,
            ...((modalState.billing || Object.keys(billingAddress ?? {}).length === 0) && {
              ...localAddress,
              firstName: shippingAddress?.firstName ?? '',
              lastName: shippingAddress?.lastName ?? '',
              phoneNumber: shippingAddress?.phoneNumber ?? '',
            }),
          },
          selected: !modalState.billing,
        }),
      );
    }
    dispatch(setAddressModal({ open: false, setSelected: false, billing: false }));
  };

  useEffect(() => {
    setAddAddressInfoShow(!!localAddress.id);
    setAddressInfo(localAddress.additionalInfo ?? '');
  }, [localAddress.id, localAddress.street]);

  useEffect(() => {
    dispatch(setLocalAddress({ additionalInfo: addressInfo }));
  }, [addressInfo]);

  const changeAddressInfo = (e) => {
    setAddressInfo(e.target.value);
  };

  const closeAddressModal = () => {
    dispatch(setAddressModal({ open: false, setSelected: false, billing: false }));
  };

  const [mapInitialized, setMapInitialized] = useState(false);

  return (
    <Modal
      show={modalState?.open ?? false}
      onHide={closeAddressModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addressModal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">{t('deliveryAddress')}</span>
      </Modal.Header>
      <Modal.Body>
        {!addAddressInfoShow && (
          <div className="modal-address-availability-text">{t('addressAvailabilityText')}</div>
        )}
        <Map
          setMapInitialized={setMapInitialized}
          disabled={addAddressInfoShow}
          setAddAddressInfoShow={setAddAddressInfoShow}
        />
        {mapInitialized ? (
          <PlacesAutocomplete mapInitialized={mapInitialized} disabled={addAddressInfoShow} />
        ) : (
          ''
        )}
        {addAddressInfoShow && (
          <div className="address-info-wrapper">
            <div className="form-input">
              <input
                type="text"
                name="address"
                className="form-control"
                placeholder=" "
                onChange={changeAddressInfo}
                value={addressInfo}
              />
              <label htmlFor="address">{t('addressInfoBlock')}</label>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div onClick={addAddress} className="modal-button">
          <span className="modal-button-text">{t('continue')}</span>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default AddressModal;
