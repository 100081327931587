import Company from 'types/Company';

const defaultCompany: Company = {
  id: undefined,
  company: null,
  uniqueRegistrationCode: null,
  commercialRegisterNumber: null,
  bank: null,
  bankAccount: null,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  city: '',
  provinceName: '',
  street: '',
  additionalInfo: '',
};

const companySelector = (data) => {
  return {
    ...defaultCompany,
    company: data?.name ?? null,
    uniqueRegistrationCode: data?.CIF ?? null,
    commercialRegisterNumber: data?.regCom ?? null,
    city: data?.city ?? '',
    provinceName: data?.county ?? '',
    street: `${data?.street} ${data?.streetNumber}` ?? '',
    additionalInfo: data?.others ?? '',
    phoneNumber: data?.phone ?? '',
  };
};

export { defaultCompany, companySelector };
