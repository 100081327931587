import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { FETCH_PROFILE_REQUEST, SET_PROFILE_DATA_REQUEST } from 'redux/types/ProfileTypes';
import {
  fetchProfileError,
  fetchProfileSuccess,
  setProfileDataError,
  setProfileDataSuccess,
} from 'redux/actions/ProfileActions';
import { getProfileAPI, setProfileDataAPI } from 'redux/api/ProfileApi';
import { profileSelector } from 'redux/selector/ProfileSelector';
import { setSelectedAddress } from 'redux/actions/AddressActions';
import Alerts from 'components/Alerts';
import i18next from 'i18next';

function* getProfile(actionType) {
  const patientProfileChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(patientProfileChannel);
    try {
      const response = yield call(getProfileAPI, {});
      yield put(fetchProfileSuccess(profileSelector(response.data)));

      if (payload.login && response.data.defaultAddress) {
        yield put(
          setSelectedAddress({
            shippingAddress: response.data.defaultAddress,
            billingAddress: {},
            selected: true,
          }),
        );
      }
    } catch ({ message }) {
      yield put(fetchProfileError({ message }));
    }
  }
}

function* setProfileData(actionType) {
  const patientProfileChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(patientProfileChannel);
    try {
      const response = yield call(setProfileDataAPI, { ...payload });
      yield put(setProfileDataSuccess(profileSelector(response.data)));
      Alerts.successAlert(`${i18next.t('alerts.profileChanged')}`);
    } catch ({ message }) {
      yield put(setProfileDataError({ message }));
    }
  }
}

function* ProfileSaga() {
  yield fork(getProfile, FETCH_PROFILE_REQUEST);
  yield fork(setProfileData, SET_PROFILE_DATA_REQUEST);
}

export default ProfileSaga;
