import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { PRODUCT_VARIANT_FETCH_REQUEST } from 'redux/types/ProductVariantTypes';
import { getProductVariantAPI } from 'redux/api/ProductVariantApi';
import {
  productVariantFetchError,
  productVariantFetchSuccess,
} from 'redux/actions/ProductVariantActions';

function* getProductVariant(actionType: ActionPattern<Action<any>>) {
  const getProductVariantChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getProductVariantChannel);
    try {
      const safeAction = {
        itemsPerPage: 30,
        page: 1,
        ...payload,
      };
      const response = yield call(getProductVariantAPI, safeAction);
      yield put(
        productVariantFetchSuccess({
          data: response.data,
        }),
      );
    } catch ({ message }) {
      yield put(productVariantFetchError({ message }));
    }
  }
}

function* productVariantSaga() {
  yield fork(getProductVariant, PRODUCT_VARIANT_FETCH_REQUEST);
}

export default productVariantSaga;
