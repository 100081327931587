import { formatAddressData } from 'modules/AddressUtils';
import Address from 'types/Address';

const defaultShippingAddress: Address = {
  id: undefined,
  firstName: '',
  lastName: '',
  phoneNumber: null,
  countryCode: 'RO',
  provinceCode: 'RO-B',
  provinceName: null,
  street: '',
  streetNumber: '',
  city: '',
  postcode: null,
  latitude: '44.429584',
  longitude: '26.103231',
  additionalInfo: null,
};

const defaultBillingAddress: Address = {
  ...defaultShippingAddress,
  company: null,
  commercialRegisterNumber: null,
  bankName: null,
  bankAccount: null,
  uniqueRegistrationCode: null,
};

const addressesSelector = (addresses): Address[] => {
  return addresses.map((address): Address => {
    return formatAddressData(address);
  });
};

export { defaultShippingAddress, defaultBillingAddress, addressesSelector };
