import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { GET_DAY_SCHEDULE_REQUEST, GET_WEEKLY_SCHEDULE_REQUEST } from 'redux/types/ScheduleTypes';
import { Action } from 'redux';
import {
  getDayScheduleError,
  getDayScheduleSuccess,
  getWeeklyScheduleError,
  getWeeklyScheduleSuccess,
} from 'redux/actions/ScheduleActions';
import { todayScheduleApi, weeklyScheduleApi } from 'redux/api/ScheduleApi';
import { scheduleSelector } from '../selector/ScheduleSelector';

function* daySchedule(actionType: ActionPattern<Action<any>>) {
  const dayScheduleChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(dayScheduleChannel);
    const { day } = payload;
    try {
      const response = yield call(todayScheduleApi, { day });
      yield put(getDayScheduleSuccess({ ...response.data }));
    } catch ({ message }) {
      yield put(getDayScheduleError({ day, timeStart: '', timeEnd: '' }));
    }
  }
}

function* weeklySchedule(actionType: ActionPattern<Action<any>>) {
  const weeklyScheduleChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(weeklyScheduleChannel);
    try {
      const schedule = yield call(weeklyScheduleApi, { numOfDays: 7 });
      yield put(
        getWeeklyScheduleSuccess(
          scheduleSelector({ ...schedule.data['week-1'], ...schedule.data['week-2'] }),
        ),
      );
    } catch ({ message }) {
      yield put(getWeeklyScheduleError({ message }));
    }
  }
}

function* scheduleSaga() {
  yield fork(daySchedule, GET_DAY_SCHEDULE_REQUEST);
  yield fork(weeklySchedule, GET_WEEKLY_SCHEDULE_REQUEST);
}

export default scheduleSaga;
