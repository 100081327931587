export const getErrorState = (state) => state.errorState;
export const getAuthState = (state) => state.authState;
export const getGeneralInfoState = (state) => state.generalInfoState;
export const getAllProductsState = (state) => state.allProductsState;
export const getProductState = (state) => state.productState;
export const getUserProfileState = (state) => state.userProfileState;
export const getUserPreRegisterState = (state) => state.userPreRegisterState;
export const getActiveOrdersState = (state) => state.activeOrdersState;
export const getInactiveOrdersState = (state) => state.inactiveOrdersState;
export const getLastOrderState = (state) => state.lastOrderState;
export const getOrderDetailsState = (state) => state.orderDetailsState;
export const getCartState = (state) => state.cartState;
export const getOrderItemState = (state) => state.orderItemState;
export const getShippingMethodsState = (state) => state.shippingMethodsState;
export const getAvailableShippingMethodsState = (state) => state.availableShippingMethodsState;
export const getPaymentMethodsState = (state) => state.paymentMethodsState;
export const getAccessoryProductsState = (state) => state.accessoryProductsState;
export const getSelectedAddressState = (state) => state.selectedAddressState;
export const getAddressListState = (state) => state.addressListState;
export const getLocalAddressState = (state) => state.localAddressState;
export const getAddressModalState = (state) => state.addressModalState;
export const getSelectAddressModalState = (state) => state.selectAddressModalState;
export const getContactInfoModalState = (state) => state.contactInfoModalState;
export const getContactInfoState = (state) => state.contactInfoState;
export const getTodayScheduleState = (state) => state.todayScheduleState;
export const getWeeklyScheduleModalState = (state) => state.weeklyScheduleModalState;
export const getWeeklyScheduleState = (state) => state.weeklyScheduleState;
export const getCustomerCompaniesState = (state) => state.customerCompaniesState;
export const getSelectedCompanyState = (state) => state.selectedCompanyState;
export const getLocalCompanyState = (state) => state.localCompanyState;
export const getCompanyModalState = (state) => state.companyModalState;
export const getSelectCompanyModalState = (state) => state.selectCompanyModalState;
export const getDownloadDataModalState = (state) => state.downloadDataModalState;
export const getDeviceState = (state) => state.deviceState;
export const getInvoiceTypeState = (state) => state.invoiceTypeState;
export const getReviewModalState = (state) => state.reviewModalState;
