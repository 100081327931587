import pin from 'assets/images/suggestion-pin.svg';
import usePlacesAutocomplete, { getGeocode, getLatLng, getDetails } from 'use-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getEnv } from 'services/EnvService';
import { addressAsString, formatAddressData } from 'modules/AddressUtils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useOnclickOutside from 'react-cool-onclickoutside';
import { setLocalAddress } from 'redux/actions/AddressActions';
import { getLocalAddressState } from 'redux/src/StatesGetter';

const PlacesAutocomplete = ({ mapInitialized, disabled }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 200 });

  const apiKey = getEnv('GOOGLE_MAP_API') ?? '';

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const localAddress = useSelector(getLocalAddressState, shallowEqual);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });

    dispatch(
      setLocalAddress({
        ...formatAddressData({
          ...results[0],
          ...localAddress,
          lat: `${results[0].geometry.location.lat()}`,
          lng: `${results[0].geometry.location.lng()}`,
        }),
      }),
    );
  };

  useEffect(() => {
    setValue(
      addressAsString(localAddress),
      localAddress.street === '' ||
        localAddress.streetNumber === '' ||
        localAddress.postcode === '',
    );
  }, [localAddress]);

  const changeAutocompleteInput = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="autocomplete-places-wrapper" ref={ref}>
      <div className="form-input">
        <input
          value={value}
          type="text"
          name="address"
          onChange={changeAutocompleteInput}
          disabled={!mapInitialized || disabled}
          className="form-control"
          placeholder=" "
        />
        <label htmlFor="address">{t('deliveryAddressPlaceholder')}</label>
      </div>
      {data.length > 0 && !disabled ? (
        <div className="delivery-address-results">
          <div className="autocomplete-suggestion-text-head">{t('selectAddressText')}</div>
          {status === 'OK' &&
            data.map(({ place_id: placeId, description }) => (
              <div
                key={placeId}
                className="autocomplete-suggestion"
                onClick={() => handleSelect(description)}
              >
                <img src={pin} height="20" width="17" alt="pin" />
                {description}
              </div>
            ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
