import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'i18n/locales/en.json';
import ro from 'i18n/locales/ro.json';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'ro',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: en,
      },
      ro: {
        translation: ro,
      },
    },
  })
  .then((r) => {});

export default i18n;
