import 'styles/components/CartSuggestions.scss';
import AccessoryBlock from 'components/SuggestionBlock';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const CartSuggestions = ({ products }) => {
  const { t, i18n } = useTranslation();
  const [hideAccessories, setHideAccessories] = useState(false);
  const toggleAccessories = () => setHideAccessories(!hideAccessories);

  return (
    <div className={`cart-suggestions-wrapper ${hideAccessories ? 'hidden' : ''}`}>
      <div className="cart-suggestions-header">
        <span className="cart-suggestions-title">{t('cart.addAccessories')}</span>
        <span className="toggle-suggestions-button" onClick={toggleAccessories}>
          {hideAccessories ? t('cart.display') : t('cart.hide')}
        </span>
      </div>
      <div className="cart-suggestions-content">
        {products?.map((product) => {
          return <AccessoryBlock key={product.id} item={product} />;
        })}
      </div>
    </div>
  );
};

export default CartSuggestions;
