import 'styles/pages/Auth.scss';
import warningIcon from 'assets/images/warning-icon.svg';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forgotPasswordRequest } from 'redux/actions/AuthActions';
import Alerts from 'components/Alerts';

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const onPress = (e) => {
    e.preventDefault();
    if (!email) {
      Alerts.warningAlert(t('resetPasswordError'));
      return;
    }
    dispatch(forgotPasswordRequest({ email }));
  };

  return (
    <section className="forgot-password">
      <form onSubmit={onPress}>
        <div className="title-section">
          <h1>{t('resetPassword')}</h1>
        </div>
        <div className="info-message">
          <img src={warningIcon} alt="warning-icon" />
          {t('resetPasswordText')}
        </div>
        <div className="form-input">
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder=" "
          />
          <label htmlFor="email">{t('email')}</label>
        </div>
        <button type="submit" className="green-button">
          {t('resetPassword')}
        </button>
      </form>
    </section>
  );
};

export default ForgotPassword;
