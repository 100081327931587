import 'styles/components/modals/Modal.scss';
import 'styles/components/modals/AddToCartModal.scss';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { aquaImage } from 'modules/ImageUtils';
import PacketPrice from 'components/PacketPrice';
import QuantityChange from 'components/QuantityChange';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeOrderItemQuantityRequest } from 'redux/actions/OrderActions';
import { resolveAccessoriesOnCart, resolveBottlesOnCart } from 'modules/OrderUtils';
import Order from 'types/Order';
import { getCartState, getGeneralInfoState, getOrderItemState } from 'redux/src/StatesGetter';
import Alerts from 'components/Alerts';

const EditCartItemModal = ({ cartItemModalVisibility, closeCartItemModal, item }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    productName,
    quantity,
    total,
    totalAsString,
    totalBeforeDiscount,
    totalBeforeDiscountAsString,
    product,
  } = item;
  const { isPacket, image, quantityPriceItem, code } = product;
  const [quantitySelected, setQuantitySelected] = useState(quantity);
  const [isAccessory, setIsAccessory] = useState(code !== '11L' && code !== '19L');
  const [itemsInCart, setItemsInCart] = useState(0);
  const cart: Order = useSelector(getCartState, shallowEqual);

  const generalInfo = useSelector(getGeneralInfoState, shallowEqual);

  const selectedProduct = useSelector(getOrderItemState, shallowEqual);
  console.log(selectedProduct);

  useEffect(() => {
    setQuantitySelected(quantity);
  }, [item]);

  const setQuantity = (changeQuantityBy) => {
    const newQuantity = quantitySelected + changeQuantityBy;

    if (itemsInCart + changeQuantityBy > 6) {
      Alerts.warningAlert(t('product.maxBottlesReached'));
      return;
    }

    setQuantitySelected(newQuantity);
    dispatch(
      changeOrderItemQuantityRequest({
        orderItemId: `${item.id}`,
        newQuantity,
        notes: '',
      }),
    );
  };

  useEffect(() => {
    setQuantitySelected(item.quantity);
    setIsAccessory(code !== '11L' && code !== '19L');
  }, [item]);

  useEffect(() => {
    if (!isAccessory) {
      setItemsInCart(resolveBottlesOnCart(cart));
    } else {
      setItemsInCart(resolveAccessoriesOnCart(cart));
    }
  }, [cart]);

  return (
    <Modal
      show={cartItemModalVisibility}
      onHide={closeCartItemModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-to-cart-modal"
    >
      <Modal.Header closeButton>
        <span className="modal-header-text">{t('amount')}</span>
      </Modal.Header>
      <Modal.Body>
        <span className="product-name">
          {isPacket ? `${t('bidon')} ${productName}` : productName}
        </span>
        <img className="product-image" src={image} alt={productName} />

        <QuantityChange
          quantitySelected={quantitySelected}
          itemsInCart={itemsInCart}
          setQuantity={setQuantity}
          accessory={isAccessory}
          maxQuantity={generalInfo.maxQuantity}
        />

        <div className="selected-packet-preview">
          <img className="smallImage" src={quantityPriceItem?.image ?? image} alt={productName} />
          <div>
            <PacketPrice
              quantity={quantitySelected}
              code={code}
              productName={productName}
              priceAsString={totalAsString}
              price={total}
              originalPrice={totalBeforeDiscount}
              originalPriceAsString={totalBeforeDiscountAsString}
              bottlePacket={isPacket}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditCartItemModal;
