import 'styles/components/SuggestionBlock.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { aquaImage } from 'modules/ImageUtils';
import whiteCartIcon from 'assets/images/cart_white_icon.svg';
import { addOrderItemRequest } from 'redux/actions/OrderActions';
import Order from 'types/Order';
import { getCartState } from 'redux/src/StatesGetter';
import { resolveAccessoriesOnCart } from 'modules/OrderUtils';
import { useTranslation } from 'react-i18next';

const SuggestionBlock = ({ item }) => {
  const { t, i18n } = useTranslation();
  const { id, primaryImage, code, mainTaxon, productName, priceAsString } = item;
  const cart: Order = useSelector(getCartState, shallowEqual);

  const dispatch = useDispatch();
  const addToCart = () =>
    dispatch(
      addOrderItemRequest({
        productCode: code,
        productVariantCode: code,
        quantity: 1,
        notes: '',
      }),
    );

  return (
    <div className="suggestion-block">
      <img className="suggestion-image" src={primaryImage} alt={productName} />
      <div className="suggestion-data">
        <span className="suggestion-name">{productName}</span>
        <span className="suggestion-price">{priceAsString}</span>
      </div>
      <button type="button" className="add-to-cart" onClick={addToCart}>
        <img src={whiteCartIcon} alt="cart" />
        <span>{t('add')}</span>
      </button>
    </div>
  );
};

export default SuggestionBlock;
