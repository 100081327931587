import 'styles/pages/Auth.scss';

import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StorageService from 'services/StorageService';
import { registerRequest } from 'redux/actions/UserActions';
import Alerts from 'components/Alerts';
import { getEnv } from 'services/EnvService';
import Validators from 'modules/Validators';
import showPassIcon from 'assets/images/show-password.svg';
import hidePassIcon from 'assets/images/hide-password.svg';
import SocialLogin from 'components/SocialLogin';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import labels from 'react-phone-number-input/locale/en.json';
import dropIcon from 'assets/images/dropdown-arrow-icon.svg';
import { isValidPhoneNumber } from 'react-phone-number-input';

const Register = () => {
  const { t, i18n } = useTranslation();
  const [selectedLocale, setSelectedLocale] = useState(getEnv('LOCALE'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phonePrefix, setPhonePrefix] = useState('+40');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => setPasswordShown(!passwordShown);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const toggleConfirmPasswordVisibility = () => setConfirmPasswordShown(!confirmPasswordShown);
  const [agreeTermsAndConditions, setAgreeTermsAndConditions] = useState(false);
  const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(false);
  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(false);

  useEffect(() => {
    StorageService.getData('APP_LANGUAGE', getEnv('LOCALE')).then((loc) => {
      setSelectedLocale(loc);
    });
  });

  const onRegisterPress = (e) => {
    e.preventDefault();
    if (
      !agreeTermsAndConditions ||
      !agreePrivacyPolicy ||
      !email ||
      !password ||
      !confirmPassword ||
      !lastName ||
      !firstName ||
      !phoneNumber
    ) {
      Alerts.warningAlert(t('allFieldsMandatory'));
      return;
    }

    if (password !== confirmPassword) {
      Alerts.warningAlert(t('alerts.passwordRepeat'));
      return;
    }

    if (!isValidPhoneNumber(`${phonePrefix}${phoneNumber}`)) {
      Alerts.warningAlert(t('alerts.invalidPhoneNumber'));
      return;
    }

    const isValidEmail = Validators.validateEmail(email);

    if (!isValidEmail) {
      Alerts.warningAlert(t('alerts.invalidEmail'));
      return;
    }

    dispatch(
      registerRequest({
        email,
        password,
        firstName,
        lastName,
        phoneNumber: `${phonePrefix}${phoneNumber}`,
        subscribedToNewsletter,
      }),
    );
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <section className="register">
      <form onSubmit={onRegisterPress}>
        <div className="title-section">
          <h1>{t('createAccount')}</h1>
        </div>
        <div className="social-login">
          <SocialLogin />
        </div>
        <div className="telephone-wrapper">
          <div className="form-input prefix-wrapper">
            <select
              autoComplete="off"
              id="prefix"
              name="prefix"
              className="form-control"
              placeholder=" "
              onChange={(event) => setPhonePrefix(event.target.value)}
              defaultValue="+40"
            >
              {getCountries().map((country) => (
                <option key={country} value={`+${getCountryCallingCode(country)}`}>
                  {labels[country]} +{getCountryCallingCode(country)}
                </option>
              ))}
            </select>
            <label htmlFor="prefix">{t('choosePrefix')}</label>
            <div className="countryPrefix">
              <span>{phonePrefix}</span>
              <img src={dropIcon} alt="dropIcon" />
            </div>
          </div>
          <div className="form-input">
            <input
              autoComplete="off"
              id="telephone"
              name="telephone"
              type="text"
              className="form-control"
              placeholder=" "
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
            <label htmlFor="telephone">{t('phoneNumber')}</label>
          </div>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="email"
            name="email"
            type="email"
            className="form-control"
            placeholder=" "
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <label htmlFor="email">{t('email')}</label>
        </div>
        <div className="password-eye">
          <input
            value={password}
            name="password"
            id="password"
            onChange={(event) => setPassword(event.target.value)}
            type={passwordShown ? 'text' : 'password'}
            className="form-control"
            placeholder=" "
          />
          <label htmlFor="password">{t('password')}</label>
          <div className="eye" onClick={togglePasswordVisibility}>
            <img src={passwordShown ? hidePassIcon : showPassIcon} alt="show-password" />
          </div>
        </div>
        <div className="password-eye">
          <input
            value={confirmPassword}
            name="confirmPassword"
            id="confirmPassword"
            onChange={(event) => setConfirmPassword(event.target.value)}
            type={confirmPasswordShown ? 'text' : 'password'}
            className="form-control"
            placeholder=" "
          />
          <label htmlFor="confirmPassword">{t('confirmPassword')}</label>
          <div className="eye" onClick={toggleConfirmPasswordVisibility}>
            <img src={confirmPasswordShown ? hidePassIcon : showPassIcon} alt="show-password" />
          </div>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="lastName"
            name="lastName"
            type="text"
            className="form-control"
            placeholder=" "
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />
          <label htmlFor="lastName">{t('lastName')}</label>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="firstName"
            name="firstName"
            type="text"
            className="form-control"
            placeholder=" "
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
          <label htmlFor="firstName">{t('firstName')}</label>
        </div>
        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className="custom-control-input"
            id="agree_terms"
            type="checkbox"
            checked={agreeTermsAndConditions}
            onChange={() => setAgreeTermsAndConditions(!agreeTermsAndConditions)}
          />
          <label htmlFor="agree_terms" className="custom-control-label">
            {t('iAgreeWith')}{' '}
            <a target="_blank" href="/terms-and-conditions" rel="noreferrer">
              {t('aquaTermsAndConditions')}
            </a>
          </label>
        </div>

        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className="custom-control-input"
            id="agree_policy"
            type="checkbox"
            checked={agreePrivacyPolicy}
            onChange={() => setAgreePrivacyPolicy(!agreePrivacyPolicy)}
          />
          <label htmlFor="agree_policy" className="custom-control-label">
            {t('iUnderstandAnd')}{' '}
            <a target="_blank" href="/privacy-policy" rel="noreferrer">
              {t('privacyTitle')}
            </a>
          </label>
        </div>
        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className="custom-control-input"
            id="subscribed_to_newsletter"
            type="checkbox"
            checked={subscribedToNewsletter}
            onChange={() => setSubscribedToNewsletter(!subscribedToNewsletter)}
          />
          <label htmlFor="subscribed_to_newsletter" className="custom-control-label">
            {t('settings.marketingCommunication')}
          </label>
        </div>
        <button type="submit" className="green-button register-button" onClick={onRegisterPress}>
          {t('createAccount')}
        </button>
        <button type="button" onClick={handleLogin} className="white-button position-relative">
          {t('alreadyHaveAccount')}
        </button>
      </form>
    </section>
  );
};

export default Register;
