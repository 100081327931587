import { AnyAction } from 'redux';
import {
  FETCH_PROFILE_SUCCESS,
  SET_DOWNLOAD_DATA_MODAL,
  SET_PROFILE_DATA_SUCCESS,
} from 'redux/types/ProfileTypes';
import { profilePlaceholder } from 'redux/selector/ProfileSelector';
import { SELECT_COMPANY_MODAL } from '../types/CompanyTypes';

const ProfileReducer = (state = profilePlaceholder, action: AnyAction) => {
  switch (action.type) {
    case FETCH_PROFILE_SUCCESS:
    case SET_PROFILE_DATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const DownloadDataModalStateReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case SET_DOWNLOAD_DATA_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export { ProfileReducer, DownloadDataModalStateReducer };
