import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getEnv } from 'services/EnvService';
import { getAuthState } from 'redux/src/StatesGetter';
import StorageService from 'services/StorageService';
import { resetPasswordRequest } from 'redux/actions/AuthActions';
import Alerts from 'components/Alerts';
import showPassIcon from 'assets/images/show-password.svg';
import hidePassIcon from 'assets/images/hide-password.svg';

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { token } = useParams();
  const [validationToken, setValidationToken] = useState(token);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  useEffect(() => {
    setValidationToken(validationToken);
  }, [token]);

  const onPress = (e) => {
    e.preventDefault();
    if (!token || !password || !confirmPassword) {
      Alerts.warningAlert(t('allFieldsMandatory'));
      return;
    }
    if (password !== confirmPassword) {
      Alerts.warningAlert(t('account.changePasswordValidation2'));
      return;
    }
    dispatch(
      resetPasswordRequest({ token, newPassword: password, confirmNewPassword: confirmPassword }),
    );
  };

  return (
    <section className="reset-password">
      <form onSubmit={onPress}>
        <div className="title-section">
          <h1>{t('newPassword')}</h1>
        </div>
        <div className="form-input">
          <input
            type="text"
            id="token"
            name="token"
            className="form-control"
            value={validationToken}
            onChange={(event) => setValidationToken(event.target.value)}
            placeholder=" "
            autoComplete="off"
          />
          <label htmlFor="token">{t('token')}</label>
        </div>
        <div className="password-eye">
          <input
            name="password"
            id="password"
            type={passwordShown ? 'text' : 'password'}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className="form-control"
            placeholder=" "
            autoComplete="off"
          />
          <label htmlFor="password">{t('password')}</label>
          <div className="eye" onClick={togglePasswordVisibility}>
            <img src={passwordShown ? hidePassIcon : showPassIcon} alt="show-password" />
          </div>
        </div>
        <div className="password-eye">
          <input
            name="new-password"
            id="new-password"
            type={confirmPasswordShown ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            className="form-control"
            placeholder=" "
            autoComplete="off"
          />
          <label htmlFor="new-password">{t('confirmPassword')}</label>
          <div className="eye" onClick={toggleConfirmPasswordVisibility}>
            <img src={confirmPasswordShown ? hidePassIcon : showPassIcon} alt="show-password" />
          </div>
        </div>
        <button type="submit" className="green-button">
          {t('resetPassword')}
        </button>
      </form>
    </section>
  );
};

export default ResetPassword;
