import { AnyAction } from 'redux';
import {
  FETCH_PROFILE_ERROR,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  SET_DOWNLOAD_DATA_MODAL,
  SET_PROFILE_DATA_ERROR,
  SET_PROFILE_DATA_REQUEST,
  SET_PROFILE_DATA_SUCCESS,
} from 'redux/types/ProfileTypes';

export const fetchProfileRequest = (payload): AnyAction => ({
  type: FETCH_PROFILE_REQUEST,
  payload,
});

export const fetchProfileSuccess = (payload): AnyAction => ({
  type: FETCH_PROFILE_SUCCESS,
  payload,
});

export const fetchProfileError = (payload): AnyAction => ({
  type: FETCH_PROFILE_ERROR,
  payload,
});

export const setProfileDataRequest = (payload): AnyAction => ({
  type: SET_PROFILE_DATA_REQUEST,
  payload,
});

export const setProfileDataSuccess = (payload): AnyAction => ({
  type: SET_PROFILE_DATA_SUCCESS,
  payload,
});

export const setProfileDataError = (payload): AnyAction => ({
  type: SET_PROFILE_DATA_ERROR,
  payload,
});

export const setDownloadDataModal = (payload): AnyAction => ({
  type: SET_DOWNLOAD_DATA_MODAL,
  payload,
});
