import { actionChannel, call, put, take, fork, ActionPattern, select } from 'redux-saga/effects';
import { Action } from 'redux';
import {
  changePasswordError,
  changePasswordSuccess,
  deleteAccountError,
  deleteAccountSuccess,
  forceLogoutError,
  forceLogoutSuccess,
  forgotPasswordError,
  forgotPasswordSuccess,
  requestLoginError,
  requestLoginSuccess,
  requestLogoutError,
  requestLogoutSuccess,
  resendTokenError,
  resetPasswordError,
  resetPasswordSuccess,
  socialPreRegister,
  verifyAccountError,
  verifyAccountSuccess,
} from 'redux/actions/AuthActions';
import {
  loginUserAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
  changePasswordApi,
  socialLoginAPI,
  requestDeleteAccountAPI,
  verifyAccountAPI,
} from 'redux/api/AuthApi';
import {
  CHANGE_PASSWORD_REQUEST,
  VERIFY_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  FORCE_LOGOUT_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  RESEND_TOKEN_REQUEST,
  RESET_PASSWORD_REQUEST,
} from 'redux/types/AuthTypes';
import Alerts from 'components/Alerts';
import StorageService from 'services/StorageService';
import { handleLoginResponse, logOutCleanUp } from 'modules/AuthUtils';
import { fetchProfileRequest } from 'redux/actions/ProfileActions';
import { fetchAddressRequest } from 'redux/actions/AddressActions';
import i18next from 'i18next';
import NavigationService from 'services/NavigationService';
import ErrorType from 'types/Error';
import { getDeviceState } from 'redux/src/StatesGetter';
import { associateDeviceRequest, getDeviceRegIdRequest } from 'redux/actions/DeviceActions';

const resolveLoginApi = (loginType: any, loginData: Record<string, unknown> | null | undefined) => {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    switch (loginType) {
      case 'classic':
        loginUserAPI(loginData)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
        break;
      case 'google':
        socialLoginAPI(loginData)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
        break;
      case 'facebook':
        socialLoginAPI(loginData)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
        break;
      case 'apple':
        // appleLoginAPI(loginData)
        //   .then((response) => {
        //     return resolve(response);
        //   })
        //   .catch((error) => {
        //     return reject(error);
        //   });
        break;
      default:
        loginUserAPI(loginData)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
    }
  });
};

function* logIn(actionType: ActionPattern<Action<any>>) {
  const loginChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(loginChannel);
    try {
      const deviceState = yield select(getDeviceState);
      const { data: tokens } = yield call(resolveLoginApi, payload.type, {
        ...payload.data,
      });
      yield call(handleLoginResponse, tokens);
      if (deviceState.regId) {
        yield put(associateDeviceRequest({ regId: deviceState.regId }));
      }
      yield put(requestLoginSuccess({ isLoggedIn: true }));
      yield put(fetchProfileRequest({ login: true }));
      yield put(fetchAddressRequest({}));
    } catch (error: ErrorType | any) {
      if (error?.status === 404) {
        yield put(socialPreRegister({ ...payload, registerModal: true }));
        yield put(requestLoginSuccess({}));
      } else if (error?.status === 401) {
        Alerts.errorAlert(`${i18next.t('alerts.noMatchingAccount')}`);
        yield put(requestLoginError({ error }));
      } else {
        yield put(requestLoginError({ error }));
      }
    }
  }
}

function* logOut(actionType: ActionPattern<Action<any>>) {
  const logoutChannel = yield actionChannel(actionType);
  while (true) {
    yield take(logoutChannel);
    try {
      yield put(requestLogoutSuccess({}));
      yield call(logOutCleanUp);
      yield put(getDeviceRegIdRequest({}));
    } catch ({ message }) {
      yield put(requestLogoutError({ message }));
    }
  }
}

function* forceLogOut(actionType) {
  const forceLogoutChannel = yield actionChannel(actionType);
  while (true) {
    yield take(forceLogoutChannel);
    try {
      yield put(forceLogoutSuccess({}));
      yield call(StorageService.clearAll);
    } catch ({ message }) {
      yield put(forceLogoutError({ message }));
    }
  }
}

/**
 * in case if user change it from settings
 * @param actionType
 */
function* changePassword(actionType: ActionPattern<Action<any>>) {
  const changePasswordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(changePasswordChannel);
    try {
      yield call(changePasswordApi, { ...payload });
      Alerts.successAlert(`${i18next.t('alerts.passwordUpdated')}`);
      yield put(changePasswordSuccess({}));
    } catch ({ message }) {
      yield put(changePasswordError({ message }));
    }
  }
}

function* forgotPassword(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const forgetPasswordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(forgetPasswordChannel);
    try {
      yield call(forgotPasswordAPI, payload);
      Alerts.successAlert(`${i18next.t('alerts.passwordResetCheck')}`);
      yield put(forgotPasswordSuccess({}));
    } catch ({ message }) {
      yield put(forgotPasswordError({ message }));
    }
  }
}

/**
 * in case if user forgot his password
 * @param actionType
 */
function* resetPassword(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const resetPasswordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(resetPasswordChannel);
    try {
      const { token, newPassword, confirmNewPassword } = payload;
      const response = yield call(resetPasswordAPI, {
        token,
        payload: { newPassword, confirmNewPassword },
      });
      yield put(resetPasswordSuccess({}));
      Alerts.actionAlert(
        `${i18next.t('info')}`,
        `${i18next.t('alerts.passwordUpdated')}`,
        'Ok',
        () => {
          NavigationService.navigate('/login');
        },
      );
    } catch ({ message }) {
      yield put(resetPasswordError({ message }));
    }
  }
}

function* deleteAccount(actionType: ActionPattern<Action<any>>) {
  const deleteAccountChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deleteAccountChannel);
    try {
      const response = yield call(requestDeleteAccountAPI, payload);
      yield put(deleteAccountSuccess(response.data));
      yield put(requestLogoutSuccess({}));
      yield call(StorageService.clearAll);
    } catch ({ message }) {
      yield put(deleteAccountError({ message }));
    }
  }
}

function* verifyAccount(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const verifyAccountChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(verifyAccountChannel);
    try {
      const { token } = payload;
      yield call(verifyAccountAPI, { token });
      Alerts.successAlert(`${i18next.t('alerts.accountActivated')}`, 'Ok', () => {
        NavigationService.navigate('/login');
      });
      yield put(verifyAccountSuccess({}));
    } catch ({ message }) {
      Alerts.errorAlert(`${i18next.t('alerts.tokenInvalid')}`);
      yield put(verifyAccountError({ message }));
    }
  }
}

function* resendToken(actionType) {
  const resendTokenChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(resendTokenChannel);
    try {
      // const response = yield call(resendActivationAPI, payload);
      // yield put(resendTokenSuccess(response.data));
    } catch ({ message }) {
      yield put(resendTokenError({ message }));
    }
  }
}

function* authSaga() {
  yield fork(logIn, LOGIN_REQUEST);
  yield fork(logOut, LOGOUT_REQUEST);
  yield fork(forgotPassword, FORGOT_PASSWORD_REQUEST);
  yield fork(changePassword, CHANGE_PASSWORD_REQUEST);
  yield fork(resetPassword, RESET_PASSWORD_REQUEST);
  yield fork(deleteAccount, DELETE_ACCOUNT_REQUEST);
  yield fork(verifyAccount, VERIFY_ACCOUNT_REQUEST);
  yield fork(forceLogOut, FORCE_LOGOUT_REQUEST);
  yield fork(resendToken, RESEND_TOKEN_REQUEST);
}

export default authSaga;
