import 'styles/components/map/OrderMap.scss';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useEffect, useMemo, useState } from 'react';
import { getEnv } from 'services/EnvService';
import { mapStyle, libraries } from 'constants/MapThemeConstant';
import { useTranslation } from 'react-i18next';

const OrderMap = ({ address, markers }) => {
  const apiKey = getEnv('GOOGLE_MAP_API') ?? '';

  const { t, i18n } = useTranslation();

  const { lat, lng, zoom } = address;

  const [markerState, setMarkerState] = useState('1212313123');

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [mapref, setMapRef] = useState(null);

  useEffect(() => {
    if (mapref) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend({
          lat: marker.latitude,
          lng: marker.longitude,
        });
      });
      // @ts-ignore
      mapref.fitBounds(bounds);
    }
  }, [markers, mapref]);

  const handleOnLoad = (map) => setMapRef(map);

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerClassName="addressMap"
        center={{ lat, lng }}
        zoom={zoom}
        options={{
          styles: mapStyle,
          disableDefaultUI: true,
        }}
        onLoad={handleOnLoad}
      >
        {markers.length > 0 &&
          markers.map((marker) => {
            const now = new Date().getTime();
            return (
              <Marker
                key={`google_marker_${marker.id}_${now}`}
                position={{
                  lat: marker.latitude,
                  lng: marker.longitude,
                }}
                icon={marker.icon}
              />
            );
          })}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default OrderMap;
