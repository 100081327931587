import googleLibPhoneNumber from 'google-libphonenumber';

export const calcPrefix = (phoneNumberFull = '') => {
  try {
    const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();

    const phoneData = phoneUtil.parseAndKeepRawInput(phoneNumberFull);

    return `+${phoneData.getCountryCode()}`;
  } catch (message) {
    return '';
  }
};

export const calcPhone = (phoneNumberFull = '') => {
  try {
    const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();

    const phoneData = phoneUtil.parseAndKeepRawInput(phoneNumberFull);

    return phoneData.getNationalNumber();
  } catch (message) {
    return '';
  }
};

export const checkPhoneValidity = (phoneNumberFull = '') => {
  try {
    const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
    const phoneData = phoneUtil.parseAndKeepRawInput(phoneNumberFull);
    return phoneUtil.isValidNumber(phoneData);
  } catch (message) {
    console.log(message);
    return false;
  }
};
