import { AnyAction } from 'redux';
import { GET_ORDER_ITEM } from 'redux/types/OrderItemTypes';
import { defaultOrderItem } from 'redux/selector/OrderItemSelector';

const OrderItemReducer = (state = defaultOrderItem, action: AnyAction) => {
  switch (action.type) {
    case GET_ORDER_ITEM:
      return action.payload;
    default:
      return state;
  }
};

export { OrderItemReducer };
