/* eslint-disable no-bitwise */
export const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export const stringHashCode = (str: string) => {
  if (!str) {
    return Math.random();
  }
  let hash = 0;
  let i;
  let chr;
  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return Math.abs(hash);
};
