import CalendarConstants from 'constants/CalendarConstants';
import i18next from 'i18next';
import { capitalizeFirstLetter } from 'modules/Utils';

const dateFormat = (datetime) => {
  const d = new Date(datetime);

  return d.toDateString();
};

const addLeadingZeros = (date) => {
  return '0'.concat(date.toString()).slice(-2);
};

const orderHistoryDateFormat = (datetime) => {
  if (!datetime) return '';
  const d = new Date(datetime);
  const locale = i18next.language;
  return `${d.getDate()} ${CalendarConstants[locale].monthNames[d.getMonth()]} ${d.getFullYear()}`;
};

const orderDateETAFormat = (datetimeStart, datetimeEnd) => {
  const dStart = new Date(datetimeStart);
  const dEnd = new Date(datetimeEnd);

  return `${addLeadingZeros(dStart.getHours())}:${addLeadingZeros(
    dStart.getMinutes(),
  )} - ${addLeadingZeros(dEnd.getHours())}:${addLeadingZeros(dEnd.getMinutes())}`;
};

const orderDayFormat = (datetime) => {
  return datetime?.split(' ')[0];
};

const getDayName = () => {
  const locale = i18next.language;
  const d = new Date().toLocaleString(locale === 'ro' ? 'ro-RO' : 'en-GB', { weekday: 'long' });
  return `${d}`;
};

const getDayNameByDate = (date) => {
  const locale = i18next.language;
  const d = new Date(date).toLocaleString(locale === 'ro' ? 'ro-RO' : 'en-GB', { weekday: 'long' });
  return `${capitalizeFirstLetter(d)}`;
};

const getSlotTime = (datetime) => {
  const locale = i18next.language;
  const d = new Date(datetime).toLocaleString(locale === 'ro' ? 'ro-RO' : 'en-GB', {
    hour: 'numeric',
    minute: 'numeric',
  });
  return `${d}`;
};

const getExpirationDate = () => {
  const d = new Date();
  return d.getTime() + 5 * 60000;
};

const checkReviewLaterHour = (date) => {
  const d = new Date();
  const oneHour = 2 * 60 * 1000;
  return date + oneHour < d.getTime();
};

export {
  dateFormat,
  orderHistoryDateFormat,
  orderDateETAFormat,
  getDayName,
  getDayNameByDate,
  getSlotTime,
  orderDayFormat,
  getExpirationDate,
  checkReviewLaterHour,
};
