import { convertPrice, convertPriceToString } from 'modules/CurrencyUtils';
import { sortObjectByKey } from 'modules/Utils';
import Product from 'types/Product';
import AquaDetailsItem from 'types/AquaDetailsItem';
import ProductVariant from 'types/ProductVariant';
import OrderItem from 'types/OrderItem';
import i18next from 'i18next';

const defaultOrderItem: OrderItem = {
  id: undefined,
  quantity: 0,
  total: 0,
  productName: '',
  totalAsString: '',
  subtotal: 0,
  subtotalAsString: '',
  totalBeforeDiscount: 0,
  totalBeforeDiscountAsString: '',
  product: {
    id: undefined,
    code: '',
    isPacket: false,
    image: '',
    aquaDetails: [],
    quantityPriceItem: null,
  },
  variant: {
    id: undefined,
    code: '',
    price: 0,
    priceAsString: '',
    originalPrice: 0,
    originalPriceAsString: '',
    inStock: false,
  },
};

const orderItemSelector = (item: Record<string, any>): OrderItem => {
  const resolveAquaDetails = (aquaDetails): AquaDetailsItem[] => {
    if (!aquaDetails || !aquaDetails.length) {
      return [];
    }

    return aquaDetails.map((aquaDetailsItem: Record<string, any>): AquaDetailsItem => {
      return {
        id: aquaDetailsItem?.pvqpId,
        image: aquaDetailsItem.image,
        price: convertPrice(aquaDetailsItem.price),
        priceAsString: convertPriceToString(aquaDetailsItem.price),
        originalPrice: convertPrice(aquaDetailsItem.originalPrice),
        originalPriceAsString: convertPriceToString(aquaDetailsItem.originalPrice),
        quantity: aquaDetailsItem.quantity,
      };
    });
  };

  const resolveProduct = (product: Record<string, any>): Product => {
    const resolvedAquaDetails = resolveAquaDetails(
      product?.aquaDetails ? sortObjectByKey(product.aquaDetails, 'quantity') : [],
    );

    const applicableQuantityPriceItems = resolvedAquaDetails?.filter(
      (aquaDetailsItem) => aquaDetailsItem.quantity <= item.quantity,
    );

    return {
      id: product?.id,
      code: product?.code,
      isPacket: resolvedAquaDetails.length > 1,
      image: product?.images[0].path,
      aquaDetails: resolvedAquaDetails,
      quantityPriceItem:
        applicableQuantityPriceItems.length > 0 ? applicableQuantityPriceItems.pop() : null,
    };
  };

  const resolveProductVariant = (variant: Record<string, any>): ProductVariant => {
    return {
      id: variant?.id,
      code: variant?.code,
      price: convertPrice(variant.price),
      priceAsString: convertPriceToString(variant.price),
      originalPrice: convertPrice(variant.originalPrice),
      originalPriceAsString: convertPriceToString(variant.originalPrice),
      inStock: variant.inStock,
    };
  };

  return {
    id: item?.id,
    productName: item?.product?.translations
      ? item?.product?.translations[i18next.language ?? 'ro']?.name
      : item?.productName,
    quantity: item.quantity,
    total: convertPrice(item.total),
    totalAsString: convertPriceToString(item.total),
    subtotal: convertPrice(item.subtotal),
    subtotalAsString: convertPriceToString(item.subtotal),
    totalBeforeDiscount: convertPrice(item.variant.originalPrice * item.quantity),
    totalBeforeDiscountAsString: convertPriceToString(item.variant.originalPrice * item.quantity),
    product: resolveProduct(item.product),
    variant: resolveProductVariant(item.variant),
  };
};

export { defaultOrderItem, orderItemSelector };
