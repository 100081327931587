import { AnyAction } from 'redux';
import {
  GET_DAY_SCHEDULE_ERROR,
  GET_DAY_SCHEDULE_SUCCESS,
  WEEKLY_SCHEDULE_MODAL,
  GET_WEEKLY_SCHEDULE_SUCCESS,
} from 'redux/types/ScheduleTypes';

const TodayScheduleReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case GET_DAY_SCHEDULE_SUCCESS:
    case GET_DAY_SCHEDULE_ERROR:
      return action.payload;
    default:
      return state;
  }
};

const WeeklyScheduleReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case GET_WEEKLY_SCHEDULE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const WeeklyScheduleModalReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case WEEKLY_SCHEDULE_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export { TodayScheduleReducer, WeeklyScheduleReducer, WeeklyScheduleModalReducer };
