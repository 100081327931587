import { actionChannel, ActionPattern, call, fork, put, select, take } from 'redux-saga/effects';
import { Action } from 'redux';
import {
  associateDeviceError,
  associateDeviceSuccess,
  getDeviceRegIdError,
  getDeviceRegIdSuccess,
  updateDeviceRegIdError,
  updateDeviceRegIdSuccess,
} from 'redux/actions/DeviceActions';
import firebase from 'firebase';
import {
  ASSOCIATE_DEVICE_REQUEST,
  GET_DEVICE_REG_ID_REQUEST,
  UPDATE_DEVICE_REG_ID_REQUEST,
} from 'redux/types/DeviceTypes';
import { associateDeviceAPI, createDeviceAPI } from '../api/DeviceApi';

function* updateDeviceRegId(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const deviceRegIdChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deviceRegIdChannel);
    try {
      // yield call(UpdateDeviceRegIdAPI, { deviceRegistrationId: payload });
      yield put(updateDeviceRegIdSuccess({}));
    } catch ({ message }) {
      yield put(updateDeviceRegIdError({ message }));
    }
  }
}

function* getDeviceRegId(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const deviceRegIdChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deviceRegIdChannel);
    try {
      const deviceRegId = yield call(firebase.getWebToken);
      if (deviceRegId) {
        console.log(
          `%cDeviceRegistrationId:\n%c${deviceRegId}`,
          'color:#FF5F1F; font-family:monospace; font-size: 15px;',
          'color:#F28C28; font-family:monospace; font-size: 15px;',
        );
        const response = yield call(createDeviceAPI, {
          platform: 2,
          regId: deviceRegId,
          receiveMarketing: false,
          localeCode: 'ro',
        });
        yield put(getDeviceRegIdSuccess({ ...response.data }));
      } else {
        yield put(getDeviceRegIdSuccess({}));
      }
    } catch ({ message }) {
      yield put(getDeviceRegIdError({ message }));
    }
  }
}

function* associateDevice(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const associateDeviceChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(associateDeviceChannel);
    try {
      const { regId } = payload;
      yield call(associateDeviceAPI, { regId });
      yield put(associateDeviceSuccess({}));
    } catch ({ message }) {
      yield put(associateDeviceError({ message }));
    }
  }
}

function* deviceSaga() {
  yield fork(updateDeviceRegId, UPDATE_DEVICE_REG_ID_REQUEST);
  yield fork(getDeviceRegId, GET_DEVICE_REG_ID_REQUEST);
  yield fork(associateDevice, ASSOCIATE_DEVICE_REQUEST);
}

export default deviceSaga;
