/**
 * @upgrade https://firebase.google.com/docs/web/modular-upgrade
 */
import firebase from '@firebase/app-compat';
import '@firebase/messaging-compat';
import PushNotifier from './modules/PushNotifier';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
} else {
  console.warn('This browser does not support firebase messaging!');
}

const handlePushNotification = (payload) => {
  console.log(`%cPush Payload:`, 'color:#FF5F1F; font-family:monospace; font-size: 15px;');
  console.table(payload);
  PushNotifier.onPushReceived(payload);
};

// messaging?.onMessage(handlePushNotification);

// Background Messages
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (message) => {
    const { data: payload } = message;
    handlePushNotification(payload);
  });
}

const getWebToken = () => {
  return messaging
    ?.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY,
    })
    .then((currentToken) => {
      return currentToken;
    })
    .catch((e) => {
      if (e.name === 'AbortError') {
        window.location.reload();
      }
      return null;
    });
};

export default { getWebToken };
