/**
 * @ref https://create-react-app.dev/docs/adding-custom-environment-variables/
 */

const getPrefix = (specific) => {
  if (!specific) return 'REACT_APP';
  return `REACT_APP`;
};

export const getEnv = (key: string, specific = false) => {
  const prefix = getPrefix(specific);
  return process.env[`${prefix}_${key}`];
};

export const isRegister = () => {
  const pathParts = window.location.href.split('/');
  return pathParts.indexOf('register') > -1;
};

export const isLoginOrRegister = () => {
  const pathParts = window.location.href.split('/');
  return (
    pathParts.indexOf('register') > -1 ||
    pathParts.indexOf('login') > -1 ||
    pathParts.indexOf('forgot-password') > -1 ||
    pathParts.indexOf('reset-password') > -1
  );
};

export const isRegisterSteps = () => {
  const pathParts = window.location.href.split('/');
  return (
    pathParts.indexOf('register-step-personal-data') > -1 ||
    pathParts.indexOf('register-step-invoice') > -1 ||
    pathParts.indexOf('register-step-specialization') > -1
  );
};

export const activateAccount = () => {
  const pathParts = window.location.href.split('/');
  return pathParts.indexOf('activate-account') > -1;
};
