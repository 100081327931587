import { storeDispatcher, storeStateGetter } from 'redux/ReduxStore';
import LocalEventsService from 'services/LocalEventsService';
import StorageService from 'services/StorageService';
import { default as StringConstants } from 'constants/StringConstants.json';
import { forceLogoutRequest } from 'redux/actions/AuthActions';

export const registerLocalEvents = () => {
  LocalEventsService.offAll();
  LocalEventsService.on('checkEvents', (data) => {});

  LocalEventsService.on('forceLogout', () => {
    storeDispatcher(forceLogoutRequest({}));
  });
};

export const removeLocalEvents = () => {
  LocalEventsService.offAll();
};
