import { AnyAction } from 'redux';
import {
  ADD_ORDER_ITEM_SUCCESS,
  CHANGE_ORDER_ITEM_QUANTITY_SUCCESS,
  COMPLETE_ORDER_ON_CHECKOUT_SUCCESS,
  CREATE_CART_FROM_ORDER_SUCCESS,
  CREATE_CART_SUCCESS,
  DELETE_CART_SUCCESS,
  FETCH_CART_SUCCESS,
  FETCH_ORDER_DETAILS_ERROR,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_PAYMENT_METHODS_SUCCESS,
  FETCH_ORDER_SHIPPING_METHODS_SUCCESS,
  ACTIVE_ORDERS_FETCH_SUCCESS,
  REMOVE_ORDER_ITEM_SUCCESS,
  SET_ORDER_ADDRESS_SUCCESS,
  SET_ORDER_PAYMENT_METHOD_SUCCESS,
  SET_ORDER_SHIPPING_METHOD,
  INACTIVE_ORDERS_FETCH_SUCCESS,
  FETCH_LAST_ORDER_SUCCESS,
  COMPANY_INVOICE_TYPE,
  REVIEW_MODAL,
  ADD_REVIEW_SUCCESS,
  REVIEW_LATER,
  DELETE_CART_ERROR,
} from 'redux/types/OrderTypes';
import { defaultOrder } from 'redux/selector/OrderSelector';
import OrderItem from 'types/OrderItem';
import Order from 'types/Order';
import { antiDuplication } from 'modules/ArrayUtils';

const ActiveOrdersReducer = (state = { data: [], hasMoreActive: true }, action: AnyAction) => {
  switch (action.type) {
    case ACTIVE_ORDERS_FETCH_SUCCESS:
      return {
        data:
          action.payload.page !== 1
            ? antiDuplication([...state.data, ...action.payload.data], 'id')
            : action.payload.data,
        hasMoreActive: action.payload.data.length === action.payload.perPage,
      };
    default:
      return state;
  }
};

const InactiveOrdersReducer = (state = { data: [], hasMoreInactive: true }, action: AnyAction) => {
  switch (action.type) {
    case INACTIVE_ORDERS_FETCH_SUCCESS:
      return {
        data:
          action.payload.page !== 1
            ? antiDuplication([...state.data, ...action.payload.data], 'id')
            : action.payload.data,
        hasMoreInactive: action.payload.data.length === action.payload.perPage,
      };
    default:
      return state;
  }
};

const LastOrdersReducer = (state: Order = defaultOrder, action: AnyAction) => {
  switch (action.type) {
    case FETCH_LAST_ORDER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const OrderDetailsReducer = (state: Order = defaultOrder, action: AnyAction) => {
  switch (action.type) {
    case FETCH_ORDER_DETAILS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const CartReducer = (state: Order = defaultOrder, action: AnyAction) => {
  switch (action.type) {
    case FETCH_CART_SUCCESS:
    case CREATE_CART_SUCCESS:
    case CREATE_CART_FROM_ORDER_SUCCESS:
    case CHANGE_ORDER_ITEM_QUANTITY_SUCCESS:
      return action.payload;
    case REMOVE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item: OrderItem) => item?.id !== action.payload.itemId),
      };
    case DELETE_CART_SUCCESS:
    case DELETE_CART_ERROR:
    case COMPLETE_ORDER_ON_CHECKOUT_SUCCESS:
      return defaultOrder;
    default:
      return state;
  }
};

const ShippingMethodsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case SET_ORDER_SHIPPING_METHOD:
      return action.payload;
    default:
      return state;
  }
};

const AvailableShippingMethodsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_ORDER_SHIPPING_METHODS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const PaymentMethodsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_ORDER_PAYMENT_METHODS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const InvoiceReducer = (state = false, action: AnyAction) => {
  switch (action.type) {
    case COMPANY_INVOICE_TYPE:
      return action.payload;
    case DELETE_CART_SUCCESS:
    case DELETE_CART_ERROR:
    case COMPLETE_ORDER_ON_CHECKOUT_SUCCESS:
      return false;
    default:
      return state;
  }
};

const ReviewModalReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case REVIEW_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export {
  ActiveOrdersReducer,
  InactiveOrdersReducer,
  LastOrdersReducer,
  OrderDetailsReducer,
  CartReducer,
  ShippingMethodsReducer,
  AvailableShippingMethodsReducer,
  PaymentMethodsReducer,
  InvoiceReducer,
  ReviewModalReducer,
};
